import { setQboData } from "actions/rolePermission";
import { useDispatch } from "react-redux";
import { eventBus } from "utils/eventbus";

function CloseQboWindow() {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const clientId = queryParameters.get("clientId");
  const status = queryParameters.get("status");
  const message = queryParameters.get("message");
  setTimeout(() => {
    localStorage.setItem("queryParameters", [clientId, status, message]);
    dispatch(setQboData({ url: clientId }));
  }, 1000);
  setTimeout(() => {
    window.close();
  }, 2000);
  eventBus.$emit("closeQboWindowApiCall", "Close");
  return null;
}

export default CloseQboWindow;