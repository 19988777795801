export const User = {
   admin: "Admin",
   manager: "Manager",
   user: "User"
}

export const User1 = {
   domain1: "Admin",
   domain: "Manager",
   client: "User"
}

export const UserNew = {
   domain1: "admin",
   domain: "manager",
   client: "user"
}

export const User2 = {
   owner: "Owner",
   admin: "Admin",
   manager: "Manager",
   user: "User"
}
export const UserA = {
   manager: "Manager",
   user: "User"
}
export const UserM = {
   user: "User"
}

export const UserU = {
   user: "User"
}