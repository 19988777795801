import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { textEllipsis } from "components/textEllipsis";
import { Form, Formik, isString } from "formik";
import CurrencyFieldText from "layouts/accountants/user/components/CurrencyField-Text";
import FormFieldDate from "layouts/accountants/user/components/FormFieldDate";
import LoaderDailog from "layouts/accountants/user/components/imgLoader/LoaderDailog";
import { InvoiceEditSchema } from "layouts/accountants/user/components/schemas/editinvoicevalidation";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import FormField from "layouts/admin/accountants/components/FormField";
import Moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/cjs/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { useAPICall } from "utils/useapicall";
import {GetInvoiceDetails, GetItemList, GetuserClientDetails, GetuserClientList} from "../../../services/userservices";
import "./styles.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  option: {
    "&:first-child": {
      color: "#0000ff",
    },
    "&:hover:first-child": {
      color: "#0000ff",
      backgroundColor: "none !important",
    },
  },
  InputRoot: {
    "&::-webkit-calendar-picker-indicator": {
      display: "none !important",
    },
  },
}));

const myTheme = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: "rgb(123, 128, 154)",
  },
  components: {
    //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-actionsCell": {
            gridGap: "0px",
          },
          "& .MuiDataGrid-cell": {
            justifyContent: "center",
            outline: " none",
            fontWeight: "bold !important",
            color: "rgb(123 128 154)",
            // border: 0,
          },
          "& .MuiDataGrid-cell:focus": {
            outline: " none",
          },
          "&.Mui-selected": {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function InvoiceDetails({ invoiceIds, onClickClose, reloadApiAfterClose }) {
  let businessId = "";
  let onIssueClick = false;
  const classes = useStyles();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const [invoice_id, setInvoice_Id] = useState(invoiceIds);
  const [invoiceId, setInvoiceId] = useState(invoiceIds);
  const [createObjectURL, setCreateObjectURL] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [counts, setCounts] = useState(0);
  const [imageUrl1, setImageUrl1] = useState("");
  const [value, setValue] = useState(1);
  const [onIssueClick1, setOnIssueClick1] = useState(onIssueClick);
  const [styles, setStyles] = useState({});
  const [transactionDate, setTransactionDate] = useState("");
  const [toggleShow, setToggleShow] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [total, setTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderTotalStore, setOrderTotalStore] = useState(0);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shippingprice, setShippingprice] = useState(0);
  const [onChanges, SetonChanges] = useState(false);
  const [discountStore, setDiscountStore] = useState(0);
  const [shippingpriceStore, setShippingpriceStore] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [subtotal, setSubTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [showDetailsForm, setShowDetailsForm] = useState(true);
  const [editable, SetEditable] = useState(false);
  const [taxStore, setTaxStore] = useState(0);
  const [actualSubtotal, setActualSubtotal] = useState(0);
  const [actualTotal, setActualTotal] = useState(0);
  const [subTotStore, setSubTotStore] = useState(0);
  const [issues, setIssues] = useState([]);
  const [documentSource, setDocumnetSource] = useState("");
  const [isSynced, setIsSynced] = useState(false);
  const [documentStatus, setDocumentStatus] = useState("");
  const [openQboSync, setOpenQboSync] = useState(false);
  const [extention, setExtention] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 5, page: 0 });
  const [{isLoading: isInvoiceDetailsLoading,isError: isInvoiceDetailsError,response: InvoiceDetailsResponse},InvoiceDetailsUserAPICall] = useAPICall(GetuserClientList, { onLoad: false });
  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse },DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [{ isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse },GetItemListListAPICall] = useAPICall(GetItemList, { onLoad: false });
  const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails,{ onLoad: false });
  const [showClasses, SetShowClasses] = useState(true);

  useEffect(async () => {
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoiceId}` }],
    });
  }, []);

  const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  useEffect(async () => {
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      SetShowClasses(clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine);
    }
  }, [clientDetailsDataResponse]);

  useEffect(() => {
    if (isInvoiceDetailsLoading) {
      return () => null;
    }
    if (isInvoiceDetailsError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (InvoiceDetailsResponse && InvoiceDetailsResponse.data.isSuccess === true) {
      const found2 = InvoiceDetailsResponse?.data?.data?.records.find((obj) => {
        return obj._id === businessId;
      });
      setOwnerAssigned(found2?.ownerAssigned);
      setLoading(true);
      setLoadingCircul(false);
    }
  }, [InvoiceDetailsResponse]);

  function getPriceProduct(params) {
    const finalPrice = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.price : params?.row?.price;
    return `$${finalPrice}`;
  }
  function getTotalProduct(params) {
    return `$${params?.row?.total}`;
  }
  function getQuantity(params) {
    const finalQty = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.qty : params?.row?.qty;
    return finalQty;
  }

  useEffect(() => {
    setLoading(true);
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    InvoiceDetailsUserAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isGetItemListLoading) {
      return () => null;
    }
    if (isGetItemListError) {
      setLoading(false);
      return () => null;
    }
    if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
      setLoading(false);
      if (onIssueClick1 === true && moduleTypeEdit === "ITEM") {
        setValue(1);
      } else if (onIssueClick1 === true && moduleTypeEdit === "DOCUMENT") {
        setValue(0);
      }
      const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
        return {
          item: prop?.description ? prop?.description : "---",
          qty: prop?.quantity ? isString(prop?.quantity) ? Number(prop?.quantity) : prop?.quantity : "---",
          unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
          product: prop?.product?.name ? prop?.product?.name : "---",
          price: prop?.unitPrice ? prop?.unitPrice : "0",
          total: prop?.amount ? prop?.amount : "---",
          customer: prop?.customer?.name ? prop?.customer?.name : "---",
          category: prop?.category?.name ? prop?.category?.name : "---",
          class: prop?.class?.name ? prop?.class?.name : "---",
          proderror: false,
          proderrorMsg: "---",
          id: prop._id,
          sku: prop.sku,
          itemType: prop.itemType,
          CheckAskClient: prop?.askClient,
        };
      });
      const Categoryresult = data.filter((data) => data.itemType === "AccountBasedExpenseLineDetail");
      setCategoryList(data);
      setProductList(data);
      if (firstTime === true) {
        if (Categoryresult.length === 0) {
          setItemList(data), setLoading(false), setFirstTime(false);
        } else {
          setItemList(data);
          setLoading(false), setFirstTime(false);
        }
      } else {
        setLoading(false), toggleShow === true ? setItemList(data) : setItemList(data);
      }
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setTimeout(() => {
        setShowDetails(true);
      }, 3000);
      setLoadingCircul(false);
    }
  }, [GetItemListResponse]);

  useEffect(() => {
    if (GetItemListResponse && DetailsResponse) {
      setShowDetails(true);
    }
  }, [GetItemListResponse, DetailsResponse]);

  useEffect(() => {
    if (productList || categoryList) {
      {toggleShow === true ? setItemList(productList) : setItemList(productList)}
    }
  }, [toggleShow]);

  useEffect(async () => {
    setFirstTime(onIssueClick1);
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (loading == true) {
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }, [loading]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openAddItem) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAddItem]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      const DetailsResponseData = DetailsResponse?.data?.data?.document;
      setActualSubtotal(DetailsResponseData?.actualSubtotal);
      setActualTotal(DetailsResponseData?.actualTotal);
      setTransactionDate(DetailsResponseData?.transactionDate ? Moment(DetailsResponseData?.transactionDate).format("YYYY-MM-DD") : null);
      setTotal(DetailsResponseData?.total);
      setOrderTotalStore(DetailsResponseData?.orderTotal);
      setTax(DetailsResponseData?.tax);
      setDiscount(DetailsResponseData?.discount);
      setDiscountStore(DetailsResponseData?.discount);
      setShippingpriceStore(DetailsResponseData?.shippingPrice);
      setShippingprice(DetailsResponseData?.shippingPrice);
      setTaxStore(DetailsResponseData?.tax);
      setIssues(DetailsResponseData?.issues);
      setSubTotal(DetailsResponseData?.sub_total ? DetailsResponseData?.sub_total : DetailsResponseData?.subTotal);
      setSubTotStore(DetailsResponseData?.sub_total ? DetailsResponseData?.sub_total : DetailsResponseData?.subTotal);
      setCurrencySymbol(DetailsResponseData?.currencySymbol);
      setDocumnetSource(DetailsResponseData?.documentSource ? DetailsResponseData?.documentSource : "");
      setIsSynced(DetailsResponseData?.isSynced);
      setDocumentStatus(DetailsResponseData?.documentStatus ? DetailsResponseData?.documentStatus : "");
      setCreateObjectURL(DetailsResponseData?.uploadedFileUrl ? DetailsResponseData?.uploadedFileUrl : "" );
      setImageUrl(DetailsResponseData?.uploadedFileUrl ? DetailsResponseData?.uploadedFileUrl : "" );
      setLoading(false);
      DetailsResponseData?.itemType === "ItemBasedExpenseLineDetail" ? setToggleShow(true) : setToggleShow(false);
      setLoadingCircul(false);
      setShowDetailsForm(true);
      setInitialValues({
        vendorname: DetailsResponseData?.vendor ? DetailsResponseData?.vendor.name : "",
        documenttype: DetailsResponseData?.documentType ? DetailsResponseData?.documentType : "",
        invoicenumber: DetailsResponseData?.docNumber,
        ponumber: DetailsResponseData?.purchaseOrderNo,
        invoicedate: DetailsResponseData?.transactionDate ? Moment(DetailsResponseData?.transactionDate).format("YYYY-MM-DD") : null,
        uploaddate: DetailsResponseData?.createdAt ? Moment(DetailsResponseData?.createdAt).format("YYYY-MM-DD") : null,
        total: `${total}`,
        tax: `${tax}`,
        transactionDate: `${transactionDate}`,
        subtotal: `${subtotal}`,
      });
    }
  }, [DetailsResponse]);

  const handleChange = async (event, newValue) => {
    setFirstTime(true);
    setOnIssueClick1(false);
    setValue(newValue);
    if (newValue === 1) {
      setLoading(true);
      await GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` }],
      });
    }
  };

  const handleSubmit = async (event, values) => {};

  const ToggleClick = () => {
    setToggleShow(!toggleShow), setLoading(true);
  };

  const handleToggle = () => {
    documentStatus === "Synced" || isSynced === true ? setOpenQboSync(true) : ToggleClick();
  };

  const columns = [
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 110,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      editable: false,
      flex: 0.2,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
              },
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      editable: false,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 65,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      editable: false,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const columnsWitoutClass = [
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 60,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      editable: false,
      flex: 0.2,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      editable: false,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 25,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 70,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 70,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumns = [
    {
      field: "category",
      headerName: "CATEGORY",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      editable: false,
      minWidth: 70,
      disableColumnMenu: true,
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.26,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumnsWithoutClass = [
    {
      field: "category",
      headerName: "CATEGORY",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 75,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 75,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const dataTableData = {
    columns: [
      {
        Header: "Category",
        accessor: "category",
        width: 90,
      },
      {
        Header: "Description",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "total",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Ask Client",
        accessor: "askClient",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: itemList,
  };

  const dataTableData1 = {
    columns: [
      {
        Header: "Products/Service",
        accessor: "product",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Qty",
        accessor: "qty",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unit",
        accessor: "unit",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Ask Client",
        accessor: "askClient",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: itemList,
  };

  useEffect(() => {
    let taxNow = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
    let subTotalNow = typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
    let discountNow = typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
    let shippingPriceNow = typeof shippingpriceStore == "string" ? shippingpriceStore.replaceAll(",", "") : shippingpriceStore;
    let orderTotalNow = typeof orderTotalStore == "string" ? orderTotalStore.replaceAll(",", "") : orderTotalStore;
    const finaldiscountNow = discountNow ? discountNow : 0;
    const MyTotal = Number.parseFloat(subTotalNow, 10) - Number.parseFloat(finaldiscountNow, 10);
    const finalshippingPriceNow = shippingPriceNow ? shippingPriceNow : 0;
    const finaltaxNow = taxNow ? taxNow : 0;

    if (onChanges === true) {
      const timeOutId = setTimeout(
        async () =>
        setTotal( MyTotal + Number.parseFloat(finaltaxNow, 10) + Number.parseFloat(finalshippingPriceNow, 10) ),
        setOrderTotal(Number.parseFloat(orderTotalNow, 10) + Number.parseFloat(finaltaxNow, 10)),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [taxStore, subTotStore, discountStore, orderTotalStore, orderTotal, shippingpriceStore]);

  const getFormattedNumber = (n) => {
    let unFormatted = typeof n == "string" ? n.replaceAll(",", "") : n;
    const numberFormatter = Intl.NumberFormat(
      "en-US",
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
    const formatted = numberFormatter.format(unFormatted);
    return formatted;
  };

  useEffect(() => {
    setStyles(
      value === 2 || value === 3
        ? { display: "grid", overflowY: "scroll", overflowX: "scroll", height: "80%" }
        : { height: "80%", overflow: "auto" }
    );
  }, [value]);

  useEffect(() => {
    if (createObjectURL) {
      setExtention(createObjectURL.split(".").pop());
    }
  }, [createObjectURL]);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const handleClickImgDownload = () => {
    window.open(`${createObjectURL}`, "_blank");
  };

  async function createFile() {
    if (imageUrl && counts === 0) {
      setCounts(counts + 1);
      let response = await fetch(imageUrl);
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);
      const imageDataUrl = URL.createObjectURL(file);
      setImageUrl1(imageDataUrl);
    }
  }
  
  useEffect(() => {
    createFile();
  }, [imageUrl]);

  const Controls = () => {
    const { zoomIn, zoomOut } = useControls();
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "23px",
          marginTop: "-23px",
          marginRight: "100px",
        }}
      >
        <a style={{ display: "flex", justifyContent: "center", marginLeft: "100px" }}>
          <img
            onClick={() => zoomOut()}
            style={{ marginRight: "20px", cursor: "pointer", marginBottom: "-6px" }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+0lEQVR4nLXTTSvFQRTH8c9FKAsLS9lYWSgpG5dXwC1ZKTuLKwsbpWxEnp+SDStLKRsskYW8IW+Bpob+6c7/zs31q9Oc5kzfc+bMGf5RQzjFK57ieomRViCLeMEUKoX9MTxgLQdSww26EvEKzlAvg/TgHb1NknXEigdSB2axnlM2FrCSCm6hGv0+DDew/sJjXKdAhxiP/iiOG9h0jIdr3aZAy7HkHFWxlwoO4j4TdIWJsgMXmG8CmcRds0zdeMTSr2H81hw+cC5DYRg38IYjrGIXz9jECT6xL1OdsQ+12NzioB60CivTToSFz/1nhREIsO12wAJkph2gH30BrGgnHq5WuyEAAAAASUVORK5CYII="
            title="ZoomIn"
          />
        </a>
        <a style={{ display: "flex", justifyContent: "center" }}>
          <img
            onClick={() => zoomIn()}
            style={{ marginRight: "20px", cursor: "pointer", marginBottom: "-6px" }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVR4nLXTTyuFQRTH8c9FKMrCUjZWFkrKxuUVcEtWyu4uyMJGKQsi//8lG1aWUjZYIgvxQrwEb4Gmhp6e7lzPzfWr05zzm/o2Z+YM/6h+HOMZD3E9x2AjkDk8YRyljD+MOywXgVRwhbbEfgknmK8H6cArOnP+Wq5uiSfuTYGmsFLDf6zhzWIxBdpAOeZdGIjxlsl7Mo9xmQLtYyTmQziM8Z7JJ+J+aOs6BVqIRy7SWhk7KVAfbguCLjCqjs4wk/O6c/UYbvyidtyjmhvGb03jA6cKKAzjKl5wgCVsxxbXcYRP7Cqo1ngPlXi52UHdaxRWT1sRFj73nxVGIMA2mwELkMlmgH70Bf8ZKsMW9hShAAAAAElFTkSuQmCC"
            title="ZoomIn"
          />
        </a>
      </div>
    );
  };

  const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

  return (
    <>
      {!showDetails ? (
        <>
          <MDBox mt={"-10%"} display="flex" justifyContent="center">
            {<LoaderDailog />}
          </MDBox>
        </>
      ) : (
        <>
          <MDBox mb={3} display={"flex"}>
            <Typography variant="h6" className={classes.title}>
              <MDBox display={"flex"}></MDBox>
            </Typography>
            <MDBox>
              <Icon
                sx={{ cursor: "pointer", mr: 2 }}
                fontSize="medium"
                onClick={reloadApiAfterClose}
              >
                close
              </Icon>
            </MDBox>
          </MDBox>
          <MDBox
            mb={3}
            style={{ height: "95%", overflow: "auto", display: "block", marginBottom: 0 }}
          >
            <Grid container style={{ height: "100%", overflow: "auto" }}>
              {createObjectURL ? (
                <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: "100%", overflow: "auto" }} >
                  {extention === "pdf" ? (
                    <div
                      className="rpv-core__viewer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "97%",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          backgroundColor: "#eeeeee",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          padding: "0.25rem",
                        }}
                      >
                        <Toolbar>
                          {(props) => {
                            const {
                              CurrentPageInput,
                              NumberOfPages,
                              ZoomIn,
                              ZoomOut,
                              EnterFullScreen,
                              Download,
                            } = props;
                            return (
                              <>
                                <div style={{ padding: "0px 5px", width: "4rem", marginLeft: "10px" }} >
                                  <CurrentPageInput />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                  of <NumberOfPages />
                                </div>
                                <div style={{ padding: "0px 2px", marginLeft: "35%" }}>
                                  <ZoomOut />
                                </div>
                                <div style={{ padding: "0px 2px", marginLeft: "10px" }}>
                                  <ZoomIn />
                                </div>
                                <div style={{ padding: "0px 2px", display: "flex", marginLeft: "auto" }} >
                                  <Download />
                                </div>
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                    paddingBottom: "2px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <img
                                    style={{ marginRight: "20px", cursor: "pointer" }}
                                    width="19"
                                    height="19"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC"
                                    alt="fullscreen"
                                    onClick={() => window.open(`${createObjectURL}`, "_blank")}
                                    title="Full Screen"
                                  />
                                </div>
                              </>
                            );
                          }}
                        </Toolbar>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          overflow: "hidden",
                        }}
                      >
                        {extention === "pdf" && (
                          <Worker workerUrl={workerUrl}>
                            <Viewer fileUrl={createObjectURL} plugins={[toolbarPluginInstance]}  
                              renderError={(error) => {
                                let message = '';
                                switch (error.name) {
                                  case 'InvalidPDFException':
                                      message = 'The document is invalid or corrupted';
                                      break;
                                  case 'MissingPDFException':
                                      message = 'The document is missing';
                                      break;
                                  case 'UnexpectedResponseException':
                                      message = 'Unexpected server response';
                                      break;
                                  default:
                                      message = 'Cannot load the document';
                                      break;
                              }
          
                              return (
                                  <div style={{ color: "red",marginTop: '50%' , textAlign: "center", padding: "20px" }}>
                                      {message}
                                  </div>
                              );
                          }}
                            />
                          </Worker>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="rpv-core__viewer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "97%",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CurrentPageLabel>
                          {(props) => (
                            <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
                          )}
                        </CurrentPageLabel>
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          backgroundColor: "#eeeeee",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          justifyContent: "end",
                          padding: "0.25rem",
                        }}
                      >
                        <a href={imageUrl1} download={"downloaded-image.jpeg"}>
                          <img
                            style={{ marginRight: "20px", cursor: "pointer", marginBottom: "-6px" }}
                            width="18"
                            height="18"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2SUQqAIBAF5xJK3bcumB9eJb8MwQUTC8ItiBpYUFbeyCp8hRkIQMyV1pOmIBThUqumIOY62v8C/hHtMMDS+JaxONPqeWCgUyJ0hbckHhhPepfDzyRGK1ywgMuBrlqnngqmehOVmx9J/B3hgtUcy/uIyvW8gFeyAeoqduPSsPEqAAAAAElFTkSuQmCC"
                            title="Download"
                          />
                        </a>
                        <img
                          style={{ marginRight: "20px", cursor: "pointer" }}
                          width="19"
                          height="19"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC"
                          alt="fullscreen"
                          onClick={handleClickImgDownload}
                          title="Full Screen"
                        />
                      </div>
                      <div>
                        <TransformWrapper
                          defaultScale={1}
                          defaultPositionX={0}
                          defaultPositionY={0}
                        >
                          <Controls />
                          <TransformComponent
                            contentStyle={{
                              width: "100% !important",
                              height: "110%",
                              marginBottom: "100px",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <img src={createObjectURL} alt="test" width="100%" height="100%" />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: "100%", overflow: "auto" }} >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      border: "0.5px solid gray",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50%",
                      }}
                    >
                      <h2>
                        {documentSource === "QBO"
                          ? "No image available from Quickbooks"
                          : "No image available from Paynthr"}
                      </h2>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={8} lg={7.5} style={{ height: "100%", overflow: "auto" }}>
                <Card style={{ height: "100%", overflow: "auto" }}>
                  <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    <Tab
                      label={
                        issues?.documents ? (
                          <MDBadge
                            overlap="circular"
                            badgeContent={issues?.documents?.count}
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "#ef5350",
                                background: "transparent",
                              },
                            }}
                          >
                            Details &nbsp; &nbsp; &nbsp;
                          </MDBadge>
                        ) : (
                          "Details"
                        )
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        issues?.items ? (
                          <MDBadge
                            overlap="circular"
                            badgeContent={issues?.items?.count}
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "#ef5350",
                                background: "transparent ",
                              },
                            }}
                          >
                            Items &nbsp; &nbsp; &nbsp;
                          </MDBadge>
                        ) : (
                          "Items"
                        )
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>
                  <CardHeader
                    subheader={
                      <MDBox>
                        <Grid
                          item
                          sx={12}
                          sm={12}
                          md={12}
                          style={{ display: "flex", marginLeft: "70px", fontSize: "1rem" }}
                        >
                          <Grid item sx={12} xs={4}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                              Subtotal
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                              {currencySymbol}
                              {actualSubtotal}
                            </span>
                          </Grid>
                          <Grid item sx={12} xs={4}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                              Taxes & Fees
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                              {currencySymbol}
                              {taxStore ? getFormattedNumber(taxStore) : getFormattedNumber(tax)}
                            </span>
                          </Grid>
                          <Grid item sx={12} xs={4}>
                            <span style={{ display: "flex", justifyContent: "center" }}>Total</span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                              {currencySymbol}
                              {actualTotal}
                            </span>
                          </Grid>
                        </Grid>
                      </MDBox>
                    }
                  />
                  <Divider />
                  <CardContent style={styles}>
                    <TabPanel value={value} index={0}>
                      {showDetailsForm && (
                        <MDBox my={2}>
                          <Box>
                            <Grid item xs={12} sm={12} md={12}>
                              <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={InvoiceEditSchema}
                                onSubmit={handleSubmit}
                              >
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                }) => (
                                  <Form autoComplete="off">
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6}>
                                        <FormField
                                          type="text"
                                          label="Vendor Name"
                                          name="vendorname"
                                          disabled={true}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <FormField
                                          type="text"
                                          label="Document Type"
                                          name="documenttype"
                                          disabled={true}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                        <FormField
                                          type="text"
                                          label="Invoice Number"
                                          name="invoicenumber"
                                          disabled={true}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                        <FormField
                                          type="text"
                                          label="PO Number"
                                          name="ponumber"
                                          disabled={true}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{ mt: 1 }}
                                        className={!editable ? "bg-salmon" : ""}
                                      >
                                        <FormFieldDate
                                          type="date"
                                          label="Transaction Date"
                                          name="transactionDate"
                                          disabled={true}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{ mt: 1 }}
                                        className={"bg-salmon"}
                                      >
                                        <FormFieldDate
                                          type="date"
                                          label="Upload Date"
                                          name="uploaddate"
                                          disabled={true}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Subtotal"
                                          name="subtotal"
                                          disabled={false}
                                          value={subtotal}
                                          onValueChange={(event) => {
                                            setSubTotal(event.value);
                                            setSubTotStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Discount"
                                          name="discount"
                                          disabled={false}
                                          value={discount}
                                          onValueChange={(event) => {
                                            setDiscount(event.value);
                                            setDiscountStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2.5} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Shipping Price"
                                          name="shippingprice"
                                          disabled={false}
                                          value={shippingprice}
                                          onValueChange={(event) => {
                                            setShippingprice(event.value);
                                            setShippingpriceStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Taxes & Fees"
                                          name="tax"
                                          disabled={false}
                                          value={tax}
                                          onValueChange={(event) => {
                                            setTax(event.value);
                                            setTaxStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2.3} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Total"
                                          name="total"
                                          disabled={false}
                                          value={total}
                                          onValueChange={(event) => {
                                            setTotal(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Form>
                                )}
                              </Formik>
                            </Grid>
                          </Box>
                        </MDBox>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <MDBox mt={-3} sx={{ pt: 3 }}>
                        {
                          <MDBox display="flex" alignItems="center" ml={-1} justifyContent="end">
                            <Switch
                              checked={toggleShow}
                              onChange={handleToggle}
                              value="toggleShow"
                            />
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none" }}
                            >
                              &nbsp;&nbsp;Split by Products/Services
                            </MDTypography>
                          </MDBox>
                        }
                        <MDBox mt={1.5} mb={-5} ml={-6} mr={-6} sx={{ p: 3 }}>
                          {!toggleShow ? (
                            dataTableData?.rows?.length === 0 ? (
                              <MDBox my={5} sx={{ mt: 1 }}>
                                <ThemeProvider theme={myTheme}>
                                  <DataGrid
                                    rows={itemList || []}
                                    columns={
                                      showClasses ? cetagorycolumns : cetagorycolumnsWithoutClass
                                    }
                                    components={{
                                      NoRowsOverlay: () => (
                                        <Stack
                                          height="100%"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          No records found
                                        </Stack>
                                      ),
                                    }}
                                    sx={{
                                      ".MuiDataGrid-withBorderColor" : {
                                          borderTop:'2px solid #1a2035 !important'
                                      },
                                      "@media (max-width: 1440px)": {
                                        fontWeight: `0  !important`,
                                        fontSize: `0.775rem`,
                                      },
                                      ".MuiDataGrid-columnSeparator": {
                                        display: "none",
                                      },
                                      ".MuiDataGrid-columnHeaderDraggableContainer": {
                                        width: "112% !important",
                                      },
                                      border: 0,
                                      ".MuiDataGrid-columnHeaderTitle": {
                                        color: "rgb(123, 128, 154)",
                                        fontWeight: "bold !important",
                                      },
                                      ".MuiDataGrid-columnHeader:focus": {
                                        outline: "none !important",
                                      },
                                      "& .MuiTablePagination-root": {
                                        display : 'none',
                                      },
                                      ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor": {
                                        outline: "none !important",
                                      },
                                    }}
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </ThemeProvider>
                              </MDBox>
                            ) : (
                              <ThemeProvider theme={myTheme}>
                                <Box
                                  sx={{
                                    "& .caterror": {
                                      borderBottom: `0.5px solid red !important`,
                                    },
                                  }}
                                >
                                  <DataGrid
                                    rows={itemList}
                                    columns={
                                      showClasses ? cetagorycolumns : cetagorycolumnsWithoutClass
                                    }
                                    getCellClassName={(params) => {
                                      return params?.row?.proderror == true &&
                                        params?.field === "category"
                                        ? "caterror"
                                        : "";
                                    }}
                                    disableRowSelectionOnClick
                                    sx={{
                                      "@media (max-width: 1440px)": {
                                        fontWeight: `0  !important`,
                                        fontSize: `0.775rem`,
                                      },
                                      ".MuiDataGrid-columnSeparator": {
                                        display: "none",
                                      },
                                      ".MuiDataGrid-columnHeaderDraggableContainer": {
                                        width: "112% !important",
                                      },
                                      border: 0,
                                      ".MuiDataGrid-columnHeaderTitle": {
                                        color: "rgb(123, 128, 154)",
                                        fontWeight: "bold !important",
                                        overflow: "visible !important",
                                      },
                                      ".MuiDataGrid-columnHeader:focus": {
                                        outline: "none !important",
                                      },
                                      ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                        {
                                          outline: "none !important",
                                        },
                                    }}
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    cellModesModel={cellModesModel}
                                    onCellModesModelChange={handleCellModesModelChange}
                                    onCellClick={handleCellClick}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </Box>
                              </ThemeProvider>
                            )
                          ) : dataTableData1?.rows?.length === 0 ? (
                            <MDBox my={5} sx={{ mt: 1 }}>
                              <ThemeProvider theme={myTheme}>
                                <DataGrid
                                  rows={itemList || []}
                                  columns={showClasses ? columns : columnsWitoutClass}
                                  components={{
                                    NoRowsOverlay: () => (
                                      <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        No records found
                                      </Stack>
                                    ),
                                  }}
                                  sx={{
                                    ".MuiDataGrid-withBorderColor" : {
                                      borderTop:'2px solid #1a2035 !important'
                                    },
                                    "@media (max-width: 1440px)": {
                                      fontWeight: `0  !important`,
                                      fontSize: `0.775rem`,
                                    },
                                    ".MuiDataGrid-columnSeparator": {
                                      display: "none",
                                    },
                                    border: 0,
                                    ".MuiDataGrid-columnHeaderTitle": {
                                      color: "rgb(123, 128, 154)",
                                      fontWeight: "bold !important",
                                      overflow: "visible !important",
                                    },
                                    ".MuiDataGrid-columnHeader:focus": {
                                      outline: "none !important",
                                    },
                                    "& .MuiTablePagination-root": {
                                      display : 'none',
                                    },
                                    ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor": {
                                      outline: "none !important",
                                    },
                                  }}
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </ThemeProvider>
                            </MDBox>
                          ) : (
                            <ThemeProvider theme={myTheme}>
                              <Box
                                sx={{
                                  "& .proerror": {
                                    borderBottom: `0.5px solid red !important`,
                                  },
                                  "& .proerror.tooltiptext": {
                                    visibility: `hidden`,
                                    width: `120px`,
                                    backgroundColor: `black`,
                                    color: `#fff`,
                                    textAlign: `center`,
                                    borderRadius: `6px`,
                                    padding: `5px 0`,
                                    position: `absolute`,
                                    zIndex: 1,
                                    bottom: `100%`,
                                    left: `50%`,
                                    marginLeft: `-60px`,
                                  },
                                  "& .proerror:hover .tooltiptext": {
                                    visibility: `visible`,
                                  },
                                }}
                              >
                                <DataGrid
                                  rows={itemList}
                                  columns={showClasses ? columns : columnsWitoutClass}
                                  getCellClassName={(params) => {
                                    return params?.row?.proderror == true &&
                                      params?.field === "product"
                                      ? "proerror"
                                      : "";
                                  }}
                                  disableRowSelectionOnClick
                                  sx={{
                                    "@media (max-width: 1440px)": {
                                      fontWeight: `0  !important`,
                                      fontSize: `0.775rem`,
                                    },
                                    ".MuiDataGrid-columnSeparator": {
                                      display: "none",
                                    },
                                    border: 0,
                                    ".MuiDataGrid-columnHeaderTitle": {
                                      color: "rgb(123, 128, 154)",
                                      fontWeight: "bold !important",
                                      overflow: "visible !important",
                                      "@media (max-width: 1440px)": {
                                        fontSize: `0.580rem`,
                                      },
                                    },
                                    ".MuiDataGrid-columnHeader:focus": {
                                      outline: "none !important",
                                    },
                                  }}
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  cellModesModel={cellModesModel}
                                  onCellModesModelChange={handleCellModesModelChange}
                                  onCellClick={handleCellClick}
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </Box>
                            </ThemeProvider>
                          )}
                        </MDBox>
                      </MDBox>
                    </TabPanel>
                  </CardContent>
                </Card>
                <MDBox>{loading && <LoaderDailog />}</MDBox>
              </Grid>
            </Grid>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
          </MDBox>
        </>
      )}
    </>
  );
}

export default InvoiceDetails;