import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { SITE_URL } from "helpers/config";
import FormField from "layouts/admin/accountants/components/FormField";
import { AddNewOwnerSchema } from "layouts/accountants/clients/components/schemas/addnewownerforrole";
import { AddNewEmailOwnerSchema } from "layouts/accountants/clients/components/schemas/addowneremail.js";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { AssignNewOwner, checkEmailUser ,GetuserDetailsList ,AddNewuser ,EditUserInfo} from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { FormControl } from "@mui/material";
import { User1 ,UserNew } from "helpers/getUser";
import InputLabel from "@mui/material/InputLabel";
import MDTypography from "components/MDTypography";

function AddNewUserDialog({ userName ,invoiceUserId }) {
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [emailId, setEmailId] = useState("");
    const [emailExistButton, setEmailExistButton] = useState(true);
    const [helperText, setHelperText] = useState("");
    const [searchBusinessName, setSearchBusinessName] = useState("");
    const [ErrorMessages, setErrorMessage] = useState("");
    const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [oldOwner, setOldOwner] = useState(false);
    const [phone, setPhone] = useState("");
    const [existMessage, setExistMessage] = useState("");
    const [isAdminRole , setIsAdminRole ] = useState(false);
    const [selectedRole, setSelectedRole] = useState("");
    const [isAlreadyExist ,setIsAlreadyExist ] = useState(false);
    const [existUserid , setexistUserid ] = useState("");
    const [roleIfexist ,setRoleIfexist] = useState("");
    const [existfname ,setExistfname] = useState("");
    const [existlname, setExistlname] = useState("");
    const [selectedClientIds, setSelectedClientIds] = useState([]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [{ isLoading: isAssignOwnerLoading, isError: isAssignOwnerError, response: AssignOwnerResponse }, AssignOwnerAPICall] = useAPICall(AssignNewOwner, { onLoad: false });
    const [{ isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse },checkEmailAPICall] = useAPICall(checkEmailUser, { onLoad: false });
    const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserDteailsAPICall] = useAPICall(GetuserDetailsList, { onLoad: false });
    const [{isError, isLoading, response }, setAPICall] = useAPICall( roleIfexist ? EditUserInfo : AddNewuser, { onLoad: false });

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            eventBus.$emit("addNewUserFailed", response?.data);
            setBtnDisable(false);
            return () => null;
        }
        if (response && response?.data?.isSuccess === true) {
            setBtnDisable(false);
            eventBus.$emit("addNewUserComplete", response?.data);
        }
    }, [response]);

    useEffect(() => {
    if (isCheckEmError) {
        setErrorMessage(checkEmResponse?.data?.message);
        setShowErrorMoveSnackbar(true);
        setTimeout(() => {
        setShowErrorMoveSnackbar(false);
        }, 3000);
        setEmailExist(false);
        return () => null;
    }
    if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
        if(checkEmResponse?.data?.data?.userId && checkEmResponse?.data?.data?.status !== "NEW"){
            getUserDteailsAPICall({
                payload: [{ bussinessId: checkEmResponse?.data?.data?.userId }],
            });
        }else {
            setSelectedClientIds([{"_id": invoiceUserId, "name": userName }]);
            setEmailExist(true);
            setEmailExistButton(false);
        }
    }
    }, [checkEmResponse]);

    useEffect(() => {
        if (isGetUserLoading) {
            return () => null;
        }
    
        if (isGetUserError) {
            return () => null;
        }
    
        if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
            const role = userDetailsDataResponse?.data?.data?.employee?.permissions.find((p) => p.category === "accounting")?.role || null;
            if (role === "admin") {
                setIsAdminRole(true);
                setExistMessage("This email is already a admin user");
            }else{
                setIsAlreadyExist(true);
                const finalRole = Object.keys(UserNew).find((key) => UserNew[key] === role);
                setRoleIfexist(finalRole);
                setexistUserid(userDetailsDataResponse?.data?.data?.employee?._id)
                setExistlname(userDetailsDataResponse?.data?.data?.employee?.lname);
                setExistfname(userDetailsDataResponse?.data?.data?.employee?.fname);
                setPhone(userDetailsDataResponse?.data?.data?.employee?.phone);
                setSelectedClientIds((prev) => {
                    // Combine the previous state, the new custom data, and the assigned clients
                    const combinedClients = [
                        { _id: invoiceUserId, name: userName },
                        ...userDetailsDataResponse?.data?.data?.employee?.assignedClients.map((client) => ({ _id: client._id, name: client.name }))
                    ];
                    
                    // Filter out any duplicates based on _id
                    const uniqueClients = combinedClients.filter(
                        (client, index, self) => 
                        index === self.findIndex((c) => c._id === client._id)
                    );
                    
                    return [...prev, ...uniqueClients];
                    });
                setEmailExistButton(false);
            }
        }
        }, [userDetailsDataResponse]);
    
    

    useEffect(() => {
    const timeOutId = setTimeout(async () => {
        if (searchBusinessName.length !== 0) {
        await checkEmailAPICall({
            payload: [
            {   
                checkBy: "email",
                email: `${searchBusinessName}`,
            },
            ],
        });
        }
    }, 500);
    return () => clearTimeout(timeOutId);
    }, [searchBusinessName]);

    useEffect(() => {
    if (isAssignOwnerLoading) {
        return () => null;
    }
    if (isAssignOwnerError) {
        if (AssignOwnerResponse?.data?.message) {
        eventBus.$emit("errorInAddOwner", AssignOwnerResponse.data.message);
        }
        setLoading(false);
        setBtnDisable(false);
        return () => null;
    }
    if (AssignOwnerResponse && AssignOwnerResponse.data.isSuccess === true) {
        eventBus.$emit("successInAddOwner", AssignOwnerResponse);
        setLoading(false);
        setBtnDisable(false);
    }
    }, [AssignOwnerResponse]);

    const initialValues = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        role: "",
    };

    const handleSubmit = async (values) => {
    setBtnDisable(true);
    const Role = isAlreadyExist ? roleIfexist : values.role;
            {
            Role === "domain1"
            ? await setAPICall({
                payload: [
                    {
                    bussinessId: existUserid,
                    email: emailId,
                    phone: phone,
                    fname: `${isAlreadyExist ? existfname : values.fname}`,
                    lname: `${isAlreadyExist ? existlname : values.lname}`,
                    returnLink: `${SITE_URL}/user/create-password`,
                    permissions: [
                        {
                        category: "accounting",
                        role: "admin",
                        allScopes: true,
                        },
                    ],
                    },
                ],
                })
            : Role === "domain"
            ? await setAPICall({
                payload: [
                    {
                    bussinessId: existUserid,
                    email: emailId,
                    phone: phone,
                    fname: `${isAlreadyExist ? existfname : values.fname}`,
                    lname: `${isAlreadyExist ? existlname : values.lname}`,
                    returnLink: `${SITE_URL}/user/create-password`,
                    permissions: [
                        {
                        category: "accounting",
                        role: "manager",
                        scopeIds: selectedClientIds,
                        },
                    ],
                    },
                ],
                })
            : await setAPICall({
                payload: [
                    {
                    bussinessId: existUserid,
                    email: emailId,
                    phone: phone,
                    fname: `${isAlreadyExist ? existfname : values.fname}`,
                    lname: `${isAlreadyExist ? existlname : values.lname}`,
                    returnLink: `${SITE_URL}/user/create-password`,
                    permissions: [
                        {
                        category: "accounting",
                        role: "user",
                        scopeIds: selectedClientIds,
                        },
                    ],
                    },
                ],
                });
        }
    };

    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }

    const handleBusiness = async (event) => {
        const valid = isValidEmail(event.target.value);
        if (valid) {
        setEmailId(event.target.value);
        setSearchBusinessName(event.target.value);
    }
    };

    return (
    <>
        {showDetails && (
        <>
            <MDBox mt={0}>
            <Grid spacing={3}>
                <Grid item xs={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={isAlreadyExist ? AddNewEmailOwnerSchema : AddNewOwnerSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form autoComplete="off">
                        <MDBox p={3}>
                        <MDBox>
                            <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="email"
                                    name="email"
                                    label="Email *"
                                    style={{ marginTop: "-2px" }}
                                    type="text"
                                    helperText={helperText}
                                    variant="standard"
                                    InputLabelProps={{
                                    style: { color: darkMode ? "#ffffff" : "" }, // Change label color
                                    }}
                                    InputProps={{
                                    style: { color: darkMode ? "#ffffff" : "" }, // Change input text color
                                    }}
                                    FormHelperTextProps={{
                                    style: {
                                        color: "#ff6f00",
                                        fontSize: ".9em",
                                    },
                                    }}
                                    onChange={handleBusiness}
                                />
                                </Grid>
                            </Grid>
                            {emailExist && (
                                <Grid container spacing={3}>
                                    <Grid item mt={2} xs={12} sm={6}>
                                        <FormField type="text" label="First Name *" name="fname" />
                                    </Grid>
                                    <Grid item mt={2} xs={12} sm={6}>
                                        <FormField type="text" label="Last Name *" name="lname" />
                                    </Grid>
                                </Grid>
                            )}
                            {emailExist && (
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                <NumberFormat
                                    customInput={TextField}
                                    variant="standard"
                                    type="tel"
                                    fullWidth
                                    value={phone}
                                    label="Phone Number"
                                    InputLabelProps={{
                                        style: darkMode ? { color: "#ffffff" } : {},
                                    }}
                                    InputProps={{
                                        style: {
                                        color: darkMode ? "#ffffff" : "black",
                                        backgroundColor: darkMode ? "#00000000" : "",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        },
                                        mb: 1,
                                        fontSize: `0.823rem !important`,
                                    }}
                                    format="(###) ###-####"
                                    mask=""
                                    onValueChange={(value) => setPhone(value.formattedValue)}
                                    />
                                </Grid>
                                </Grid>
                            )}
                            {emailExist && 
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth variant="standard" sx={{ mt: 3 }}>
                                    <InputLabel sx={darkMode ? { mt: -3, color: "#ffffff" } : { mt: -3 }}>
                                        Role *
                                    </InputLabel>
                                    <Field
                                        as="select"
                                        name="role"
                                        variant="standard"
                                        style={{
                                        width: "100%",
                                        marginTop: "18px",
                                        borderTop: "0px",
                                        borderLeft: "0px",
                                        borderRight: "0px",
                                        borderRadius: "0px",
                                        borderBottom: darkMode ? "2px solid" : "1px solid",
                                        color: darkMode ? "lightgrey" : "slategray",
                                        backgroundColor: darkMode ? "transparent" : "inherit", // Adjust if a light mode background is needed
                                        }}
                                    >
                                        <option
                                        style={{ color: darkMode && "#ffffff", backgroundColor: darkMode && "#202940" }}
                                        value=""
                                        >
                                        Select Role
                                        </option>
                                        {Object.keys(User1).map((key) => (
                                        <option
                                            style={{ color: darkMode && "#ffffff", backgroundColor: darkMode && "#202940" }}
                                            value={key}
                                        >
                                            {User1[key]}
                                        </option>
                                        ))}
                                    </Field>
                                    <MDBox mt={0.75}>
                                        <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                        >
                                        <ErrorMessage name="role" />
                                        </MDTypography>
                                        <>
                                        {values.role == "domain1" ? (
                                            <MDTypography
                                            component="div"
                                            variant="caption"
                                            color="info"
                                            fontWeight="regular"
                                            >
                                            This user will have access to the entire Paynthr account and
                                            be able to add/edit users.
                                            </MDTypography>
                                        ) : ("")}
                                        {values.role == "domain" ? (
                                            <MDTypography
                                            component="div"
                                            variant="caption"
                                            color="info"
                                            fontWeight="regular"
                                            >
                                            This user will have access to the entire account except the
                                            billing page and be able to add/edit users
                                            </MDTypography>
                                        ) : ("")}
                                        {values.role == "client" ? (
                                            <MDTypography
                                            component="div"
                                            variant="caption"
                                            color="info"
                                            fontWeight="regular"
                                            >
                                            This user will have only have access to the Documents &
                                            Inventory page
                                            </MDTypography>
                                        ) : ("")}
                                        </>
                                        <>{setSelectedRole(values.role)}</>
                                    </MDBox>
                                    </FormControl>
                                    </Grid>
                                </Grid>
                            }
                            </MDBox>
                            <DialogContentText sx={{ color: "#bdbdbd", fontSize: `0.9rem` }}>
                            {isAdminRole && <span style={darkMode ? { color: "#ffffff" } : {}}>
                                {isAdminRole ? existMessage : ''} 
                            </span>}
                        </DialogContentText>
                        <MDBox mt={2} mb={-2} width="100%" display="flex" justifyContent="end">
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                            <MDButton
                                disabled={emailExistButton}
                                type="submit"
                                variant="gradient"
                                color="info"
                            >
                                {btnDisable ? "Submitting..." : "submit"}
                            </MDButton>
                            </MDBox>
                        </MDBox>
                        </MDBox>
                    </MDBox>
                    </Form>
                )}
                </Formik>
            </Grid>
            <MDBox>
                <MDSnackbar
                    color="error"
                    icon="warning"
                    title="Failed"
                    content={ErrorMessages}
                    open={showErrorMoveSnackbar}
                    close={() => setShowErrorMoveSnackbar(false)}
                />
                </MDBox>
                <MDBox>
                {loading && (
                    <CircularProgress
                    size={50}
                    sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                    />
                )}
                </MDBox>
            </Grid>
            </MDBox>
        </>
        )}
    </>
    );
}

export default AddNewUserDialog;
