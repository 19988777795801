import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import UserClientlist from "layouts/clientuser/clients";
import Inventory from 'layouts/clientuser/inventory';
import Restaurant from 'layouts/clientuser/restaurants';
import ClientsUser from "layouts/clientuser/user";
import ClientUserInvoice from "layouts/clientuser/user/invoice/invoice";
import NewClientsUser from 'layouts/clientuser/user/new-user';

function CheckClientDarkMode(){
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    
    let clientRoutes = [];
    if(!darkMode){
        clientRoutes = [
            // { type: "divider", key: "divider-0" },
            {
                type: "collapse",
                name: "Users",
                key: "user",
                module: "employees",
                action: "read",
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">groups</Icon>,
                route: "/client/user/list",
                noCollapse: true,
                component: <ClientsUser />,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">groups</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "clients-list",
                        module: "employees",
                        action: "read",
                        route: "/client/user/list",
                        component: <ClientsUser />,
                    },
                    {
                        name: "Add New",
                        key: "new-clients",
                        module: "employees",
                        action: "create",
                        route: "/client/user/new-user",
                        component: <NewClientsUser />,
                    },
                ],
            },
            // { type: "divider", key: "divider-1" },
            {
                type: "collapse",
                name: "Business",
                key: "client",
                module: "clients",
                action: "read",
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">workspaces</Icon>,
                route: "/client/client/list",
                component: <UserClientlist />,
                noCollapse: true,
            },
            // { type: "divider", key: "divider-0" },
            {
                type: "collapse",
                name: "Documents",
                key: "document",
                module: "documents",
                action: "read",
                route: "/client/document/:id",
                component: <ClientUserInvoice />,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">receipt</Icon>,
                noCollapse: true,
            },
            // { type: "divider", key: "divider-2" },
            {
                type: "collapse",
                name: "Restaurants",
                key: "restaurant",
                module: "restaurants",
                action: "read",
                route: "/client/restaurant/list",
                component: <Restaurant />,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">restaurant</Icon>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Inventory",
                key: "inventory",
                module: "inventories",
                action: "read",
                route: "/client/inventory/:id/list",
                component: <Inventory />,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">inventory</Icon>,
                noCollapse: true,
            },
        ];
    } else {
        clientRoutes = [
            // { type: "divider", key: "divider-0" },
            {
                type: "collapse",
                name: "Users",
                key: "user",
                module: "employees",
                action: "read",
                icon: <Icon fontSize="medium">groups</Icon>,
                route: "/client/user/list",
                noCollapse: true,
                component: <ClientsUser />,
                icon: <Icon fontSize="medium">groups</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "clients-list",
                        module: "employees",
                        action: "read",
                        route: "/client/user/list",
                        component: <ClientsUser />,
                    },
                    {
                        name: "Add New",
                        key: "new-clients",
                        module: "employees",
                        action: "create",
                        route: "/client/user/new-user",
                        component: <NewClientsUser />,
                    },
                ],
            },
            // { type: "divider", key: "divider-1" },
            {
                type: "collapse",
                name: "Business",
                key: "client",
                module: "clients",
                action: "read",
                icon: <Icon fontSize="medium">workspaces</Icon>,
                route: "/client/client/list",
                component: <UserClientlist />,
                noCollapse: true,
            },
            // { type: "divider", key: "divider-0" },
            {
                type: "collapse",
                name: "Documents",
                key: "document",
                module: "documents",
                action: "read",
                route: "/client/document/:id",
                component: <ClientUserInvoice />,
                icon: <Icon fontSize="medium">receipt</Icon>,
                noCollapse: true,
            },
            // { type: "divider", key: "divider-2" },
            {
                type: "collapse",
                name: "Restaurants",
                key: "restaurant",
                module: "restaurants",
                action: "read",
                route: "/client/restaurant/list",
                component: <Restaurant />,
                icon: <Icon  fontSize="medium">restaurant</Icon>,
                noCollapse: true,
            },
            {
                type: "collapse",
                name: "Inventory",
                key: "inventory",
                module: "inventories",
                action: "read",
                route: "/client/inventory/:id/list",
                component: <Inventory />,
                icon: <Icon fontSize="medium">inventory</Icon>,
                noCollapse: true,
            },
        ];
    }
    return clientRoutes;
}

export default CheckClientDarkMode;