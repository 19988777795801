import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EditProductApi, GetProductsList } from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import DraggableAddServiceProductDialog from "layouts/accountants/clients/setting/components/draggbleAddServiceProduct";
import DraggableEditProductDialog from "layouts/accountants/clients/setting/components/draggbleeditProduct";
import image from "layouts/accountants/clients/setting/output-onlinegiftools.gif";
import MDSnackbar from "components/MDSnackbar";

const Products = ({ businessId, syncPercent, documentSource }) => {
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const [productsList, setProductsList] = useState([]);
  const [productsId, setProductsId] = useState([]);
  const [openEditProducts, setOpenEditProducts] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAddNewProduct, setOpenAddNewProduct] = useState(false);
  const [openAddOption, setOpenAddOption] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [addProductSelectType, setAddProductSelectType] = useState("");
  const [showList, setShowList] = useState(true);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessages, setErrorMessage] = useState("");
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });
  const [{isLoading: isEditProductLoading, isError: isEditProductError, response: EditProductResponse }, EditProductListAPICall ] = useAPICall(EditProductApi, { onLoad: false });
  const { pathname } = useLocation();

  eventBus.$on("AddNewProductError", (data) => AddNewProductError(data));
  function AddNewProductError(data) {
    setErrorMessage(data?.message);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 5000);
  }


  eventBus.$on("EditProductError", (data) => EditProductError(data));
  function EditProductError(data) {
    setErrorMessage(data?.message);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 5000);
  }

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const offset = (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", Number(offset));
    ProductsListAPICall({
      payload: [
        {
          businessId: `${businessId}`,
          keyword: `${search}`,
          offset: Number(offset),
          limit: PerPagelimit,
        },
      ],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}`, keyword: `${search}`, offset: 0, limit: data }],
    });
  }

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(true);
      await ProductsListAPICall({
        payload: [
          { businessId: `${businessId}`, keyword: `${search}`, offset: ThisOffsetuse, limit: 10 },
        ],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    eventBus.$emit("OnSetBlurEdit", loading);
  }, [loading]);

  eventBus.$on("reloadProductDetailsList", (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  }

  eventBus.$on("AddnewProductComplete", (data) => AddnewProductComplete(data));
  function AddnewProductComplete(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  }

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await ProductsListAPICall({
            payload: [
              {
                businessId: `${businessId}`,
                keyword: `${search}`,
                offset: ThisOffsetuse,
                limit: PerPagelimit,
              },
            ],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (isEditProductLoading) {
      return () => null;
    }
    if (isEditProductError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditProductResponse && EditProductResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(false);
      ProductsListAPICall({
        payload: [{ businessId: `${businessId}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, [EditProductResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      setTotalRecords(ProductsResponse.data.data.totalRecords);
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        let USDollar = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return {
          name: prop.name,
          productType: prop.productType,
          taxable: prop.taxable === true ? "Yes" : "No",
          unitPrice: USDollar.format(prop.unitPrice),
          createdat: Createdate.toLocaleDateString(),
          action:
            documentSource === "Paynthr" ? (
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.edittooltip}
                    placement="top"
                    onClick={(value) => {
                      setProductsId(prop._id);
                      setOpenEditProducts(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                  </Tooltip>
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setProductsId(prop._id);
                      setAlert(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : syncPercent === 100 ? (
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.edittooltip}
                    placement="top"
                    onClick={(value) => {
                      setProductsId(prop._id);
                      setOpenEditProducts(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                  </Tooltip>
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setProductsId(prop._id);
                      setAlert(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : (
              ""
            ),
        };
      });
      setProductsList(data);
      setTimeout(() => {
        setShowList(true);
        setLoadingCircul(false);
        setLoading(false);
      }, 1000);
    }
  }, [ProductsResponse]);

  const handleOpenAddNewProduct = (data) => {
    setOpenAddNewProduct(true);
    setAddProductSelectType(data);
    setOpenAddOption(false);
  };

  const dataTableData1 = {
    columns: [
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Product Type",
        accessor: "productType",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "taxable",
        accessor: "taxable",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "unitPrice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: productsList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const successDelete = async () => {
    setLoading(true);
    await EditProductListAPICall({
      payload: [
        {
          isArchived: true,
          productId: `${productsId}`,
        },
      ],
    });
    setAlert(false);
  };
  return ProductsResponse?.data?.isSuccess === true && syncPercent < 100 ? (
    <>
      <Grid item xs={12}>
        <MDBox mt={5} mb={5}>
          <MDTypography
            variant="body2"
            color="secondary"
            sx={{
              width: "100%",
              fontSize: "1.20rem", 
              display: "flex",
              justifyContent: "center",
              mt: 2,
              ...(darkMode && {color: "#ffffff !important"})
            }}
          >
            {`Please bear with us while we retrieve information from QBO. This process may take a few moments...`}
          </MDTypography>
          <MDBox
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              fontSize: "1.20rem",
            }}
          >
            <div>
              <img
                width={23}
                height={23}
                style={{ marginBottom: "-6px", marginRight: "8px" }}
                src={image}
              />
            </div>
            <MDTypography sx={{ mr: 2 }} fontSize="1.20rem" color={"warning"}>
              ({syncPercent}% completed)
            </MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
    </>
  ) : (
    showList && (
      <>
        <MDBox
          px={2}
          sx={{ pr: 3, mb: 2 }}
          component="form"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h5" fontWeight="medium">
            Products
          </MDTypography>
          <MDBox px={2} display="flex">
            <MDBox sx={{ pr: 3, mb: 2 }}>
              <MDInput
                sx={{
                  color: darkMode ? "#ffffff" : "inherit", // Text color
                  "& .MuiInputBase-root": {
                    color: darkMode ? "#ffffff" : "inherit", // Text color
                  },
                  "& .MuiInputLabel-root": {
                    color: darkMode ? "#ffffff" : "#7b809a", // Label color
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: darkMode ? "#ffffff" : "#7b809a", // Label color when focused
                  },
                  "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                }}
                type="search"
                label="Search Products"
                onKeyDown={handleKeyDown}
                variant="standard"
                onChange={handleSearch}
              />
            </MDBox>
            <MDBox sx={{ pr: 3, mb: 2 }}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={() => setOpenAddOption(true)}
              >
                Add Product
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {
          <MDBox my={5} sx={{ mt: 1 }}>
            <DataTable1
              table={dataTableData1}
              entriesPerPage={false}
              pagination={true}
              showTotalEntries={true}
              response={totalRecords}
              showCheck={false}
              ClientHeigth={true}
            />
          </MDBox>
        }
        <MDBox display="flex" justifyContent="center">
          {loading && <Loader />}
        </MDBox>
        <MDBox>
          <Dialog
            open={alert}
            onClose={() => setAlert(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "450px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#7b809a",
                },
              },
            }}
          >
            <DialogContent sx={{ overflowY: "hidden" }}>
              <DialogContentText
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  color: darkMode ? "#ffffff" : "#7b809a",
                }}
              >
                <h3>Are you sure?</h3>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{
                    fontWeight: 300,
                    fontSize: "0.90rem",
                    textAlign: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  Once you delete this Customer there is no way of getting data back?
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
              <MDButton variant="gradient" color="success" onClick={successDelete}>
                Yes, delete it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={() => setAlert(false)}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
              <DraggableEditProductDialog
               open={openEditProducts}
                productId={productsId}
                businessId={businessId}
                onClose={() => setOpenEditProducts(false)}
              />
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
        <MDBox>
          <DraggableAddServiceProductDialog
            open={openAddNewProduct}
            addProductSelectType={addProductSelectType}
            invoiceId={""}
            businessId={businessId}
            onClose={() => setOpenAddNewProduct(false)}
          />
        </MDBox>
        <MDBox>
          <Dialog
            open={openAddOption}
            onClose={() => setOpenAddOption(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                  ...(darkMode && {
                    backgroundColor: "#202940"
                  })
                }
              }
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                justifyContent: "center",
                display: "flex",
                ...(darkMode && { color: "#ffffff" })
              }}
            >
              Product/Service information
            </DialogTitle>
            <DialogContent>
              <List
                sx={{
                  width: "100%",
                  bgcolor: darkMode ? "transparent" : "background.paper"
                }}
              >
                <ListItem
                  alignItems="flex-start"
                  disableGutters
                  secondaryAction={
                    <IconButton
                      style={darkMode ? { color: "#ffffff" } : {}}
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Service")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Service")}>
                    <ListItemAvatar>
                      <Avatar alt="Service" src="./components/services.png" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={darkMode ? { color: "#ffffff" } : {}} variant="body1">
                          Service
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              ...(darkMode && { color: "#ffffff" })
                            }}
                            component="span"
                            variant="body2"
                            color= {darkMode ? "#ffffff" : "text.primary"}
                          >
                            Services that you provide to customers, for example, landscaping or tax
                            preparation services.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem
                  alignItems="flex-start"
                  disableGutters
                  secondaryAction={
                    <IconButton
                      style={darkMode ? { color: "#ffffff" } : {}}
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Inventory")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Inventory")}>
                    <ListItemAvatar>
                      <Avatar alt="Inventory" src="/static/images/avatar/2.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={darkMode ? { color: "#ffffff" } : {}} variant="body1">
                          Inventory
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              ...(darkMode && { color: "#ffffff" })
                            }}
                            component="span"
                            variant="body2"
                            color= {darkMode ? "#ffffff" : "text.primary"}
                          >
                            Products you buy and/or sell and that you track quantities of.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem
                  alignItems="flex-start"
                  disableGutters
                  secondaryAction={
                    <IconButton
                      style={darkMode ? { color: "#ffffff" } : {}}
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Non Inventory")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton onClick={() => handleOpenAddNewProduct("Non Inventory")}>
                    <ListItemAvatar>
                      <Avatar alt="Non Inventory" src="/static/images/avatar/3.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={darkMode ? { color: "#ffffff" } : {}} variant="body1">
                          Non Inventory
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ 
                              display: "inline", 
                              color: darkMode ? "#ffffff" : "inherit" 
                            }}
                            component="span"
                            variant="body2"
                            color= {darkMode ? "#ffffff" : "text.primary"}
                          >
                            Products you buy and/or sell but don’t need to (or can’t) track
                            quantities of, for example, nuts and bolts used in an installation.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDSnackbar
            color="error"
            icon="warning"
            title="Failed."
            content={ErrorMessages}
            open={showErrorSnackbar}
            close={() => setShowErrorSnackbar(false)}
          />
      </>
    )
  );
};

export default UserHoc(Products);