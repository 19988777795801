import { Button, Card, Divider, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Hoc from "hoc";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import DataTableLog from "layouts/admin/components/Tables/DataTable/index.js";
import { useEffect, useState } from "react";
import { GetAdminBilling, GetClientLogsList, GetuserDetailsList, } from "services/accountantservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import Loader from "../accountants/components/imgLoader/loader";
import LoaderProfile from "../accountants/components/imgLoader/LoaderProfile";
import CardImg from "./CardImg";
import SetupForm from "./SetupForm.js";
import DataTable from "./Tables/DataTable/index";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function Billing() {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [lastDigit, setLastDigit] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [expmonth, setExpmonth] = useState("");
  const [expyear, setExpyear] = useState("");
  const [nextBillAmount, setNextBillAmount] = useState("");
  const [newCardText, setNewCardText] = useState(false);
  const [billingList, setBillingList] = useState([]);
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [totalClients, settotalClients] = useState("");
  const [loaderClass, setLoaderClass] = useState({});
  const [clientLogsList, setClientLogsList] = useState([]);
  const [showClientLogs, setShowClientLogs] = useState(false);
  const [invoiceLogId, setInvoiceLogId] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [accountantId, setAccountantId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [{isError: isBillingError, isLoading: isBillingLoading, response: Billingresponse },AdminBillingAPICall] = useAPICall(GetAdminBilling, { onLoad: false });
  const [{isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserDteailsAPICall ] = useAPICall(GetuserDetailsList, { onLoad: false });
  const [{isLoading: isGetClientLogsLoading, isError: isGetClientLogsError, response: userLogsDataResponse}, GetClientLogsAPICall] = useAPICall(GetClientLogsList, { onLoad: false });

  useEffect(() => {
    if (isGetClientLogsLoading) {
      return () => null;
    }

    if (isGetClientLogsError) {
      setLoading(false);
      return () => null;
    }

    if (userLogsDataResponse && userLogsDataResponse.data.isSuccess === true) {
      const data = userLogsDataResponse?.data?.data?.records.map((prop, key) => {
        return {
          clientname: prop?.item?.name ? prop?.item?.name : "-",
          startdate: prop?.billingPeriodStartDate ? prop?.billingPeriodStartDate : "-",
          enddate: prop?.billingPeriodEndDate ? prop?.billingPeriodEndDate : "-",
          totaldays: prop?.billableDays ? prop?.billableDays : "-",
          amount: prop?.amount ? `$${prop?.amount}` : "-",
        };
      });
      setLoading(false);
      setClientLogsList(data);
    }
  }, [userLogsDataResponse]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    AdminBillingAPICall({
      payload: [{ accountantId: accountantId }],
    });
    getUserDteailsAPICall({
      payload: [{ bussinessId: accountantId }],
    });
  }, []);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      setLoading(false);
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      const BillingDetails = userDetailsDataResponse?.data?.data?.accountantOwner;
      setNextBillAmount(BillingDetails?.subscriptions[0]?.nextBillAmount);
      setNextBillingDate(BillingDetails?.subscriptions[0]?.nextBillingDate);
      settotalClients(BillingDetails?.totalClients);
      setCardHolderName(BillingDetails?.cardHolderName ? BillingDetails?.cardHolderName : "---");
      setCardBrand(BillingDetails?.card?.brand);
      setLastDigit(BillingDetails?.card?.lastFourDigit);
      setExpmonth(BillingDetails?.card?.exp_month);
      setExpyear(BillingDetails?.card?.exp_year);
      setLoading(false);
    }
  }, [userDetailsDataResponse]);

  useEffect(() => {
    if (isBillingLoading) {
      return () => null;
    }
    if (isBillingError) {
      setLoading(false);
      return () => null;
    }
    if (Billingresponse && Billingresponse.data.isSuccess === true) {
      const data = Billingresponse?.data?.data?.records.map((prop, key) => {
        return {
          invoice: prop?.invoiceId ? prop?.invoiceId : "-",
          billingdate: prop?.periodStartDate ? prop?.periodStartDate : "-",
          amount: prop?.total ? `$${prop?.total}` : "-",
          clients: prop?.invoicedItems >= 1 ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  sx={{ cursor: "pointer" }}
                  onClick={async (value) => {
                    setLoading(true);
                    setShowClientLogs(true);
                    setInvoiceLogId(prop?.invoiceId);
                    GetClientLogsAPICall({
                      payload: [{ accuntantId: `${accountantId}`, invoiceId: `${prop?._id}` }],
                    });
                  }}
                >
                  {prop?.invoicedItems}
                </MDTypography>
              </MDBox>
            ) : (prop?.invoicedItems),
          paymentStatus: prop?.paymentStatus ? prop?.paymentStatus : "-",
        };
      });
      setBillingList(data);
    }
  }, [Billingresponse]);

  const dataTableLogsData = {
    columns: [
      {
        Header: " Client Name",
        accessor: "clientname",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "End Date",
        accessor: "enddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Total Days",
        accessor: "totaldays",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: clientLogsList,
  };

  const appearance = {
    theme: "stripe",
  };

  const options = {
    // passing the SetupIntent's client secret
    clientSecret: `${clientSecret}`,
    // Fully customizable with appearance API.
    appearance: appearance,
  };

  eventBus.$on("OnSubmitClick", (data) => OnSubmitClick(data));
  function OnSubmitClick(data) {
    setShowPaymentForm(false);
    setNewCardText(false);
  }

  eventBus.$on("EditPaymentForm", (data) => EditPaymentForm(data));
  function EditPaymentForm(data) {
    setNewCardText(true);
    if (response?.data?.isSuccess === true) {
      setShowPaymentForm(true);
    }
  }

  const BillingTableData1 = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Billing Date",
        accessor: "billingdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Clients",
        accessor: "clients",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [],
  };

  const BillingTableData = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Billing Date",
        accessor: "billingdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Clients",
        accessor: "clients",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: billingList,
  };
  
  return (
        <MDBox>
          <MDBox >
            <MDBox lineHeight={0}>
              <MDTypography variant="h5"> Billing</MDTypography>
            </MDBox>
            <MDBox my={3}>
              <MDBox mb={3} sx={loaderClass}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={10}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} xl={3}>
                        <DefaultInfoCard
                          icon="payment"
                          title="Next payment"
                          description={nextBillingDate == null ? `on  -` : `on ${nextBillingDate}`}
                          value={nextBillAmount ? `$${nextBillAmount}` : `$0`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={3}>
                        <DefaultInfoCard
                          icon="groups"
                          title="Total Clients"
                          description="Active"
                          value={totalClients ? totalClients : 0}
                        />
                      </Grid>
                      <Grid item xs={12} xl={6}>
                        {userDetailsDataResponse?.data?.data?.accountantOwner?.addedCard === false || userDetailsDataResponse?.data?.data?.accountantOwner?.card == null ? (
                          <MDBox>
                            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}></MDBox>
                            <Card
                              sx={{
                                border: 1,
                                borderColor: "#7239ea",
                                borderStyle: "dashed",
                                width: `360px`,
                                height: `224px`,
                                backgroundColor: darkMode ? "#202940" : "#ffffff",
                              }}
                            >
                              <MDBox
                                component="li"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                borderRadius="lg"
                                p={2} mb={1} mt={1}
                              >
                                <MDBox width="100%" display="flex" flexDirection="column">
                                  <MDBox
                                    display="flex"
                                    justifyContent="center"
                                    lineHeight={0}
                                    mt={5}
                                  >
                                    <MDTypography variant="caption" color="text">
                                      <MDTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                      >
                                        {/* Add a payment method for a subscription. */}
                                      </MDTypography>
                                    </MDTypography>
                                  </MDBox>
                                  <MDBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems={{ xs: "flex-start", sm: "center" }}
                                    flexDirection={{ xs: "column", sm: "row" }}
                                    mb={4} mt={4}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      fontWeight="medium"
                                      textTransform="capitalize"
                                    >
                                      No card added yet!
                                    </MDTypography>
                                  </MDBox>
                                  <MDBox
                                    display="flex"
                                    justifyContent="center"
                                    lineHeight={0} mt={5}
                                  >
                                    <MDTypography variant="caption" color="text">
                                      <MDTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                      >
                                        {/* Add a payment method for a subscription. */}
                                      </MDTypography>
                                    </MDTypography>
                                  </MDBox>
                                </MDBox>
                              </MDBox>
                            </Card>
                          </MDBox>
                        ) : (
                          <CardImg
                            number={`************${lastDigit}`}
                            holder={cardHolderName}
                            expires={`${expmonth}/${expyear}`}
                            cardBrand={cardBrand}
                            editCard={false}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Card
                    sx={loaderClass}
                    style={{height:'500px' , overflowY:'auto', backgroundColor: darkMode ? "#202940" : "#ffffff" }}
                  >
                    <MDBox
                      pt={3} px={2} sx={{ pr: 3, mb: 2, mt: -1 }}
                      component="form"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Billing History
                      </MDTypography>
                      <MDBox px={2} display="flex">
                        <MDBox sx={{ pr: 3, mb: 2 }}>
                          {/* <MDInput type="search" label="Search" variant="standard" /> */}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    {BillingTableData?.rows.length === 0 ? (
                      <MDBox my={2} mt={-0.5}>
                        <DataTable
                          table={BillingTableData1}
                          entriesPerPage={false}
                          pagination={false}
                          showTotalEntries={true}
                        />
                        <MDTypography
                          variant="body2"
                          color="secondary"
                          sx={{ mt: 15, marginLeft: "43%", fontSize: "1.20rem" }}
                        >
                          No billing history found!
                        </MDTypography>
                      </MDBox>
                    ) : (
                      <MDBox my={2} mt={-0.5}>
                        <DataTable
                          table={BillingTableData}
                          entriesPerPage={false}
                          pagination={true}
                          showTotalEntries={true}
                          showCheckBoxs={false}
                        />
                      </MDBox>
                    )}
                  </Card>
                  {loading && (
                    <MDBox display="flex" justifyContent="center">
                      <Loader />
                    </MDBox>
                  )}
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox>
            <Dialog
              open={showPaymentForm}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    height: "75%", 
                    maxWidth: "600px",
                    ...(darkMode && {
                      backgroundColor: "#202940 !important",
                      color: "white !important"
                    })
                  }
                }
              }}
            >
               <DialogTitle sx={darkMode ? {color:'#ffffff'}:{color:'#344767'}}>
                {newCardText == true ? "Edit Card Details" : "Add New Card"}
              </DialogTitle>
              <Divider sx={{ mt: -1 }} />
              <DialogContent>
                <Elements stripe={stripePromise} options={options}>
                  <SetupForm Secret={clientSecret} />
                </Elements>
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDBox>
              <Dialog
                open={showClientLogs}
                onClose={() => setShowClientLogs(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "auto",
                      maxWidth: "1000px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    },
                  },
                }}
              >
                <DialogTitle sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>
                  Invoice #{invoiceLogId}: clients
                </DialogTitle>
                <Divider sx={{ mt: -1, mb: -2 }} />
                <DialogContent sx={{ mb: 2 }}>
                  {loading && (
                    <MDBox display="flex" justifyContent="center">
                      <LoaderProfile />
                    </MDBox>
                  )}
                  <MDBox my={5} sx={{ mt: 2 }}>
                    <DataTableLog
                      table={dataTableLogsData}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowClientLogs(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            </MDBox>
          </MDBox>
        </MDBox>
  );
}

export default Hoc(Billing);