import { setPermission } from "actions/rolePermission";
import { getUserPermissions } from 'helpers/getUserPermissions';
import { getuserToken } from 'helpers/getusertoken';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import configureStore from "store";

function UserHoc(WrappedComponent) {
    return (props) => {
        const navigate = useNavigate();
        const token = getuserToken();
        const dispatch = useDispatch();
        var permission = configureStore.getState().roleReducer.permission;
        if (!token || token === undefined) {
            window.location.replace('/sign-in');
            navigate('/sign-in', { replace: true });
            return null;
        }

        if (token) {
            if (permission?.length === 0) {
                const permission = getUserPermissions();
                dispatch(setPermission(permission));
            }
            return <WrappedComponent {...props} />;

        } else {
            window.location.replace('/sign-in');
            navigate("/sign-in")
            return null;
        }

    }
}

export default UserHoc;