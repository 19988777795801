import { Switch, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import Hoc from "hoc";
import LoaderProfile from "layouts/accountants/user/components/imgLoader/LoaderProfile";
import { EditSubscriptionSchema } from "layouts/admin/accountants/components/schemas/editSubscriptionSchema";
import FormField from "layouts/auth/components/FormField";
import { useEffect, useState } from "react";
import { GetuserDetailsList } from "services/accountantservices";
import { EditAccountantPayment } from "services/adminservices";
import { useAPICall } from "utils/useapicall";
import { setDarkMode } from "context";
import { GetProfile } from "services/accountantservices";
import { useDispatch } from "react-redux";
import { setPermission, setProfileInfo, setRole } from "actions/rolePermission";
import Cookies from "universal-cookie";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { eventBus } from "utils/eventbus";

function AccountantSetting() {
  const cookies = new Cookies();
  const [controller, dispatch] = useMaterialUIController();
  const reduxDispatch = useDispatch();
  const { darkMode } = controller;
  const [editable, SetEditable] = useState(false);
  const [AccbussinessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [onTrialPeriod, setOnTrialPeriod] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disabledconfirm, setDisabledConfirm] = useState(true);
  const [helperText, setHelperText] = useState("");
  const [typeConfirm, setTypeConfirm] = useState(false);
  const [searchBusinessName, setSearchBusinessName] = useState("");
  const [businessName, setBusinessName] = useState("YES");
  const [loaderClass, setLoaderClass] = useState({});
  const [loading, setLoading] = useState(false);
  const [
    { isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse },
    getUserDteailsAPICall,
  ] = useAPICall(GetuserDetailsList, { onLoad: false });
  const [
    {
      isLoading: isUpdatePaymentLoading,
      isError: isUpdatePaymentError,
      response: UpadatePaymentResponse,
    },
    UpdatePaymentAPICall,
  ] = useAPICall(EditAccountantPayment, { onLoad: false });
  const [
    { isLoading: isAdminGetLoading, isError: isAdminGetError, response: profileResponse },
    getProfileAPICall,
  ] = useAPICall(GetProfile, { onLoad: false });

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    if (isAdminGetLoading) {
      return () => null;
    }

    if (isAdminGetError) {
      return () => null;
    }

    if (profileResponse && profileResponse.data.isSuccess === true) {
      setDarkMode(dispatch, profileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem(
        "PaynthrAdminUiThemeMode",
        profileResponse?.data?.data?.user?.themeColor
      );
      reduxDispatch(setProfileInfo(profileResponse.data.data));
      cookies.set("admin_profile_info", profileResponse.data.data, { path: "/" });
      if (
        profileResponse.data.data &&
        profileResponse.data.data.user &&
        profileResponse.data.data.user.userType &&
        profileResponse.data.data.user.userType != ""
      ) {
        reduxDispatch(setRole(profileResponse.data.data.user.userType));
        if (
          profileResponse.data.data.user &&
          profileResponse.data.data.user.permissions &&
          profileResponse.data.data.user.permissions[0] &&
          profileResponse.data.data.user.permissions[0].role &&
          profileResponse.data.data.user.permissions[0].role.grants
        ) {
          const adminPermission = profileResponse.data.data.user.permissions[0].role.grants;
          reduxDispatch(setPermission(adminPermission));
          cookies.set("permissions", adminPermission, { path: "/" });
        }
      }
    }
  }, [profileResponse]);

  useEffect(() => {
    if (isUpdatePaymentLoading) {
      return () => null;
    }

    if (isUpdatePaymentError) {
      setLoading(false);
      if (UpadatePaymentResponse?.data?.message) {
        setErrorMessage(UpadatePaymentResponse.data.message);
      } else {
        setErrorMessage("Failed to update subscription");
      }
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 4000);
      setBtnDisable(false);
      return () => null;
    }

    if (UpadatePaymentResponse && UpadatePaymentResponse.data.isSuccess === true) {
      if (UpadatePaymentResponse?.data?.data?.updated) {
        getUserDteailsAPICall({
          payload: [{ bussinessId: `${AccbussinessId}` }],
        });
        setLoading(false);
      }
      if (
        UpadatePaymentResponse?.data?.message &&
        UpadatePaymentResponse?.data?.message !== "Success"
      ) {
        setSuccessMessage(UpadatePaymentResponse.data.message);
      } else {
        setSuccessMessage("Subscription updated successfully");
      }
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setBtnDisable(false);
    }
  }, [UpadatePaymentResponse]);

  useEffect(async () => {
    setLoading(true);
    await getUserDteailsAPICall({
      payload: [{ bussinessId: `${AccbussinessId}` }],
    });
  }, []);

  const handleonTrialPeriod = () => {
    if (onTrialPeriod == true) {
      setTypeConfirm(true);
    }
    setOnTrialPeriod(!onTrialPeriod);
  };

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      setLoading(false);
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      setOnTrialPeriod(
        userDetailsDataResponse?.data?.data?.accountantOwner?.subscriptions[0]?.ontrial
      );
      setSubscriptionAmount(
        userDetailsDataResponse?.data?.data?.accountantOwner?.subscriptions[0]?.monthlyRate
      );
      setShowProducts(true);
      setLoading(false);
    }
  }, [userDetailsDataResponse]);

  const handleClose = () => {
    SetEditable(!editable);
  };

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    setLoading(true);
    await UpdatePaymentAPICall({
      payload: [
        {
          subscriptionId: `${AccbussinessId}`,
          updateObj: {
            rate: Number(values.subscriptionAmount),
            ontrial: onTrialPeriod,
          },
        },
      ],
    });
  };

  const initialValues = {
    subscriptionAmount: subscriptionAmount,
  };

  const hideAlert = () => {
    setTypeConfirm(false);
  };

  useEffect(() => {
    const str1 = searchBusinessName.replace(/ /g, "");
    const str2 = businessName.replace(/ /g, "");
    const timeOutId = setTimeout(async () => {
      if (str1 == str2.toUpperCase() && searchBusinessName.length !== 0) {
        setDisabledConfirm(false);
        setHelperText("");
      } else {
        setDisabledConfirm(true);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchBusinessName]);

  const handleBusiness = async (event) => {
    setSearchBusinessName(event.target.value.toUpperCase());
  };

  const handleCancel = async (event) => {
    setOnTrialPeriod(
      userDetailsDataResponse?.data?.data?.accountantOwner?.subscriptions[0]?.ontrial
    );
    setTypeConfirm(false);
  };

  return (
    <>
      <MDBox sx={loaderClass} my={2}>
        <Card sx={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
          <Box sx={{ width: "100%" }}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={EditSubscriptionSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  {showProducts ? (
                    <Card
                      sx={{ height: "100%", backgroundColor: darkMode ? "#202940" : "#ffffff" }}
                    >
                      <MDBox p={3}>
                        <MDBox>
                          <MDBox lineHeight={0} display="flex" justifyContent="space-between">
                            <MDTypography variant="h5">Edit Subscription</MDTypography>
                            <MDBox alignItems="center" lineHeight={1} display="flex"></MDBox>
                          </MDBox>
                          <MDBox mb={6} mt={2}>
                            <Grid container mb={0.5} spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <FormField
                                  type="text"
                                  label="Subscription Amount(per client) *"
                                  name="subscriptionAmount"
                                  value={subscriptionAmount}
                                  onChange={(e) => setSubscriptionAmount(e.target.value)}
                                  disabled={!editable}
                                  variant="standard"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MDBox alignItems="center" lineHeight={1} display="flex">
                                  <Switch
                                    disabled={!editable || !onTrialPeriod}
                                    checked={onTrialPeriod}
                                    onChange={handleonTrialPeriod}
                                  />
                                  <MDTypography variant="h6">On Trial Period</MDTypography>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                          <Grid sx={{ justifyContent: "end", display: "flex" }}>
                            <Grid>
                              <MDBox mt={{ xs: 2, sm: 3 }}>
                                {!editable && (
                                  <MDButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    onClick={() => SetEditable(true)}
                                  >
                                    Edit
                                  </MDButton>
                                )}
                              </MDBox>
                              {editable && (
                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                  <MDButton
                                    disabled={btnDisable}
                                    variant="gradient"
                                    color="light"
                                    onClick={handleClose}
                                  >
                                    Cancel
                                  </MDButton>
                                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                    <MDButton
                                      disabled={btnDisable}
                                      type="submit"
                                      variant="gradient"
                                      color="info"
                                      autoFocus
                                    >
                                      {btnDisable ? "Updating..." : "Update"}
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              )}
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </Card>
                  ) : (
                    <MDBox display="flex" justifyContent="center">
                      <LoaderProfile />
                    </MDBox>
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
        <Dialog
          open={typeConfirm}
          onClose={handleCancel}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                color: darkMode ? "#ffffff" : "#000000",
              },
            },
          }}
        >
          <DialogTitle sx={{ color: darkMode ? "#ffffff" : "#000000" }}>
            Stop Trial Period Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 4, mt: 2, color: darkMode ? "#ffffff" : "#000000" }}>
              Are you sure you want to stop the trial period for this customer? This action cannot
              be undone. The customer will no longer have access to trial features.
            </DialogContentText>
            <DialogContentText sx={{ mb: 2, mt: 4, color: darkMode ? "#ffffff" : "#000000" }}>
              Type "Yes" to confirm
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              // label="Business name"
              type="text"
              inputProps={{
                style: { textTransform: "uppercase", color: darkMode ? "#ffffff" : "#000000" },
              }}
              InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000000" } }}
              style={{ width: 250, borderColor: darkMode ? "#ffffff" : "#000000" }}
              error={disabledconfirm}
              helperText={helperText}
              variant="outlined"
              onChange={handleBusiness}
            />
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" color="error" onClick={handleCancel}>
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="success"
              onClick={hideAlert}
              disabled={disabledconfirm}
            >
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content={successMessage}
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          content={ErrorMessage || "Something went wrong"}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
      </MDBox>
    </>
  );
}

export default Hoc(AccountantSetting);
