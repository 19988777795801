import { FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { ErrorMessage, Form, Formik } from "formik";
import CurrencyFieldText from "layouts/accountants/user/components/CurrencyField-Text";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import { AddNewInvenory, GetInventoryUnitList, GetItemCategoryList, GetRestaurantLocationList } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import * as Yup from "yup";
import "./styles.css";

function AddInventoryDialog({ newProductName,newCategoryName, onClose, businessId, restaurantsId }) {
  const restaurantsNewId = localStorage.getItem("restaurantsNewId");
  const [isDisable, setBtnDisable] = useState(false);
  const [unitPrice, setUnitPrice] = useState("");
  const [restaurantId, setRestaurantId] = useState(restaurantsId !== 'all' ? restaurantsId : restaurantsNewId);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [inventoryUnitList, setInventoryUnitList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [editable, seteditable] = useState(false);
  const [addinventory, setAddinventory] = useState(false);
  const [locationNewValue, setLocationNewValue] = useState("");
  const [VendorsNewValue, setVendorsNewValue] = useState("");
  const [unitNewValue, setunitNewValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState(newCategoryName);
  const [categoryError, setCategoryError] = useState(false);
  const [unitPriceError, setUnitPriceError] = useState(false);
  const [currentInventory, setCurrentInventory] = useState("");
  const [itemName, setItemName] = useState(newProductName);
  const [price, setPrice] = useState(0);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewInvenory, {onLoad: false});
  const [{isLoading: isInventoryUnitLoading,isError: isInventoryUnitError,response: InventoryUnitResponse},InventoryUnitListAPICall] = useAPICall(GetInventoryUnitList, { onLoad: false });
  const [{isLoading: isCategoryLoading,isError: isCategoryError,response: CategoryResponse},CategoryListAPICall] = useAPICall(GetItemCategoryList, { onLoad: false });
  const [{isLoading: isLocationLoading, isError: isLocationError, response: LocationResponse },LocationListAPICall] = useAPICall(GetRestaurantLocationList, { onLoad: false });
  const [showSubmit, setShowSubmit] = useState(false);

  eventBus.$on("AddnewInventoryUnitSchemaComplete", (data) => AddnewInventoryUnitSchemaComplete(data));
  function AddnewInventoryUnitSchemaComplete(data) {
    setunitNewValue(data?.unit?.name);
    setLoadingCircul(false);
  }

  eventBus.$on("AddnewInventoryVendorComplete", (data) => AddnewInventoryVendorComplete(data));
  function AddnewInventoryVendorComplete(data) {
    setVendorsNewValue(data?.vendor?.name);
    setLoadingCircul(false);
  }

  eventBus.$on("AddnewLocationComplete", (data) => AddnewLocationComplete(data));
  function AddnewLocationComplete(data) {
    setLocationNewValue(data?.location?.name);
    setLoadingCircul(false);
  }

  useEffect(async () => {
    setItemName(newProductName);
    await InventoryUnitListAPICall({
      payload: [],
    });
    await CategoryListAPICall({
      payload: [],
    });
    await LocationListAPICall({
      payload: [{ businessId: `${restaurantId ? restaurantId : restaurantsId}` }],
    });
    setLoadingCircul(true);
  }, []);

  const initialValues = {
    itemName: itemName,
    inventoryUnit: unitNewValue,
    currentInventory: currentInventory,
    lowStockFlag: "",
    parflag: "",
    unitPrice: unitPrice,
    location: locationNewValue,
    vendors: VendorsNewValue,
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      eventBus.$emit("FailedToAddInventoryItem", isError);
      setBtnDisable(false);
      setShowSubmit(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("IsSuccessToAddInventoryItem", response);
      setBtnDisable(false);
      onClose();
    }
  }, [response]);

  useEffect(() => {
    if (isInventoryUnitLoading) {
      return () => null;
    }
    if (isInventoryUnitError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (InventoryUnitResponse && InventoryUnitResponse.data.isSuccess === true) {
      const data = InventoryUnitResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          InventoryUnitId: prop?._id,
          clientOwnerId: prop?.clientOwnerId,
        };
      });
      setInventoryUnitList(data);
      setLoadingCircul(false);
    }
  }, [InventoryUnitResponse]);


  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          CategoryId: prop?._id,
          clientDomainId: prop?.clientDomainId,
        };
      });
      setCategoryList(data);
      setLoadingCircul(false);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isLocationLoading) {
      return () => null;
    }
    if (isLocationError) {
      return () => null;
    }
    if (LocationResponse && LocationResponse.data.isSuccess === true) {
      const data = LocationResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          locationId: prop?._id,
        };
      });
      setLocationList(data);
    }
  }, [LocationResponse]);

  const handleSubmit = async (values) => {

    const errors = {
      category: categoryValue.length === 0,
      unitPrice: price === 0,
    };
    
    setCategoryError(errors.category);
    setUnitPriceError(errors.unitPrice);

    const hasError = Object.values(errors).some(error => error);

    // if (categoryValue.length == 0) {
    //   setCategoryError(true);
    // }
    if(!hasError){
      setCategoryError(false);
      setShowSubmit(true);
      setUnitPriceError(false);
    await setAPICall({
      payload: [
        {
          restaurantId: restaurantId ? restaurantId : location.pathname.split("/").slice(-2)[0],
          name: values?.itemName,
          category: categoryValue,
          location: locationNewValue,
          unitPrice: Number(price),
          unit: unitNewValue,
          currentInventory: Number(values?.currentInventory),
          lowStockFlag: Number(values?.lowStockFlag),
          parFlag: Number(values?.parflag),
          vendor: values?.vendors,
        },
      ],
    });
    }
  };

  const handleInventoryValueChange = (event, newInputValue) => {
    setunitNewValue(newInputValue);
  };

  const handleCategoryValueChange = (event, newInputValue) => {
    if(!newInputValue) {
      setCategoryError(true);
    }else{
      setCategoryError(false);
    }
    setCategoryValue(newInputValue);
  };

  const handleLocationValueChange = (event, newValue) => {
    setLocationNewValue(newValue);
  };

  const handleAddUnit = (newUnit) => {
    if (newUnit) {
      setInventoryUnitList((prev) => [...prev, { name: newUnit }]); // Add the new unit to the list
    }
  };

  const handleAddCategory = (newUnit) => {
    if (newUnit) {
      setCategoryList((prev) => [...prev, { name: newUnit }]); // Add the new unit to the list
    }
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              itemName: Yup.string()
                .trim()
                .required("Item name is required.")
                .min(3, "Item name should be more than 3 characters."),
              location: !locationNewValue
                ? Yup.string().nullable().required("Select Location")
                : "",
              // category: Yup.string().nullable().required("Select Category"),
              inventoryUnit: !unitNewValue
                ? Yup.string().nullable().required("Select Inventory Unit")
                : "",
              currentInventory: Yup.string("Current Inventory must be a number type")
                .matches(
                  /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                  "Current Inventory must be a number type"
                )
                .nullable()
                .required("Current Inventory is required"),
              lowStockFlag: Yup.string("Low Stock Flag must be a number type")
                .matches(
                  /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                  "Low Stock Flag must be a number type"
                )
                .nullable()
                .required("Low Stock Flag is required"),
              parflag: Yup.string("Par Flag be a number type")
                .matches(
                  /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                  "Par Flag must be a number type"
                )
                .nullable()
                .required("Par Flag is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox
                  sx={{
                    "@media (max-width: 1440px)": {
                      padding: "0px 16px 14px 16px",
                    },
                    padding: "16px 16px 20px 16px",
                  }}
                >
                  <MDBox>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "5px",
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Item Name *"
                          name="itemName"
                          disabled={editable}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "-4px",
                          },
                          "@media (max-width: 1920px)": {
                            mt: 0.7,
                          },
                          "@media (max-width: 1440px)": {
                            mt: 0.7,
                          },
                        }}
                      >
                        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                          <Autocomplete
                            sx={
                              darkMode ? {
                                "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root, & .MuiAutocomplete-popupIndicator": {
                                  color: "white !important"
                                },
                                "& .MuiInput-underline": {
                                  "&.Mui-disabled:before": {
                                    borderBottom: "1px solid #949494"
                                  },
                                  borderBottom: "2px solid #ffffff",
                                  "&:before": {
                                    borderBottomColor: "white"
                                  }
                                },
                                "& .MuiInputBase-input.Mui-disabled, & .MuiInput-root .MuiInput-input": {
                                  WebkitTextFillColor: "white"
                                },
                                "& .MuiFormLabel-root": {
                                  color: "white !important"
                                }
                              } : {}
                            }
                            noOptionsText={
                              <MDTypography sx={{ fontSize: "0.775rem", cursor: "pointer" }}>
                                {locationNewValue} (New Location)
                              </MDTypography>
                            }
                            disablePortal
                            name="location"
                            id="combo-box-demo"
                            ListboxProps={{
                              style: { maxHeight: "250px" },
                              position: "bottom-start",
                            }}
                            value={{ name: locationNewValue }}
                            options={locationList}
                            getOptionLabel={(option) => option.name}
                            disabled={editable}
                            onInputChange={(event, newInputValue) => {
                              handleLocationValueChange(event, newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Location *"
                                name="location"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: darkMode ? "#ffffff" : "#000000" },
                                }}
                                sx={{
                                  "& .MuiFormLabel-root": {
                                    marginTop: "-3px",
                                  },
                                }}
                              />
                            )}
                          />
                          {!locationNewValue && (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                <ErrorMessage name="location" />
                              </MDTypography>
                            </MDBox>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "12px",
                          },
                          "@media (max-width: 1920px)": {
                            mt: 0.4,
                          },
                          "@media (max-width: 1440px)": {
                            mt: 0.4,
                          },
                        }}
                      >
                        <CurrencyFieldText
                          label="Unit Price *"
                          name="unitPrice"
                          value={price}
                          fullWidth
                          onValueChange={(event) => {
                            if(!event.value) {
                              setUnitPriceError(true);
                            }else{
                              setUnitPriceError(false);
                            }
                            setPrice(event.value);
                          }}
                        />
                        {unitPriceError && (
                                <MDBox mt={0.75}>
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                  >
                                    Unit price is required
                                  </MDTypography>
                                </MDBox>
                              )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "-26px",
                          },
                          "@media (max-width: 1920px)": {
                            mt: 0.6,
                          },
                          "@media (max-width: 1440px)": {
                            mt: 0.6,
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Current Inventory *"
                          name="currentInventory"
                          disabled={editable}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{
                            "@media (max-width: 440px)": {
                              marginTop: "-24px",
                            },
                            "@media (max-width: 1920px)": {
                              marginTop: "13px !important",
                            },
                            "@media (max-width: 1440px)": {
                              marginTop: "13px !important",
                            },
                          }}
                        >
                          <Autocomplete
                            sx={
                              darkMode ? {
                                "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root, & .MuiAutocomplete-popupIndicator": {
                                  color: "#FFFFFF"
                                },
                                "& .MuiInput-underline.Mui-disabled:before": {
                                  borderBottom: "1px solid #949494"
                                },
                                "& .MuiInput-underline": {
                                  borderBottom: "2px solid #ffffff" 
                                },
                                "& .MuiInputBase-input.Mui-disabled, & .MuiInput-root .MuiInput-input": {
                                  WebkitTextFillColor: "white"
                                },
                                "& .MuiFormLabel-root": {
                                  color: "white !important"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "white"
                                }
                              } : {}
                            }
                            noOptionsText={
                              <MDTypography sx={{ fontSize: "0.775rem", cursor: "pointer" }} onClick={() => handleAddUnit(unitNewValue)} >
                                {unitNewValue} (New Unit)
                              </MDTypography>
                            }
                            name="inventoryUnit"
                            disablePortal
                            id="combo-box-demo"
                            ListboxProps={{
                              style: { maxHeight: "150px" },
                              position: "bottom-start",
                            }}
                            value={{ name: unitNewValue }}
                            options={inventoryUnitList}
                            getOptionLabel={(option) => option.name}
                            disabled={editable}
                            onInputChange={(event, newInputValue) => {
                              handleInventoryValueChange(event, newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Inventory Unit *"
                                name="inventoryUnit"
                                variant="standard"
                                sx={{
                                  "& .MuiFormLabel-root": {
                                    marginTop: "-3px",
                                    color: darkMode ? "#ffffff" : "#7b809a !important",
                                  },
                                }}
                              />
                            )}
                          />
                          {!unitNewValue && (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                <ErrorMessage name="inventoryUnit" />
                              </MDTypography>
                            </MDBox>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginBottom: "24px",
                            marginTop: "16px",
                          },
                        }}
                      >
                        <FormField type="text" label="Vendor" name="vendors" disabled={editable} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{
                            "@media (max-width: 440px)": {
                              marginTop: "-24px",
                            },
                            "@media (max-width: 1920px)": {
                              marginTop: "10px !important",
                            },
                            "@media (max-width: 1440px)": {
                              marginTop: "10px !important",
                            },
                          }}
                        >
                          <Autocomplete
                            sx={
                              darkMode ? {
                                "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root, & .MuiAutocomplete-popupIndicator": {
                                  color: "#FFFFFF"
                                },
                                "& .MuiInput-underline.Mui-disabled:before": {
                                  borderBottom: "1px solid #949494"
                                },
                                "& .MuiInput-underline": {
                                  borderBottom: "2px solid #ffffff" 
                                },
                                "& .MuiInputBase-input.Mui-disabled, & .MuiInput-root .MuiInput-input": {
                                  WebkitTextFillColor: "white"
                                },
                                "& .MuiFormLabel-root": {
                                  color: "white !important"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "white"
                                }
                              } : {}
                            }
                            noOptionsText={
                              <MDTypography sx={{ fontSize: "0.775rem", cursor: "pointer" }} onClick={() => handleAddCategory(categoryValue)} >
                                {categoryValue} (New Category)
                              </MDTypography>
                            }
                            name="category"
                            disablePortal
                            id="combo-box-demo"
                            ListboxProps={{
                              style: { maxHeight: "150px" },
                              position: "bottom-start",
                            }}
                            value={{ name: categoryValue }}
                            options={categoryList}
                            getOptionLabel={(option) => option.name}
                            disabled={editable}
                            onInputChange={(event, newInputValue) => {
                              handleCategoryValueChange(event, newInputValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category *"
                                name="category"
                                variant="standard"
                                sx={{
                                  "& .MuiFormLabel-root": {
                                    marginTop: "-3px",
                                    color: darkMode ? "#ffffff" : "#7b809a !important",
                                  },
                                }}
                              />
                            )}
                          />
                          {categoryError && (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                Category is required
                              </MDTypography>
                            </MDBox>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{ mb: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginTop: "-20px",
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Low Stock Flag *"
                          name="lowStockFlag"
                          disabled={editable}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          "@media (max-width: 440px)": {
                            marginTop: "-20px",
                          },
                        }}
                      >
                        <FormField
                          type="text"
                          label="Par Flag *"
                          name="parflag"
                          disabled={editable}
                        />
                        <>
                          {values?.parflag &&
                          Number(values?.lowStockFlag) > Number(values?.parflag) ? (
                            <MDBox mt={-0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                Par Flag must be Greater than Low Stock
                              </MDTypography>
                            </MDBox>
                          ) : (
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              ></MDTypography>
                            </MDBox>
                          )}
                        </>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox
                    mb={-2}
                    sx={{
                      "@media (max-width: 1440px)": {
                        marginTop: "16px",
                      },
                      marginTop: "40px",
                    }}
                    display="flex"
                    justifyContent="end"
                  >
                    {editable === true && (
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="info"
                        onClick={() => setAddinventory(true)}
                      >
                        Add Inventory
                      </MDButton>
                    )}
                    {editable === false && (
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          onClick={() => seteditable(false)}
                        >
                          {showSubmit === true ? "Adding..." : "Add"}
                        </MDButton>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default AddInventoryDialog;