import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green, yellow } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import image from "assets/images/loders/smallgrayloader.gif";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DataTable1 from "layouts/accountants/user/invoice/invoiceTable.js";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { eventBus } from "utils/eventbus";
import { canAccess } from "../../../../helpers";
import {
  DeleteInvoice,
  GetInvoiceDetails,
  GetInvoiceList,
  GetIssueList,
  GetStatusCount,
  GetuserClientList,
  MoveMenuInvoice,
} from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../clientuser/user/components/Navbars/DashboardNavbar";
import DraggableNewInvoiceDialog from "./components/draggbleNewInvoiceDialog";
import EditStatementDialog from "./editStatementdialog";
import EditInvoiceDialog from "./editinvoicedialog";
import ErrorEditInvoiceDialog from "./erroreditinvoicedialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AccountantInvoice() {
  const [value, setValue] = useState("AskClient");
  const navigate = useNavigate();
  const action = {
    Movetooltip: "Move",
    showtooltip: "View",
    edittooltip: "Edit",
    deletetooltip: "Delete",
  };
  const [InvoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [checksynced, setChecksynced] = useState(false);
  const [reloadApi, setReloadApi] = useState(0);
  const [hideLoaderForStart, setHideLoaderForStart] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showMoveSnackbar, setShowMoveSnackbar] = useState(false);
  const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
  const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
  const [showErrorDeleteSnackbar, setShowErrorDeleteSnackbar] = useState(false);
  const [moveErrorMessage, setMoveErrorMessage] = useState("");
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [openErrorEditInvoice, setErrorOpenEditInvoice] = useState(false);
  const [showSelectBusiness, setShowSelectBusiness] = useState(false);
  const [counReconciled, setCounReconciled] = useState(0);
  const [headerFilter, setHeaderFilter] = useState({});
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInvoiceList, {
    onLoad: false,
  });
  const [
    { isLoading: isCountLoading, isError: isCountError, response: CountResponse },
    CountUserAPICall,
  ] = useAPICall(GetStatusCount, { onLoad: false });
  const [
    { isLoading: isClientLoading, isError: isClientError, response: ClientResponse },
    ClientUserAPICall,
  ] = useAPICall(GetuserClientList, { onLoad: false });
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openEditInvoice, setOpenEditInvoice] = useState(false);
  const [openEditStatement, setOpenEditStatement] = useState(false);
  const [countAll, setCountAll] = useState(0);
  const [countAskClient, setCountAskClient] = useState(0);
  const [invoiceId, setInvoiceId] = useState("");
  const [showrowsData, setShowrowsData] = useState([]);
  const [autoCompleteObj, setAutoCompleteObj] = useState([]);
  const [showselectedrowsData, setShowSelectedrowsData] = useState("");
  const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-1)[0]);
  const [onChangeBusinessId, setOnchageBusinessId] = useState("");
  const [openSelectInvoice, setOpenSelectInvoice] = useState(false);
  const [openNoInvoice, setOpenNoInvoice] = useState(false);
  const [clientEvent, setClientEvent] = useState("");
  const [clientNewValue, setClientNewValue] = useState("");
  const [selectTab, setSelectTab] = useState("AskClient");
  const [SelectedRows, setSelectedRows] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [selectUseEffect, setSelectUseEffect] = useState();
  const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
  const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
  const [alertIssueInvoice, setalertIssueInvoice] = useState(false);
  const [tabsMove, setTabsMove] = useState("");
  const [showCompleteSnackbar, setShowCompleteSnackbar] = useState(false);
  const [completemassage, setCompletemassage] = useState("");
  const [titleShow, setTitleShow] = useState("success");
  const [iconShow, setIconShow] = useState("done");
  const [documentStatusId, setdocumentStatusId] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(0);
  const [StoreInvoiceNumber, setStoreInvoiceNumber] = useState("");
  const [storeResponce, setStoreResponce] = useState({});
  const [invalidDocumnet, setInvalidDocumnet] = useState(false);
  const [loaderClass, setLoaderClass] = useState({});
  const limit = localStorage.getItem("limit");
  const [disButton, setDisButton] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [disableMoveButton, setDisableMoveButton] = useState(false);
  const [hideNodoc, setHideNodoc] = useState(true);
  const [startSearchLoader, setStartSearchLoader] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [
    { isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse },
    DetailsUserAPICall,
  ] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [
    {
      isLoading: isDeleteInvoiceLoading,
      isError: isDeleteInvoiceError,
      response: DeleteInvoiceResponse,
    },
    DeleteInvoiceListAPICall,
  ] = useAPICall(DeleteInvoice, { onLoad: false });
  const [
    { isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse },
    MoveMenuListAPICall,
  ] = useAPICall(MoveMenuInvoice, { onLoad: false });
  const [
    { isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse },
    IssueListAPICall,
  ] = useAPICall(GetIssueList, { onLoad: false });
  const [searchParams, setSearchParams] = useSearchParams();
  var resource = "";
  var actions = "";
  const { pathname } = useLocation();

  const useStyles = makeStyles((theme) => ({
    tabs: {
      "& .MuiTab-root": {
        backgroundColor: "#202940e3 !important",
        color: "#ffffff !important",
        borderRadius: 4,
        margin: "2px",
        padding: "6px",
        "&:hover": {
          backgroundColor: "#38405585 !important", // Change label color to blue on hover
        },
      },
      "& .MuiTabs-flexContainer": {
        backgroundColor: darkMode ? "#1a2035 !important" : "#ffffff",
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#ffffff !important",
        backgroundColor: "#006cbe !important",
        margin: "2px",
        padding: "6px",
        borderRadius: 4,
        "&:hover": {
          backgroundColor: "#006cbe !important", // Change label color to blue on hover
        },
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
  const classes = useStyles();

  useEffect(async () => {
    localStorage.setItem("limit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      if (businessId !== ":id") {
        setLoading(true);
        await setAPICall({
          payload: [
            {
              businessId: businessId,
              limit: 10,
              documentStatus: selectTab,
              keyword: `${search}`,
              offset: ThisOffsetuse,
              vendorName: headerFilter?.vendor,
              invoiceNo: headerFilter?.invoice,
              createdAt: headerFilter?.uplaoddate,
              ocrStatus: headerFilter?.SELECT,
            },
          ],
        });
      } else {
        setLoadingCircul(true);
        setOpenSelectInvoice(true);
      }
    }
  }, []);

  eventBus.$on("startSearchLoader", (data) => {
    setStartSearchLoader(data);
  });

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
    if (businessId !== ":id" && search.length !== 0) {
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [
              {
                businessId: businessId,
                documentStatus: selectTab,
                keyword: `${search}`,
                offset: ThisOffsetuse,
                vendorName: headerFilter?.vendor,
                invoiceNo: headerFilter?.invoice,
                createdAt: headerFilter?.uplaoddate,
                ocrStatus: headerFilter?.SELECT,
              },
            ],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    } else if (businessId !== ":id" && search.length == 0) {
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [
              {
                businessId: businessId,
                documentStatus: selectTab,
                keyword: `${search}`,
                offset: ThisOffsetuse,
                vendorName: headerFilter?.vendor,
                invoiceNo: headerFilter?.invoice,
                createdAt: headerFilter?.uplaoddate,
                ocrStatus: headerFilter?.SELECT,
              },
            ],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search, headerFilter]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    setHideLoaderForStart(true);
    const urlParams = new URL(window.location.href).searchParams;
    const DocId = urlParams.get("id");
    if (DocId) {
      setLoading(true);
      DetailsUserAPICall({
        payload: [{ documentId: `${DocId}`, businessId: `${businessId}` }],
      });
      setInvoiceId(DocId);
    }
  }, []);

  const removeQueryParams = () => {
    const param = new URL(window.location.href).searchParams;
    if (param) {
      searchParams.delete("id");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      removeQueryParams();
      setInvalidDocumnet(true);
      setTimeout(() => {
        setInvalidDocumnet(false);
      }, 5000);
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setOpenEditInvoice(true);
    }
  }, [DetailsResponse]);

  useEffect(() => {
    if (isIssueLoading) {
      return () => null;
    }
    if (isIssueError) {
      setLoading(false);
      return () => null;
    }
    if (IssueResponse && IssueResponse.data.isSuccess === true) {
      setIssueList(IssueResponse.data.data.records);
      setalertIssueInvoice(true);
      setLoading(false);
    }
  }, [IssueResponse]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
  };

  const darkModestyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    color: "#FFFFFF",
    bgcolor: colors.background.card,
    border: "0",
    boxShadow: 24,
    outline: "none",
    p: 4,
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: "#fff",
    },
  };

  function generate(element) {
    return issueList.map((values) => (
      <ListItem>
        <ListItemText primary={`${values.message}`} />
      </ListItem>
    ));
  }

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  useEffect(() => {
    if (isMoveMenuLoading) {
      return () => null;
    }
    if (isMoveMenuError) {
      setLoading(false);
      setDisableMoveButton(false);
      if (response?.data?.message) {
        setMoveErrorMessage(response.data.message);
      }
      setShowErrorMoveSnackbar(true);
      setTimeout(() => {
        setShowErrorMoveSnackbar(false);
      }, 3000);
      setalertMoveInvoice(false);
      return () => null;
    }
    if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
      setLoading(false);
      const ThisOffsetuse = localStorage.getItem("offset");
      setAPICall({
        payload: [
          {
            businessId: `${businessId}`,
            documentStatus: selectTab,
            keyword: `${search}`,
            offset: ThisOffsetuse,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
          },
        ],
      });
      CountUserAPICall({
        payload: [{ businessId: `${businessId}` }],
      });
      setalertMoveInvoice(false);
      setShowMoveSnackbar(true);
      setDisableMoveButton(false);
      setTimeout(() => {
        setShowMoveSnackbar(false);
      }, 3000);
    }
  }, [MoveMenuResponse]);

  useEffect(() => {
    if (isDeleteInvoiceLoading) {
      return () => null;
    }
    if (isDeleteInvoiceError) {
      setLoading(false);
      if (response?.data?.message) {
        setDeleteErrorMessage(response.data.message);
      }
      setShowErrorDeleteSnackbar(true);
      setTimeout(() => {
        setShowErrorDeleteSnackbar(false);
      }, 3000);
      setAlertDeleteInvoice(false);
      return () => null;
    }
    if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
      const ThisOffsetdelete = localStorage.getItem("offset");
      setAPICall({
        payload: [
          {
            businessId: `${businessId}`,
            documentStatus: selectTab,
            keyword: `${search}`,
            offset: ThisOffsetdelete,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
          },
        ],
      });
      CountUserAPICall({
        payload: [{ businessId: `${businessId}` }],
      });
      setLoading(false);
      setAlertDeleteInvoice(false);
      setShowDeleteSnackbar(true);
      setTimeout(() => {
        setShowDeleteSnackbar(false);
      }, 3000);
    }
  }, [DeleteInvoiceResponse]);

  const [anchor, setAnchor] = useState(null);
  const [selected, setSelected] = useState(-1);

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeAfterMove = async () => {
    setOpenEditStatement(false);
    setOpenEditInvoice(false);
    setLoading(true);
    const ThisOffsetuse = localStorage.getItem("offset");
    await setAPICall({
      payload: [
        {
          businessId: businessId,
          documentStatus: selectTab,
          keyword: `${search}`,
          offset: ThisOffsetuse,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
        },
      ],
    });
    await CountUserAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = async (event, index) => {
    setAnchor(null);
    setSelected(index);
    var tabs = "";
    if (value === 1) {
      if (index === 0) {
        var tabs = "ToReview";
      } else if (index === 1) {
        var tabs = "PendingApproval";
      } else if (index === 2) {
        var tabs = "";
      }
    } else {
      if (index === 1) {
        var tabs = "";
      } else if (index === 0) {
        var tabs = "PendingApproval";
      }
    }

    setTabsMove(tabs);
    setalertMoveInvoice(true);
    // setLoading(true);
  };

  var tabRowsData = [];

  if (value === 1) {
    var tabRowsData = ["To Review", "Pending Approval"];
  } else {
    var tabRowsData = ["Pending Approval"];
  }

  useEffect(async () => {
    const ThisOffsetuse = localStorage.getItem("offset");
    if (onChangeBusinessId) {
      await setAPICall({
        payload: [
          {
            businessId: `${onChangeBusinessId}`,
            documentStatus: selectTab,
            keyword: `${search}`,
            offset: ThisOffsetuse,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
          },
        ],
      });
      await CountUserAPICall({
        payload: [{ businessId: `${onChangeBusinessId}` }],
      });
      setBusinessId(onChangeBusinessId);
      setLoading(true);
      setValue(selectTab);
      navigate(`/client/document/${onChangeBusinessId}`);
    }
  }, [onChangeBusinessId]);

  eventBus.$on("refreshListAfterUpdate", (data) => RefreshListAfterUpdate(data));
  function RefreshListAfterUpdate(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(false);
    setAPICall({
      payload: [
        {
          businessId: `${businessId}`,
          documentStatus: selectTab,
          keyword: `${search}`,
          offset: ThisOffsetuse,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
        },
      ],
    });
    CountUserAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("onclickchangePage", (data) => OnclickchangePage(data));
  function OnclickchangePage(data) {
    setLoading(true);
    const offset =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(limit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(limit) - Number(limit);
    localStorage.setItem("offset", offset);
    setAPICall({
      payload: [
        {
          businessId: `${businessId}`,
          documentStatus: selectTab,
          keyword: `${search}`,
          offset: offset,
          limit: limit,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
        },
      ],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    setLoading(true);
    localStorage.setItem("limit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [
        {
          businessId: `${businessId}`,
          documentStatus: selectTab,
          limit: data,
          offset: 0,
          keyword: `${search}`,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
        },
      ],
    });
  }

  eventBus.$on("closeDeleteItemPopup", (data) => CloseInvoicesPopup(data));
  function CloseInvoicesPopup(data) {
    setOpenEditInvoice(false);
    setLoading(false);
    const ThisOffsetuse = localStorage.getItem("offset");
    setAPICall({
      payload: [
        {
          businessId: `${businessId}`,
          keyword: `${search}`,
          documentStatus: selectTab,
          offset: ThisOffsetuse,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
        },
      ],
    });
    CountUserAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("closeNewInvoicePopup1", (data) => closeNewInvoicePopup1(data));
  function closeNewInvoicePopup1(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    if (data.code === "ERR_BAD_REQUEST") {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setTimeout(() => {
        setOpenInvoice(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setOpenInvoice(false);
      }, 1000);
      setLoading(true);
      setAPICall({
        payload: [
          {
            businessId: businessId,
            keyword: `${search}`,
            documentStatus: selectTab,
            offset: ThisOffsetuse,
            vendorName: headerFilter?.vendor,
            invoiceNo: headerFilter?.invoice,
            createdAt: headerFilter?.uplaoddate,
            ocrStatus: headerFilter?.SELECT,
          },
        ],
      });
      CountUserAPICall({
        payload: [{ businessId: businessId }],
      });
    }
  }

  eventBus.$on("disableclosebutton", (data) => disableclosebutton(data));
  function disableclosebutton(data) {
    setDisButton(data);
  }

  eventBus.$on("closeNewInvoicePopup", (data) => fetchNewInvoices(data));

  function fetchNewInvoices(data) {
    setOpenInvoice(false);
    if (data.code === "ERR_BAD_REQUEST") {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setTimeout(() => {
        setOpenInvoice(false);
      }, 1000);
    } else {
      setOpenInvoice(false);
    }
  }

  const handleChangeBusiness = (event, newValue) => {
    setInvoiceList([]);
    setCountAll(0);
    setCountAskClient(0);
    setCounReconciled(0);
    setHideLoaderForStart(false);
    var eventValue = "";
    if (event.target.value) {
      var eventValue = event.target.value;
    } else {
      var eventValue = newValue.label;
    }
    ClientResponse.data.data.records.map((prop, key) => {
      prop.name == eventValue ? setOnchageBusinessId(prop._id) : "";
    });
    setShowSelectedrowsData(eventValue);
    setOpenSelectInvoice(false);
    setLoading(true);
    const found1 = ClientResponse?.data?.data?.records.find((obj) => {
      return obj.name === eventValue;
    });
    if (found1?.syncPercent === 100) {
      setChecksynced(false);
      ClientUserAPICall({
        payload: [{ keyword: `` }],
      });
    } else {
      setChecksynced(true);
      ClientUserAPICall({
        payload: [{ keyword: `` }],
      });
    }
  };

  const handleClose = () => {
    setOpenInvoice(false);
  };

  const handleCloseSelect = () => {
    if (showselectedrowsData) {
      setOpenSelectInvoice(false);
    } else {
      navigate("/client/client/list");
    }
  };

  eventBus.$on("GetDocumentinputValues", (data) => GetDocumentinputValues(data));
  function GetDocumentinputValues(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setHeaderFilter(data);
    if (businessId !== ":id") {
      setAPICall({
        payload: [
          {
            businessId: businessId,
            keyword: `${search}`,
            documentStatus: selectTab,
            offset: ThisOffsetuse,
            vendorName: data?.vendor,
            invoiceNo: data?.invoice,
            createdAt: data?.uplaoddate,
            ocrStatus: data?.SELECT,
          },
        ],
      });
    }
  }

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const Tabs = queryParameters.get("t");
    if (Tabs) {
      setValue(Tabs == "ToReview" ? "AskClient" : Tabs);
      setSelectTab(Tabs == "ToReview" ? "AskClient" : Tabs);
    } else {
      setValue("AskClient");
      setSelectTab("AskClient");
    }
  }, []);

  const handleChange = async (event, newValue) => {
    setHideNodoc(false);
    setInvoiceList([]);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?k=${encodeURIComponent(search)}&t=${encodeURIComponent(
        newValue == "AskClient" ? "ToReview" : newValue
      )}`
    );
    localStorage.setItem("offset", 0);
    const ThisOffsetuse = localStorage.getItem("offset");
    setValue(newValue);
    setLoading(true);
    setSelectTab(newValue);
    await setAPICall({
      payload: [
        {
          businessId: businessId,
          keyword: `${search}`,
          documentStatus: newValue,
          offset: ThisOffsetuse,
          vendorName: headerFilter?.vendor,
          invoiceNo: headerFilter?.invoice,
          createdAt: headerFilter?.uplaoddate,
          ocrStatus: headerFilter?.SELECT,
        },
      ],
    });
    await CountUserAPICall({
      payload: [{ businessId: businessId }],
    });
  };

  useEffect(async () => {
    setLoading(true);
    await ClientUserAPICall({
      payload: [{ keyword: `` }],
    });
    if (businessId !== ":id") {
      setHideLoaderForStart(false);
      await CountUserAPICall({
        payload: [{ businessId: businessId }],
      });
    } else {
      setLoadingCircul(true);
      setOpenSelectInvoice(true);
    }

    eventBus.$on("invoiceRowSelected", (data) => {
      setSelectUseEffect(data.selectedRowIds.length);

      if (data.selectedRowIds.length) {
        setSelectedRows(data.selectedRowIds);
      }
    });
    return () => {
      eventBus.$off("invoiceRowSelected");
    };
  }, []);

  useEffect(() => {
    if (isClientLoading) {
      return () => null;
    }

    if (isClientError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }

    if (ClientResponse && ClientResponse.data.isSuccess === true) {
      if (businessId == ":id") {
        setOpenSelectInvoice(true);
        setLoading(false);
        setLoadingCircul(true);
      }
      const filteredUsers = ClientResponse?.data?.data?.records.filter(
        (user) => user.accountant != null
      );

      ClientResponse.data.data.records.map((prop, key) => {
        if (businessId == ":id" && prop) {
          // setValue(0);

          prop._id === businessId ? setShowSelectedrowsData(prop.name) : "";
          if (prop.length === 0) {
            navigate("/client/client/list");
            // setOpenNoInvoice(true);
          } else if (prop.length === 1) {
            setShowSelectBusiness(false);
            setBusinessId(prop._id);
            setOnchageBusinessId(prop._id);
            setShowSelectedrowsData(prop.name);
            navigate(`/client/document/${prop._id}`);
          } else {
            setShowSelectBusiness(true);
            // setBusinessId(prop._id);
            setLoadingCircul(true);
            setOpenSelectInvoice(true);
          }
        } else {
          prop._id === businessId ? setShowSelectedrowsData(prop.name) : "";
        }

        const found2 = ClientResponse?.data?.data?.records.find((obj) => {
          return obj._id === onChangeBusinessId ? onChangeBusinessId : businessId;
        });
        if (found2?.syncPercent === 100) {
          setChecksynced(false);
        } else {
          setChecksynced(true);
        }
      });
      const array = filteredUsers?.map((obj) => Object.values(obj));
      setShowrowsData(array);
      var obj = array.map((x) => ({
        label: x[1],
      }));
      if (array.length > 1) {
        setShowSelectBusiness(true);
      }
      setAutoCompleteObj(obj);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [ClientResponse]);

  useEffect(() => {
    if (isCountLoading) {
      return () => null;
    }

    if (isCountError) {
      return () => null;
    }

    if (CountResponse && CountResponse.data.isSuccess === true) {
      setCountAll(CountResponse?.data?.data?.countAll);
      setCountAskClient(CountResponse?.data?.data?.countAskClient);
      setCounReconciled(CountResponse?.data?.data?.countReconciled);
    }
  }, [CountResponse]);

  useEffect(() => {
    if (documentStatusId === "ERROR") {
      setTitleShow("error");
      setIconShow("warning");
      setCompletemassage("Unable to read invoice");
    } else {
      setTitleShow("success");
      setIconShow("done");
      setCompletemassage(
        `Invoice Upload ${
          documentStatusId
            ? documentStatusId.charAt(0).toUpperCase() + documentStatusId.slice(1).toLowerCase()
            : documentStatusId
        }`
      );
    }
  }, [documentStatusId]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setHideNodoc(true);
      setLoading(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("showdocumentStatus", response?.data?.data?.records);
      setStoreResponce(response);
      setTableEmpty(response.data.data.totalRecords);
      setdocumentStatusId(
        response?.data?.data?.totalRecords === 0 ? 0 : response?.data?.data?.records[0]?.status
      );
      const data = response?.data?.data?.records?.map((prop, key) => {
        const VnadorName = prop.vendor;
        var FiVendorName = "";
        if (VnadorName == undefined) {
          var FiVendorName = "-";
        } else {
          var FiVendorName = VnadorName.name ? VnadorName.name : "-";
        }
        if (prop.ocrStatus === "INPROGRESS" || prop.ocrStatus === "PENDING") {
          setReloadApi(reloadApi + 1);
        }
        if (prop.ocrStatus === "PENDING") {
          setReloadApi(reloadApi + 1);
        }

        if (prop.ocrStatus === "STARTING") {
          setReloadApi(reloadApi + 1);
        }

        return {
          vendor: (
            <MDBox>
              {prop?.isSynced && (
                <Tooltip cursor="pointer" title="Synced with quickbook" placement="top">
                  <VerifiedIcon style={{ color: prop?.ocrStatusDisplayTextColor }} />
                </Tooltip>
              )}
              {prop?.documentType == "STATEMENT" ? (
                <MDTypography
                  variant="caption"
                  fontWeight="bold"
                  fontSize="0.875rem"
                  color="info"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#007bff", // Change to blue on hover
                    },
                  }}
                  onClick={async (value) => {
                    setBusinessId(prop?.client?.clientId);
                    setInvoiceId(prop._id);
                    setOpenEditStatement(true);
                  }}
                >
                  {FiVendorName}
                </MDTypography>
              ) : (
                <MDTypography fontSize="0.875rem" ml={0.5}>
                  {FiVendorName}
                </MDTypography>
              )}
            </MDBox>
          ),
          invoice: (
            <MDBox>
              {prop?.documentType == "STATEMENT" ? (
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#007bff", // Change to blue on hover
                    },
                  }}
                  onClick={async (value) => {
                    setBusinessId(prop?.client?.clientId);
                    setInvoiceId(prop._id);
                    setOpenEditStatement(true);
                  }}
                >
                  {prop.invoice_number
                    ? prop.invoice_number
                    : prop.docNumber
                    ? prop.docNumber
                    : "-"}
                </MDTypography>
              ) : (
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#007bff", // Change to blue on hover
                    },
                  }}
                  onClick={async (value) => {
                    setInvoiceId(prop._id);
                    setOpenEditInvoice(true);
                  }}
                >
                  {prop.invoice_number
                    ? prop.invoice_number
                    : prop.docNumber
                    ? prop.docNumber
                    : "-"}
                </MDTypography>
              )}
            </MDBox>
          ),
          phone: prop.phone,
          uplaoddate: prop.createdAt,
          status: (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                style={{ color: prop?.ocrStatusDisplayTextColor }}
              >
                {prop?.ocrStatusDisplayText}
              </MDTypography>
            </MDBox>
          ),
          issue:
            prop.totalIssues >= 1 ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="error"
                  sx={{ cursor: "pointer" }}
                  onClick={async (value) => {
                    setLoading(true);
                    IssueListAPICall({
                      payload: [{ businessId: `${businessId}`, documentId: `${prop._id}` }],
                    });
                  }}
                >
                  {prop.totalIssues}
                </MDTypography>
              </MDBox>
            ) : (
              prop.totalIssues
            ),
          action:
            prop.status === "COMPLETED" ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setInvoiceId(prop._id);
                    setOpenEditInvoice(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Tooltip title={action.showtooltip} placement="top">
                    <Icon fontSize="small">visibility</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setInvoiceId(prop._id);
                    setErrorOpenEditInvoice(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Tooltip title={action.showtooltip} placement="top">
                    <Icon fontSize="small">visibility</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ),
          id: prop._id,
        };
      });
      setInvoiceList(data);
      setStartSearchLoader(false);
      setHideNodoc(true);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  const reloadApiAfterClose = () => {
    setOpenEditInvoice(false);
    setOpenEditStatement(false);
    removeQueryParams();
  };

  const dataTableData = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <a style={{ cursor: "pointer", color: "blue" }}>{value} </a>,
      },
      { Header: "Vendor", accessor: "vendor", Cell: ({ value }) => <DefaultCell value={value} /> },
      {
        Header: "Upload Date",
        accessor: "uplaoddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: InvoiceList,
  };

  const dataTableDataStatment = {
    columns: [
      { Header: "Vendor", accessor: "vendor", Cell: ({ value }) => <DefaultCell value={value} /> },
      {
        Header: "Upload Date",
        accessor: "uplaoddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: InvoiceList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const deleteInvoiceConfirmMessage = () => {
    setAlertDeleteInvoice(true);
  };

  const hideInvoiceAlert = () => {
    setAlertDeleteInvoice(false);
  };

  const successInoiceDelete = () => {
    DeleteInvoiceListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: SelectedRows,
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setLoading(true);
  };

  const handleDeleteInvoice = () => {
    deleteInvoiceConfirmMessage();
  };

  const hideMoveAlert = () => {
    setalertMoveInvoice(false);
  };

  const successInoiceMove = async () => {
    setDisableMoveButton(true);
    await MoveMenuListAPICall({
      payload: [
        {
          invoiceIds: {
            invoiceIds: SelectedRows,
            documentStatus: tabsMove,
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setLoading(true);
  };

  useEffect(() => {
    setSelectedInvoice(SelectedRows.length);
    if (Object.keys(storeResponce).length > 0) {
      storeResponce.data.data.records.map((prop, key) => {
        if (prop._id === SelectedRows[0]) {
          setStoreInvoiceNumber(prop.invoice_number ? prop.invoice_number : prop.docNumber);
        }
      });
    }
  }, [SelectedRows, storeResponce]);

  const hideIssueAlert = () => {
    setalertIssueInvoice(false);
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    return (
      <>
        {selectUseEffect > 0 ? (
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              }),
            }}
          >
            {canAccess((actions = "delete"), (resource = "invoices")) && selectUseEffect > 0 ? (
              <Tooltip title="Delete">
                <IconButton onClick={handleDeleteInvoice}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </Toolbar>
        ) : null}
      </>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function splitCamelCaseToString(s) {
    return s.split(/(?=[A-Z])/).join(" ");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }} sx={loaderClass}>
          <MDBox sx={{ my: 2 }} component="form" display="flex" justifyContent="space-between">
            <MDBox mb={0} display="flex">
              {showSelectBusiness ? (
                <FormControl variant="standard" sx={{ pl: 3, minWidth: 150 }} fullWidth>
                  <InputLabel
                    sx={darkMode ? { pl: 4, color: "white !important" } : { pl: 4 }}
                    htmlFor="demo-simple-select-standard"
                    id="demo-simple-select-standard-label"
                  >
                    Business
                  </InputLabel>
                  <Select
                    aria-required="true"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={showselectedrowsData}
                    onChange={handleChangeBusiness}
                    label="Select Business"
                    sx={
                      darkMode && {
                        color: "#ffffff !important",
                        "& .MuiSelect-icon": {
                          color: "#ffffff !important" /* Change arrow color */,
                        },
                        "& .MuiPopover-paper.MuiMenu-paper": {
                          backgroundColor: "#202940 !important" /* Change background color */,
                        },
                      }
                    }
                  >
                    {showrowsData.map((prop, key) => (
                      <MenuItem value={prop[1]}>{prop[1]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <MDTypography pt={3} px={2} sx={{ pr: 3, mb: 2 }} variant="h5" fontWeight="medium">
                  Documents
                </MDTypography>
              )}
              {canAccess((actions = "update"), (resource = "invoices")) && selectUseEffect > 0 ? (
                <MDBox>
                  <Tooltip title={action.Movetooltip}>
                    <IconButton
                      onClick={openMenu}
                      sx={{ ml: 2, mt: 1.5 }}
                      aria-controls={openMenu ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                    >
                      <DriveFileMoveIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted>
                    {tabRowsData.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => onMenuItemClick(event, index)}
                        selected={index === selected}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </MDBox>
              ) : null}
              <EnhancedTableToolbar numSelected={SelectedRows.length} />
              <div style={{ fontSize: "smaller", marginLeft: "25px", marginTop: "18px" }}>
                {startSearchLoader ? (
                  <MDBox sx={{ display: "flex" }}>
                    <img
                      width={23}
                      height={23}
                      style={{ marginBottom: "-2px", marginRight: "8px" }}
                      src={image}
                    />
                    <div style={{ color: darkMode ? "#ffffff" : "#7b809a", marginTop: "-2px" }}>
                      Fetching...
                    </div>
                  </MDBox>
                ) : (
                  <MDBox ml={4}></MDBox>
                )}
              </div>
            </MDBox>
            <MDBox sx={{ pl: 3 }} display="flex">
              {canAccess((actions = "update"), (resource = "invoices")) && selectUseEffect > 0 ? (
                <MDBox>
                  <Tooltip title={action.Movetooltip}>
                    <IconButton
                      onClick={openMenu}
                      sx={{ ml: 2, mt: 1.5 }}
                      aria-controls={openMenu ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                    >
                      <DriveFileMoveIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted>
                    {tabRowsData.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => onMenuItemClick(event, index)}
                        selected={index === selected}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </MDBox>
              ) : null}
              <EnhancedTableToolbar numSelected={SelectedRows.length} />
            </MDBox>
            <MDBox px={2} display="flex"></MDBox>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search Document"
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                  sx={{
                    "& input": { color: darkMode ? "#ffffff" : "#000000" },
                    "& input[type='search']::-webkit-search-cancel-button": {
                      appearance: "none", // Remove the default clear icon
                    },
                  }}
                />
              </MDBox>
              {canAccess((actions = "create"), (resource = "documents")) && (
                <MDBox>
                  <MDButton
                    variant="gradient"
                    color="info"
                    disabled={checksynced || loading}
                    onClick={() => setOpenInvoice(true)}
                  >
                    Upload Documents
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ mx: "10px", borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={
                  darkMode && {
                    padding: "0px !important",
                    backgroundColor: "#1a2035 !important",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                    "& .Mui-selected": {
                      borderRadius: "0px",
                      backgroundColor: "#006cbe !important",
                    },
                  }
                }
                className={darkMode ? classes.tabs : ""}
                aria-label="basic tabs example"
              >
                <Tab
                  className={darkMode ? classes.tabs : ""}
                  label={
                    <>
                      To Review{" "}
                      {hideLoaderForStart ? (
                        "(0)"
                      ) : CountResponse?.data?.isSuccess === true ? (
                        `(${countAskClient})`
                      ) : (
                        <>
                          (
                          <img
                            width={15}
                            height={15}
                            style={{ marginBottom: "0px" }}
                            src={image}
                            alt="Loading"
                          />
                          )
                        </>
                      )}
                    </>
                  }
                  value="AskClient"
                />
                <Tab
                  label={
                    <>
                      Reconciled{" "}
                      {hideLoaderForStart ? (
                        "(0)"
                      ) : CountResponse?.data?.isSuccess === true ? (
                        `(${counReconciled})`
                      ) : (
                        <>
                          (
                          <img
                            width={15}
                            height={15}
                            style={{ marginBottom: "0px" }}
                            src={image}
                            alt="Loading"
                          />
                          )
                        </>
                      )}
                    </>
                  }
                  value="Reconciled"
                />
                <Tab
                  label={
                    <>
                      All Documents{" "}
                      {hideLoaderForStart ? (
                        "(0)"
                      ) : CountResponse?.data?.isSuccess === true ? (
                        `(${countAll})`
                      ) : (
                        <>
                          (
                          <img
                            width={15}
                            height={15}
                            style={{ marginBottom: "0px" }}
                            src={image}
                            alt="Loading"
                          />
                          )
                        </>
                      )}
                    </>
                  }
                  value=""
                />
                <Tab label={``} {...a11yProps(0)} disabled />
                <Tab label={``} {...a11yProps(1)} disabled />
                <Tab label={``} {...a11yProps(3)} disabled />
                <Tab label={``} {...a11yProps(4)} disabled />
                <Tab label={``} {...a11yProps(5)} disabled />
                <Tab label={``} {...a11yProps(6)} disabled />
                <Tab label={``} {...a11yProps(7)} disabled />
                <Tab label={``} {...a11yProps(8)} disabled />
              </Tabs>
            </Box>
            <TabPanel>
              <MDBox>
                <MDBox display="flex" justifyContent="center">
                  {loading && <Loader />}
                </MDBox>
                <MDBox>
                  {tableEmpty == "0" ? (
                    <MDBox my={3} sx={{ mt: 1 }}>
                      <DataTable1
                        showCheck={false}
                        table={dataTableData}
                        entriesPerPage={false}
                        pagination={true}
                        showTotalEntries={true}
                        selectTab={selectTab}
                        response={tableEmpty}
                      />
                      <MDBox sx={{ top: "55%", position: "absolute", left: "45%", zIndex: "999" }}>
                        <MDTypography
                          variant="body2"
                          color="secondary"
                          sx={{ fontSize: "1.20rem !important", mt: 2 }}
                        >
                          {hideNodoc ? "No Document in this tab" : ""}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox my={3} sx={{ mt: 1, mb: -2 }}>
                      <DataTable1
                        table={selectTab !== "Reconciled" ? dataTableData : dataTableDataStatment}
                        entriesPerPage={true}
                        pagination={true}
                        showTotalEntries={true}
                        selectTab={selectTab}
                        response={tableEmpty}
                        showCheck={false}
                        ClientHeigth={true}
                        isClient={true}
                      />
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
              <MDBox>
                {loadingCircul && (
                  <CircularProgress
                    size={50}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </MDBox>
            </TabPanel>
          </Box>
        </Card>
      </MDBox>
      <MDBox>
        <MDBox>
          <Modal
            open={openSelectInvoice}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={darkMode ? darkModestyle : style}>
              <MDBox sx={{ pl: 0, pb: 5 }} display="flex" justifyContent="center">
                <FormControl variant="standard" sx={{ minWidth: 150, mt: 1.5 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    ListboxProps={{ style: { maxHeight: "15rem" }, position: "bottom-start" }}
                    options={autoCompleteObj}
                    sx={{
                      width: 300,
                    }}
                    onChange={(event, newValue) => {
                      setClientNewValue(newValue);
                      setClientEvent(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Business"
                        variant="standard"
                        sx={{
                          "& .MuiFormLabel-root": {
                            marginTop: "5px",
                          },
                          ...(darkMode && {
                            "& .MuiAutocomplete-clearIndicator": {
                              color: "#FFFFFF",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#FFFFFF",
                            },
                            "& .MuiInput-root .MuiInput-input": {
                              color: "#FFFFFF",
                              marginBottom: "-3px",
                            },
                          }),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    zIndex: 9999,
                    marginLeft: "-12px",
                  }}
                />
              )}
              <MDBox
                sx={{
                  justifyContent: "end",
                  display: "flex",
                }}
              >
                <MDButton onClick={handleCloseSelect}>Close</MDButton>
                <MDButton
                  variant="gradient"
                  sx={{ ml: 0.5 }}
                  color="info"
                  onClick={() => handleChangeBusiness(clientEvent, clientNewValue)}
                >
                  submit
                </MDButton>
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
          <Dialog
            fullWidth="true"
            maxWidth="sm"
            TransitionComponent={Transition}
            open={openNoInvoice}
          >
            <DialogTitle>{/* <h3>Select client</h3> */}</DialogTitle>
            <DialogContentText sx={{ px: 2 }}>
              Add a client to start managing their documnents with Paynthr.
            </DialogContentText>

            <DialogContent>
              <MDBox display="flex" justifyContent="space-evenly" sx={{ mt: 3 }}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  sx={{ ml: 12 }}
                  onClick={() => navigate("/accountant/client/new-client")}
                >
                  Add New Client
                </MDButton>
                <MDButton onClick={() => navigate(-1)}>Close</MDButton>
              </MDBox>
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <DraggableNewInvoiceDialog
            open={openInvoice}
            invoiceId={invoiceId}
            businessId={businessId}
            disButton={disButton}
            onClose={handleClose}
          />
        </MDBox>
        <MDBox>
          <Dialog
            fullScreen
            open={openEditInvoice}
            onClose={() => setOpenEditInvoice(false)}
            TransitionComponent={Transition}
          >
            <DialogContent
              sx={darkMode ? { backgroundColor: "#202940" } : { backgroundColor: "#ffffff" }}
            >
              <EditInvoiceDialog
                invoiceId={invoiceId}
                businessId={businessId}
                onClose={closeAfterMove}
                selectTab={selectTab}
                reloadApiAfterClose={reloadApiAfterClose}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            fullScreen
            open={openEditStatement}
            onClose={() => setOpenEditStatement(false)}
            TransitionComponent={Transition}
          >
            <DialogContent
              sx={
                darkMode
                  ? { padding: "10px 0px 0px 0px", backgroundColor: "#202940" }
                  : { padding: "10px 0px 0px 0px" }
              }
            >
              <EditStatementDialog
                invoiceIds={invoiceId}
                businessId={businessId}
                onClose={closeAfterMove}
                selectTab={selectTab}
                reloadApiAfterClose={reloadApiAfterClose}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            fullScreen
            open={openErrorEditInvoice}
            onClose={() => setErrorOpenEditInvoice(false)}
            TransitionComponent={Transition}
          >
            <Toolbar style={{ minHeight: "45px", backgroundColor: "white" }}>
              <Typography variant="h6" className={classes.title}></Typography>
              <Icon sx={{ cursor: "pointer" }} onClick={() => setErrorOpenEditInvoice(false)}>
                close
              </Icon>
            </Toolbar>
            <DialogContent>
              <ErrorEditInvoiceDialog invoiceId={invoiceId} businessId={businessId} />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content="Please Select Valid Invoice."
            open={showSnackbar}
            close={() => setShowSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <Dialog
            open={alertDeleteInvoice}
            onClose={hideInvoiceAlert}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",
                  ...(darkMode && {
                    backgroundColor: "#202940 !important",
                    color: "white !important",
                  }),
                },
              },
            }}
          >
            <DialogContent>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography variant="body2" color="secondary" sx={{ fontSize: 70 }}>
                  <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                </MDTypography>
              </DialogContentText>
              <DialogContentText
                sx={
                  darkMode
                    ? { color: "#ffffff", mt: 8, display: "flex", justifyContent: "center" }
                    : { mt: 8, display: "flex", justifyContent: "center" }
                }
              >
                {selectedInvoice > 1 ? (
                  <h3> Are you sure you want to delete {selectedInvoice} invoices?</h3>
                ) : StoreInvoiceNumber ? (
                  <h3> Are you sure you want to delete invoice {StoreInvoiceNumber}? </h3>
                ) : (
                  <h3> Are you sure you want to delete this invoice? </h3>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <MDButton variant="gradient" color="success" onClick={successInoiceDelete}>
                Yes, delete it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={hideInvoiceAlert}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={alertMoveInvoice}
            onClose={hideMoveAlert}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "510px",
                },
              },
            }}
          >
            <DialogContent>
              <DialogContentText sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
                {selectedInvoice > 1 ? (
                  <h3>Are you sure you want to move {selectedInvoice} invoices?</h3>
                ) : StoreInvoiceNumber ? (
                  <h3> Are you sure you want to move invoice {StoreInvoiceNumber}? </h3>
                ) : (
                  <h3> Are you sure you want to move this invoice? </h3>
                )}
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body1"
                  color="info"
                  sx={{ p: 2, justifyContent: "center", display: "flex", flexDirection: "column" }}
                >
                  {splitCamelCaseToString(selectTab.length > 0 ? selectTab : "All Documents")}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="#037ffd"
                  sx={{
                    fontSize: 40,
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                </MDTypography>
                <MDTypography
                  variant="body1"
                  color="info"
                  sx={{ p: 2, justifyContent: "center", display: "flex", flexDirection: "column" }}
                >
                  {splitCamelCaseToString(tabsMove)}
                </MDTypography>
              </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center" }}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={disableMoveButton}
                onClick={successInoiceMove}
              >
                Yes, Move it!
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                disabled={disableMoveButton}
                onClick={hideMoveAlert}
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={alertIssueInvoice}
            onClose={hideIssueAlert}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "50%",
                  maxWidth: "500px",
                },
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">{"Issue"}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Demo>
                    <List sx={{ mt: 4, mb: 2 }}>{generate()}</List>
                  </Demo>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={hideIssueAlert}>Close</MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="success"
            icon="done"
            title="Success"
            content="Document Move Successfully."
            open={showMoveSnackbar}
            close={() => setShowMoveSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="success"
            icon="done"
            title="Success"
            content="Document Deleted Successfully."
            open={showDeleteSnackbar}
            close={() => setShowDeleteSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content={"Invalid Document"}
            open={invalidDocumnet}
            close={() => setInvalidDocumnet(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content={moveErrorMessage}
            open={showErrorMoveSnackbar}
            close={() => setShowErrorMoveSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content={deleteErrorMessage}
            open={showErrorDeleteSnackbar}
            close={() => setShowErrorDeleteSnackbar(false)}
          />
        </MDBox>
        <MDBox>
          <MDSnackbar
            color={titleShow.toLowerCase()}
            icon={iconShow}
            title={titleShow}
            content={completemassage}
            open={showCompleteSnackbar}
            close={() => setShowCompleteSnackbar(false)}
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default UserHoc(AccountantInvoice);
