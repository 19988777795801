import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import NumberFieldText from "layouts/accountants/user/components/NumberField";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { EditItem, GetCategoryList, GetClassesList, GetCustomerList } from "../../../../services/clientservices";
import CurrencyFieldText from "../components/CurrencyField-Text";
import { addCategoryschema } from "./components/Itemschema/addCategoryschema";

function EditCategoryItemDialog({ noCategory, itemListResponse, invoiceId, editItemId, businessId, onClose, currencySymbol, showClasses}) {
  const [isDisable, setBtnDisable] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [unit, setunit] = useState("");
  const [sku, setSku] = useState("");
  const [itemItem, setItemitem] = useState("");
  const [quantity, setQuantity] = useState("");
  const [total, setTotal] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [adjustInvoice, setAdjustInvoice] = useState(true);
  const [classIdIds, setClassIdId] = useState("");
  const [customerIds, setCustomerIdId] = useState("");
  const [categoryIds, setCategoryIds] = useState("");
  const [classesList, setClassesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [catError, setCaterror] = useState(noCategory);
  const [className, setClassName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [askClients, setAskClients] = useState(false);
  const [categoryerrormsg, setCategoryerrormsg] = useState("");
  const [categoryerror, setCategoryerror] = useState(false);
  const [priceErrorMsg, setPriceErrorMsg] = useState("");
  const [quantityErrorMsg, setQuantityErrorMsg] = useState("");
  const [TotalPrice, setTotalPrice] = useState(0);
  const [noPrices, setNoPrices] = useState(false);
  const [showerrorqty, setShowerrorqty] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [noQuantity, setNoQuantity] = useState(false);
  const [errQuantity, seterrQuantity] = useState(noQuantity);
  const [errUnitPrice, seterrUnitPrice] = useState(false);
  const [quantityerror, setQuantityrror] = useState(false);
  const [errorButtonDis, setErrorButtonDis] = useState(false);
  const [priceerror, setpriceerror] = useState(false);

  const [{isError, isLoading, response }, setAPICall] = useAPICall(EditItem, { onLoad: false });
  const [{isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
  const [{isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
  const [{isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall ] = useAPICall(GetCategoryList, { onLoad: false });

  useEffect(async () => {
    setCaterror(noCategory);
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          customerId: prop._id,
        };
      });
      setCustomerList(data);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      setCategoryList(data);
    }
  }, [CategoryResponse]);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeEditItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    setLoading(true);
    const result = itemListResponse.data.data.records.map((prop, key) => {
      {
        if (editItemId === prop._id) {
          return {
            id: prop._id,
            item: prop.description,
            qty: prop.quantity,
            unit: prop.unitOfMeasure,
            price: prop.unitPrice,
            total: prop.total,
            sku: prop.sku,
            askClients: prop.askClient,
            category: prop.category ? prop.category.name : "",
            categoryId: prop.category ? prop.category._id : "",
            class: prop.class ? prop.class.name : "",
            classId: prop.class ? prop.class._id : "",
            customer: prop.customer ? prop.customer.name : "",
            customerId: prop.customer ? prop.customer._id : "",
          };
        } else {
          return {
            id: prop.id,
            item: prop.description,
            qty: prop.quantity,
            unit: prop.unitOfMeasure,
            price: prop.unitPrice,
            total: prop.total,
            sku: prop.sku,
            askClients: prop.askClient,
            category: prop.category ? prop.category.name : "",
            categoryId: prop.category ? prop.category._id : "",
            class: prop.class ? prop.class.name : "",
            classId: prop.class ? prop.class._id : "",
            customer: prop.customer ? prop.customer.name : "",
            customerId: prop.customer ? prop.customer._id : "",
          };
        }
      }
    });
    setTimeout(() => {
      const results = result.find((item) => item.id === editItemId);
      setItemitem(results?.item);
      setQuantity(results?.qty);
      setunit(results?.unit);
      setPrice(results?.price);
      setTotal(results?.total);
      setSku(results?.sku);
      setAskClients(results?.askClients);
      setCategoryName(results?.category);
      setCustomerName(results?.customer);
      setClassName(results?.class);
      setCategoryIds(results?.categoryId);
      setClassIdId(results?.classId);
      setCustomerIdId(results?.customerId);
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    let prices = typeof price == "string" ? price.replaceAll(",", "") : price;
    let quantitys = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    if (Number(prices) <= 0.0) {
      setErrorButtonDis(true);
    }
    if (showerror === true) {
      if (!prices) {
        setPriceErrorMsg("Price is required");
        setpriceerror(true);
      } else if (prices.length === 0 || Number(prices) === 0) {
        setPriceErrorMsg("Price Must be more than 0");
        setpriceerror(true);
      } else {
        setPriceErrorMsg("");
        setpriceerror(false);
      }
    }
    if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
      setErrorButtonDis(true);
    } else {
      setErrorButtonDis(false);
    }
    if (showerrorqty === true) {
      if (!quantitys) {
        setQuantityErrorMsg("Quantity is required");
        setQuantityrror(true);
      } else if (quantitys.length === 0 || Number(quantitys) === 0) {
        setQuantityErrorMsg("Quantity Must be more than 0");
        setQuantityrror(true);
      } else {
        setQuantityErrorMsg("");
        setQuantityrror(false);
      }
    }

    const timeOutId = setTimeout(
      async () => setTotalPrice(Number(prices) * Number(quantitys)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [price, quantity]);

  useEffect(() => {
    if (price) {
      if (price.length === 0 || (Number(price) === 0 && quantity.length === 0) || Number(quantity) === 0) {
        setErrorButtonDis(true);
      } else {
        setErrorButtonDis(false);
      }
    }
  }, []);

  const initialValues = {
    item: itemItem,
    sku: sku,
    customer: "",
    qty: quantity,
    unit: unit,
    total: total,
    price: price,
    category: "",
  };

  const handleSubmit = (values) => {
    if (price.length === 0 || Number(price) === 0) {
      setPriceErrorMsg("Price is required");
      setpriceerror(true);
    } else {
      setPriceErrorMsg("");
      setpriceerror(false);
    }

    if (quantity.length === 0 || Number(quantity) === 0) {
      setQuantityErrorMsg("Quantity is required");
      setQuantityrror(true);
    } else {
      setQuantityErrorMsg("");
      setQuantityrror(false);
    }

    if (categoryIds) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }
    setBtnDisable(true);
    setAPICall({
      payload: [
        {
          itemType: "AccountBasedExpenseLineDetail",
          amount: Number(TotalPrice),
          unitPrice: Number(price),
          quantity: Number(quantity),
          discount: 0,
          unitOfMeasure: values?.unit,
          description: values.item,
          InvoiceId: `${invoiceId}`,
          classId: classIdIds ? classIdIds : null,
          customerId: customerIds ? customerIds : null,
          categoryId: categoryIds ? categoryIds : null,
          ItemId: `${editItemId}`,
          askClient: askClients,
          adjustDocument: adjustInvoice,
        },
      ],
    });
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  const handleChangeCategory = (event, newValue) => {
    setCategoryName(newValue);
    setCaterror(false);
    if (newValue) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }
    const found = categoryList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      setCategoryIds(found.categoryId);
    }
  };

  const handleChangeClass = (event, newValue) => {
    setClassName(newValue);
    const found1 = classesList.find((obj) => {
      return obj.name === newValue;
    });
    if (found1) {
      setClassIdId(found1.classId);
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    setCustomerName(newValue);
    const found2 = customerList.find((obj) => {
      return obj.name === newValue;
    });
    if (found2) {
      setCustomerIdId(found2.customerId);
    }
  };

  return (
    <>
      {loading ? (
        <MDBox>
          <Grid sx={{ mt: 40 }}>
            <Grid item xs={12} lg={12}>
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox width="auto">
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={addCategoryschema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <MDBox p={2} pt={2}>
                    <MDBox>
                      <MDBox>
                        <Grid container spacing={3} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                },
                                ...(darkMode && {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                    "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                      color: "white !important",
                                    }
                                  }
                                })
                              }}
                              options={categoryList}
                              value={{ name: categoryName }}
                              getOptionLabel={(option) => option.name}
                              onInputChange={(event, newInputValue) => {
                                handleChangeCategory(event, newInputValue);
                              }}
                              InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                              id="controllable-states-demo"
                              disableCloseOnSelect={false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Category *"
                                  variant="standard"
                                  helperText={categoryerrormsg}
                                  error={catError || categoryerror}
                                  InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black",
                                },
                                ...(darkMode && {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                    "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                      color: "white !important",
                                    }
                                  }
                                })
                              }}
                              options={customerList}
                              value={{ name: customerName }}
                              getOptionLabel={(option) => option.name}
                              onInputChange={(event, newInputValue) => {
                                handleChangeCustomer(event, newInputValue);
                              }}
                              InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                              id="controllable-states-demo"
                              disableCloseOnSelect={false}
                              renderInput={(params) => (
                                <TextField {...params} label="Customer" variant="standard" InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}/>
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          {showClasses ? (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    ...(darkMode && {
                                      "& .MuiInput-underline": {
                                        borderBottom: "2px solid #ffffff",
                                      },
                                      "& .MuiInput-root .MuiInput-input": {
                                        WebkitTextFillColor: "white",
                                      },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                        "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                          color: "white !important",
                                        }
                                      }
                                    })
                                  }}
                                  options={classesList}
                                  value={{ name: className }}
                                  getOptionLabel={(option) => option.name}
                                  onInputChange={(event, newInputValue) => {
                                    handleChangeClass(event, newInputValue);
                                  }}
                                  InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                  id="controllable-states-demo"
                                  disableCloseOnSelect={false}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Class" variant="standard" InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}/>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} mt={-1}>
                                <FormField type="text" label="Description" name="item" />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} sm={12}>
                                <FormField type="text" label="Description" name="item" />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={6} mt={-0.5}>
                            <FormField type="text" label="Unit" name="unit" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Price *"
                              name="price"
                              value={price}
                              fullWidth
                              priceMismatch={errUnitPrice}
                              error={errUnitPrice || priceerror}
                              onValueChange={(event) => {
                                setPrice(event.value);
                                setShowerror(true);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {priceErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={6}>
                            <NumberFieldText
                              label="Quantity *"
                              name="qty"
                              priceMismatch={errQuantity}
                              error={errQuantity || quantityerror}
                              value={quantity}
                              fullWidth
                              onValueChange={(event) => {
                                setQuantity(event.value);
                                setShowerrorqty(true);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {quantityErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Total Price"
                              name="TotalPrice"
                              disabled
                              fullWidth
                              value={TotalPrice}
                              error={noPrices}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          {/* <MDBox>
                            <Field
                              as={FormControlLabel}
                              type="checkbox"
                              name="checkbox"
                              control={<Checkbox />}
                              label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Adjust Total Price</span>}
                              checked={adjustInvoice}
                              onChange={handleChange}
                              sx={{ mt: 1.5 }}
                            />
                          </MDBox> */}
                        </Grid>
                      </MDBox>
                      <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                        <MDButton
                          disabled={isDisable}
                          variant="gradient"
                          color="light"
                          onClick={onClose}
                        >
                          Cancel
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          <MDButton
                            disabled={isDisable || errorButtonDis}
                            type="submit"
                            variant="gradient"
                            color="info"
                          >
                            {!categoryIds ? "Submit" : isDisable ? "Submitting..." : "Submit"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </MDBox>
      )}
    </>
  );
}

export default EditCategoryItemDialog;