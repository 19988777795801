import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Importing the dot icon
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from "context";
import image from "layouts/accountants/clients/setting/output-onlinegiftools.gif";
import { useState } from 'react';
import Draggable from 'react-draggable';
import { eventBus } from "utils/eventbus";

const NotificationBox = ({ notifications}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isMinimized, setIsMinimized] = useState(false);
  const [notificationList, setNotificationList] = useState(notifications);
  const [syncPercent ,setSyncPercent] = useState(0);

  eventBus.$on("clientLiveLogResponse", (data) => clientLiveLogResponse(data));
  function clientLiveLogResponse(data) {
    setNotificationList(data);
  }

  eventBus.$on("clientDetailsDataResponse", (data) => clientDetailsDataResponse(data));
  function clientDetailsDataResponse(data) {
    setSyncPercent(data);
  }

  // Toggle minimize/maximize function
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  if (syncPercent == 100 || notificationList.length === 0) return null;

  return (
    <Draggable>
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 25,
        width: "500px",
        minWidth: "300px",
        maxHeight: isMinimized ? 60 : 250, // Minimized height
        bgcolor: darkMode ? 'grey.900' : 'background.paper', // Background color for dark/light mode
        color: darkMode ? 'grey.100' : 'text.primary', // Text color for dark/light mode
        boxShadow: 3,
        borderRadius: '10px 10px 0 0',
        overflow: 'hidden', // Prevents overflow in the main box
        p: 0, // Removes padding around the container
        zIndex: 99999,
        cursor: 'move', // Change cursor to indicate movability
      }}
    >
      {/* Header with minimize/expand button (sticky) */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2, // Padding inside the header
          bgcolor: darkMode ? 'grey.900' : 'background.paper', // Match background for dark/light mode
          borderBottom: '1px solid', // Add a separator between header and content
          borderColor: darkMode ? 'grey.700' : 'grey.300',
          position: 'sticky',
          top: 0,
          zIndex: 1, // Ensure header stays above the scrolling content
        }}
      >
        <MDBox
          sx={{
            width: "100%",
            display: "flex",
            // justifyContent: "end",
          }}
        >
          <div>
            <img
              width={25}
              height={25}
              style={{ marginBottom: "-6px", marginRight: "8px" }}
              src={image}
            />
          </div>
          <MDTypography sx={{ mr: 2 }} fontWeight={"bold"} color={"warning"}>
            Fetching data from Quickbooks({syncPercent}%)
          </MDTypography>
        </MDBox>
        <IconButton onClick={toggleMinimize} sx={{ color: darkMode ? '#ffffff' : 'text.primary' }}>
          {isMinimized ? (
            <ExpandMoreIcon
              sx={{ color: darkMode ? '#ffffff' : 'text.primary' }} // Change color for the expand icon
            />
          ) : (
            <ExpandLessIcon
              sx={{ color: darkMode ? '#ffffff' : 'text.primary' }} // Change color for the collapse icon
            />
          )}
        </IconButton>
      </Box>
  
      {/* Scrollable Notification List */}
      {!isMinimized && (
        <Box
          sx={{
            maxHeight: 190, // Height for the scrollable section (adjust as needed)
            overflowY: 'auto', // Enables scrolling for the notification list
            p: 2, // Padding for the list container
          }}
        >
          <List>
            {notificationList && notificationList.map((notification, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ListItemIcon sx={{ minWidth: '0px' }}>
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ color: darkMode ? '#ffffff' : 'text.primary' }} // Customize dot icon color
                  />
                </ListItemIcon>
                <ListItemText
                  primary={notification.message} // Display the message
                  primaryTypographyProps={{
                    margin: '10px',
                    fontSize: '0.95rem', // Smaller font size for notification text
                  }}
                  secondaryTypographyProps={{
                    fontSize: '0.8rem', // Smaller font size for createdAt text
                    color: darkMode ? 'grey.400' : 'text.secondary', // Color for createdAt text
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  </Draggable>  
  );
};

// Example usage of NotificationBox component
export default NotificationBox;
