import { Pagination, PaginationItem } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { eventBus } from 'utils/eventbus';
import DataTableBodyCell from "./Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "./Tables/DataTable/DataTableHeadCell";

function DataTable({entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder, response, selectTab, showCheckBoxs, showCheck, ClientHeigth}) {
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const [newpageIndex, setNewpageIndex] = React.useState(0);
    const [selectAllButton, setSelectAllButton] = useState(false);  
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    let pageOptions1 = [];
    let pageOptionsFirst = [];
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    useEffect(() => {
        const ThisOffsethandle = localStorage.getItem("offset1")
        if (newpageIndex === 0) {
            setNewpageIndex(0)
            localStorage.setItem("offset1", 0)
            localStorage.setItem("newpageIndex1", 0)
        } else {
            const num = ThisOffsethandle.toString();
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result))
        }
    }, [])

    eventBus.$on('showSelectTabs', (data) => showSelectTabs(data));
    function showSelectTabs(data) {
        setNewpageIndex(0);
    }
    const totalRecords = response
    for (let i = 0; i < totalRecords;) {
        i = i + 10
        pageOptionsFirst.push(i);
    }
    pageOptionsFirst.map((prop, key) => {
        const num = prop.toString();
        if (num >= 100) {
            const str = num.substring(0, num.length - 1);
            pageOptions1.push(str - 1)
        } else {
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            pageOptions1.push(result - 1)
        }
    })
    eventBus.$on('MoveMenuResponseComplete', (data) => MoveMenuResponseComplete(data));
    function MoveMenuResponseComplete(data) {
        const finaldata = (Number(data) - 10).toString()
        const str = finaldata.split('').reverse().join('')
        const result = str.replace(/^0+/, '');
        setNewpageIndex(Number(result));
    }

    eventBus.$on('showInvoiceStatus', (data) => showInvoiceStatus(data));
    function showInvoiceStatus(data) {
        const found1 = data?.find(obj => {
            return obj.ocrStatus === 'INPROGRESS';
        });
        if (found1) {
            setSelectAllButton(true);
        } else {
            setSelectAllButton(false);
        }
    }

    const tableInstance = useTable(
        { columns, data, selectTab, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const {
        getTableProps, getTableBodyProps, headerGroups,
        prepareRow, rows, page, pageOptions, canPreviousPage,
        canNextPage, gotoPage, nextPage, previousPage,
        setPageSize, setGlobalFilter, selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
        onPageChange = (eventBus.$emit('ChangePaginationPage', pageIndex)),
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    useEffect(() => {
        var tempId = [];
        tempId = selectedFlatRows.map(
            d => d.original.id
        )
        eventBus.$emit('invoiceRowSelected', { selectedRowIds: tempId });
    }, [selectedRowIds]);

    const [pages, setPages] = useState(1);
    const ThisOffsetuse = localStorage.getItem("offset1");
    const finalPage = ThisOffsetuse == 0 ? 1 : pages;

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions1.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    const handleChange = (e, p) => {
        setPages(p);
        eventBus.$emit('onclickchangePagesDilaog', Number(p - 1)) || setNewpageIndex(Number(p - 1))
    };

    return (
        <TableContainer sx={{ boxShadow: "none" ,backgroundColor: darkMode ? '#202940' : '#ffffff'   }} >
            <Table {...getTableProps()} >
                <MDBox component="thead" >
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? "auto" : "auto"}
                                    align={showCheckBoxs === false ? "left" : "center"}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}

                        </TableRow>
                    ))}
                </MDBox>
                <TableBody  {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow  {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <DataTableBodyCell
                                        showCheckBoxs={showCheckBoxs}
                                        ClientHeigth={ClientHeigth}
                                        noBorder={noEndBorder && rows.length - 1 === key}
                                        align={showCheckBoxs === false ? "left" : "center"}
                                        ml={-60}
                                        selectHeight={selectTab}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {
                response == 0 && <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "5%", marginBottom: "5%" }}
                >
                    No records found
                </MDTypography>
            }
                <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{
                    borderTop: "1px solid",
                    borderColor: darkMode ? "#2c3e50" : "#e0e0e0",
                    mt: 2,
                    pt: 2,
                    }}
                >
                <>
                    {response >= 1 && (
                        <MDTypography
                            variant="button"
                            color={darkMode ? "light" : "secondary"}
                            fontWeight="regular"
                            sx={{ mb: 1 }}
                        >
                            Showing {(pages - 1) === 0 ? "1" : `${(pages - 1)}1`} to {`${pages}0`} of{" "}
                            {response} entries
                        </MDTypography>
                    )}
                    {pageOptions1.length > 1 && (
                        <MDPagination
                            variant={pagination.variant ? pagination.variant : "gradient"}
                            color={pagination.color ? pagination.color : "info"}
                        >
                            {
                                <Pagination
                                    page={finalPage}
                                    color="primary" // Choose the color for the buttons (primary, secondary, default)
                                    sx={{ justifyContent: 'center', marginTop: 2 }} // Apply styles to the pagination container
                                    onChange={handleChange}
                                    count={pageOptionsFirst.length}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            {...item}
                                            sx={{
                                                color: '#237dea', // Set the text color
                                                '&.Mui-selected': {
                                                    backgroundColor: '#237dea', // Set the background color for the selected button
                                                    color: 'white', // Set the text color for the selected button
                                                },
                                            }}
                                            components={{
                                                next: () => <>Next</>,
                                                previous: () => <>Previous</>
                                            }}
                                        />
                                    )}
                                />
                            }
                        </MDPagination>
                    )}
                </>

            </MDBox>
        </TableContainer >
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: false,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;