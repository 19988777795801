import { CircularProgress, FormHelperText, InputAdornment, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { SITE_URL } from "helpers/config";
import Hoc from "hoc";
import FormField from "layouts/admin/accountants/components/FormField";
import { AddNewSchema } from "layouts/admin/accountants/components/schemas/addnewvalidations";
import initialValues from "layouts/admin/accountants/components/schemas/initialValues";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import { checkEmail, EditAccountantInfo} from "../../../../services/accountantservices";
import { AddNewAdminclient } from "../../../../services/adminservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";

function NewAccountant() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessagee, setErrorMessage] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [btnDisable2, setBtnDisable2] = useState(false);
  const [emailId, setemailId] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [startCheckLoader,setStartCheckLoader] = useState(false);
  const [userId, setUserId] = useState();
  const [styleMt, setStyleMt] = useState("0px");
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewAdminclient, { onLoad: false });
  const [{ isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse }, checkEmailAPICall ] = useAPICall(checkEmail, { onLoad: false });
  const [{ isLoading: isUpdateLoading, isError: isUpdateError, response: UpadateResponse }, UpdateAPICall ] = useAPICall(EditAccountantInfo, { onLoad: false });

  useEffect(() => {
    if (isUpdateLoading) {
      return () => null;
    }

    if (isUpdateError) {
      return () => null;
    }

    if (UpadateResponse && UpadateResponse.data.isSuccess === true) {
      navigate("/admin/accountants/list");
    }
  }, [UpadateResponse]);

  useEffect(() => {
    if (isCheckEmError) {
      setStartCheckLoader(false);
      return () => null;
    }
    if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
      setEmailStatus(checkEmResponse.data.data.status);
      setUserId(checkEmResponse.data.data.userId);
      if (checkEmResponse.data.data.status === "ARCHIVED") {
        setStyleMt("6px");
        setErrorMessages("This user is deleted you want to restore it? Click here");
      } else if (checkEmResponse.data.data.status === "ACTIVE") {
        setStyleMt("6px");
        setErrorMessages("This Email already has a Business");
      } else if (checkEmResponse.data.data.status === "BLOCKED") {
        setStyleMt("6px");
        setErrorMessages("This user is blocked to unblock it - Click here");
      } else {
        setStyleMt("0px");
        setErrorMessages("");
        setBtnDisable(false);
      }
      setStartCheckLoader(false);
    }
  }, [checkEmResponse]);

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      if (emailId.length !== 0) {
        setBtnDisable(true);
        setStartCheckLoader(true);
        await checkEmailAPICall({
          payload: [
            {
              email: `${emailId}`,
              checkBy: "email",
            },
          ],
        });
      }else{
        setStartCheckLoader(false);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  }, [emailId]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      setBtnDisable1(false);
      setBtnDisable2(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 5000);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);

      navigate("/admin/client/list");
    }
  }, [response]);

  const handleSubmit = async (values) => {
    setBtnDisable1(true);
    setBtnDisable2(true);
    setBtnDisable(true);
    await setAPICall({
      payload: [
        {
          email: `${emailId}`,
          businessName: `${values.businessname}`,
          subscriptionType: "inventory",
          phone: `${values.phone}`,
          fname: `${values.firstname}`,
          lname: `${values.lastname}`,
          returnLink: `${SITE_URL}/create-password`,
        },
      ],
    });
  };
  let emails = "";
  const handleChange = (e) => {
    setBtnDisable(true);
    if(e?.target?.value.length == 0){
      setStartCheckLoader(false);
    }
    setemailId(e.target.value);
    emails = e.target.value;
    setErrorMessages("");
  };

  const validate = (value) => {
    const finalEmail = emails ? emails : emailId.slice(0, -1);
    let errorMessage;
    if (!finalEmail) {
      errorMessage = "Email address is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(finalEmail)) {
      errorMessage = "Your email address is invalid";
    } else {
      errorMessage = "";
    }

    return errorMessage;
  };
  const handleRestoreNone = async () => {
    console.log("none");
  };

  const handleRestore = async () => {
    if (emailStatus === "ARCHIVED") {
      await UpdateAPICall({
        payload: [
          {
            bussinessId: `${userId}`,
            isArchived: false,
          },
        ],
      });
    } else if (emailStatus === "BLOCKED") {
      await UpdateAPICall({
        payload: [
          {
            bussinessId: `${userId}`,
            isBlocked: false,
          },
        ],
      });
    } else {
      navigate("/admin/accountants/list");
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={AddNewSchema}
            onSubmit={handleSubmit}
          >
            {({values, errors, touched, isSubmitting, setFieldValue, setFieldTouched, handleBlur }) => (
              <Form autoComplete="off">
                <Card sx={{ height: "100%", background: darkMode ? "#202940" : "#ffffff" }}>
                  <MDBox p={3}>
                    <MDBox>
                      <MDBox lineHeight={0}>
                        <MDTypography variant="h5">Add New Client</MDTypography>
                      </MDBox>
                      <MDBox mt={1.625}>
                        <Grid item xs={12} mb={1}>
                          <FormField
                            type="text"
                            label="Client Business Name *"
                            name="businessname"
                          />
                        </Grid>
                        <MDBox lineHeight={0} mb={1} mt={1}>
                          <MDTypography variant="h6" textTransform="capitalize">
                            Client Info
                          </MDTypography>
                        </MDBox>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField type="text" label="First Name *" name="firstname" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField type="text" label="Last Name *" name="lastname" />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="email"
                            validate={validate}
                          >
                            {({ field }) => (
                              <TextField
                                {...field}
                                label="Email *"
                                variant="standard"
                                fullWidth
                                value={emailId}
                                InputProps={{
                                  style: { borderBottom: darkMode && "1px solid", color: darkMode ? "#ffffff" : "#000" },
                                  endAdornment: startCheckLoader ? (
                                    <InputAdornment position="end">
                                      <CircularProgress size={20} />
                                    </InputAdornment>
                                  ) : null,
                                }}
                                InputLabelProps={{
                                  style: { color: darkMode ? "#ffffff" : "#000000" },
                                }}
                                helperText={touched.email && errors.email ? (
                                  <FormHelperText sx={{ color: 'red' }}>
                                    {errors.email}
                                  </FormHelperText>
                                ) : null}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleBlur(e);
                                  setStartCheckLoader(true);
                                }}
                              />
                            )}
                          </Field>
                          <MDBox>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {
                                <div
                                  style={{
                                    color: "#fb8c00",
                                    cursor: "pointer",
                                    marginTop: styleMt,
                                  }}
                                  onClick={emailStatus != "ACTIVE" ? handleRestore : handleRestoreNone}
                                >
                                  {errorMessages}
                                </div>
                              }
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                          <NumberFormat
                            customInput={TextField}
                            variant="standard"
                            type="tel"
                            name="phone"
                            fullWidth
                            value={values.phone}
                            label="Phone Number *"
                            InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                            InputProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: darkMode ? "white" : "black",
                              },
                              mb: 1, mt: -1, fontSize: "0.823rem !important",
                            }}
                            format="(###) ###-####"
                            mask=""
                            onValueChange={(value) => setFieldValue("phone", value.formattedValue)}
                          />
                        </Grid>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          <ErrorMessage name="phone" />
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                        <MDButton
                          disabled={btnDisable2}
                          variant="gradient"
                          color="light"
                          component={Link}
                          to="/admin/client/list"
                        >
                          Cancel
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          <MDButton
                            disabled={btnDisable}
                            type="submit"
                            variant="gradient"
                            color="info"
                          >
                            {btnDisable1 ? "Creating..." : "Create"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDSnackbar
                      color="success"
                      icon="done"
                      title="Success"
                      content="Accountant Created Successfully!"
                      open={showSnackbar}
                      close={() => setShowSnackbar(false)}
                    />
                    <MDSnackbar
                      color="error"
                      icon="warning"
                      title="Failed!"
                      content={ErrorMessagee}
                      open={showErrorSnackbar}
                      close={() => setShowErrorSnackbar(false)}
                    />
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
        {/* </Grid> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Hoc(NewAccountant);