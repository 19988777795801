import { Button, Card, Divider, Grid, Icon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import logo from "assets/images/logos/paynthr-logo-colored.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import UserHoc from "hoc/userHoc";
import LoaderProfile from "layouts/accountants/user/components/imgLoader/LoaderProfile";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import DataTableLog from "layouts/admin/components/Tables/DataTable/index.js";
import { useEffect, useState } from "react";
import { GetCardDetails, GetClientLogsList, GetClientUserBilling, GetPaymentagreement, GetTransactionsList, GetUserProfile } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import CardImg from "./CardImg";
import SetupForm from "./SetupForm.js";
import DataTable from "./Tables/DataTable/index";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function ClientUserBilling() {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showClientLogs, setShowClientLogs] = useState(false);
  const [transactionsList ,setTransactionsList] = useState([]);
  const [showTransactionsList ,setShowTransactionsList] = useState(false);
  const [openNoTransaction ,setOpenNoTransaction]= useState(false);
  const [showBillingTable, setShowBillingTable] = useState(false);
  const [lastDigit, setLastDigit] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [expmonth, setExpmonth] = useState("");
  const [expyear, setExpyear] = useState("");
  const [billingList, setBillingList] = useState([]);
  const [invoiceLogId, setInvoiceLogId] = useState("");
  const [clientLogsList, setClientLogsList] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [newCardText, setNewCardText] = useState(false);
  const [nextBillAmount, setNextBillAmount] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [itemType, setitemType] = useState("");
  const [itemName, setItemName] = useState("Client Name");
  const [showBilling, setShowBilling] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetPaymentagreement, { onLoad: false});
  const [{ isLoading: isEditCardLoading, isError: isEditCardError, response: EditCardResponse },EditCardUserAPICall] = useAPICall(GetPaymentagreement, { onLoad: false });

  useEffect(() => {
    if (isEditCardLoading) {
      return () => null;
    }
    if (isEditCardError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (EditCardResponse && EditCardResponse.data.isSuccess === true) {
      setClientSecret(EditCardResponse?.data?.data?.clientSecret);
      setTimeout(() => {
        setShowPaymentForm(true);
        setLoadingCircul(false);
      }, 1000);
    }
  }, [EditCardResponse]);

  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetCardDetails, { onLoad: false });
  const [{ isError: isBillingError, isLoading: isBillingLoading, response: Billingresponse }, ClientUserBillingAPICall] = useAPICall(GetClientUserBilling, { onLoad: false });
  const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserDteailsAPICall] = useAPICall(GetUserProfile, { onLoad: false });
  const [{ isLoading: isGetClientLogsLoading, isError: isGetClientLogsError, response: userLogsDataResponse,}, GetClientLogsAPICall] = useAPICall(GetClientLogsList, { onLoad: false });

  const appearance = {
    theme: "stripe",
  };

  const Modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
    bgcolor: darkMode ? '#202940' : "background.paper",
  };

  const [{ isLoading: isGetTransactionsLoading, isError: isGetTransactionsError, response: transactionsDataResponse}, GetTransactionsAPICall ] = useAPICall(GetTransactionsList, { onLoad: false });

  useEffect(() => {
    if (isGetTransactionsLoading) {
      return () => null;
    }

    if (isGetTransactionsError) {
      setErrorMessage(transactionsDataResponse?.data?.message);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setLoadingCircul(false);
      return () => null;
    }

    if (transactionsDataResponse && transactionsDataResponse.data.isSuccess === true) {
      const data = transactionsDataResponse?.data?.data?.records.map((prop, key) => {
        return {
          title: prop?.title ? prop?.title : '-',
          amount: prop?.amount ? prop?.amount : "0",
          currency: prop?.currency ? prop?.currency : "-",
          status : prop?.status  ? 
          prop?.status == "FAILED" ? 
          <MDTypography
            variant="caption"
            fontWeight="regular"
            fontSize="0.875rem"
            color="error"
          >
            {prop?.status}
          </MDTypography> : 
          <MDTypography
          variant="caption"
          fontWeight="regular"
          fontSize="0.875rem"
          color="success"
        >
          {prop?.status}
        </MDTypography>
        : "-",
          message: prop?.message ? prop?.message : "-",
        };
      });
      setTransactionsList(data);
      if( transactionsDataResponse?.data?.data?.totalRecords == 0){
        setOpenNoTransaction(true);
      }else{
        setShowTransactionsList(true);
      }
      
      setLoadingCircul(false);
    }
  }, [transactionsDataResponse]);


  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
  }, [loading]);

  useEffect(() => {
    if (isGetClientLogsLoading) {
      return () => null;
    }

    if (isGetClientLogsError) {
      setErrorMessage(userLogsDataResponse?.data?.message);
      setLoadingLogs(false);
      setShowClientLogs(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setLoading(false);
      return () => null;
    }

    if (userLogsDataResponse && userLogsDataResponse.data.isSuccess === true) {
      const data = userLogsDataResponse?.data?.data?.records.map((prop, key) => {
        setitemType(prop?.item?.name);
        setItemName(prop?.item?.itemType === "restaurant" ? "Restaurant Name" : "Client Name");
        return {
          itemname: prop?.item?.name ? prop?.item?.name : "-",
          startdate: prop?.billingPeriodStartDate ? prop?.billingPeriodStartDate : "-",
          enddate: prop?.billingPeriodEndDate ? prop?.billingPeriodEndDate : "-",
          totaldays: prop?.billableDays ? prop?.billableDays : "-",
          amount: prop?.amount ? `$${prop?.amount}` : "-",
        };
      });
      setLoadingLogs(false);
      setClientLogsList(data);
    }
  }, [userLogsDataResponse]);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      setLoading(false);
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      const BillingDetails = userDetailsDataResponse?.data?.data?.user;
      setNextBillAmount(BillingDetails?.subscriptions[0]?.nextBillAmount ? BillingDetails?.subscriptions[0]?.nextBillAmount : BillingDetails?.nextBillAmount);
      setNextBillingDate(BillingDetails?.subscriptions[0]?.nextBillingDate ? BillingDetails?.subscriptions[0]?.nextBillingDate : BillingDetails?.nextBillingDate);
      setLoading(false);
    }
  }, [userDetailsDataResponse]);

  useEffect(() => {
    if (isBillingLoading) {
      return () => null;
    }
    if (isBillingError) {
      setLoading(false);
      return () => null;
    }
    if (Billingresponse && Billingresponse.data.isSuccess === true) {
      const data = Billingresponse?.data?.data?.records.map((prop, key) => {
        return {
          invoice: prop?.invoiceId ? prop?.invoiceId : "-",
          billingdate: prop?.periodStartDate ? prop?.periodStartDate : "-",
          amount: prop?.total ? `$${prop?.total}` : "-",
          restaurant: prop?.invoicedItems >= 1 ? (
              <>
                {setShowBillingTable(true)}
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="info"
                    sx={{ cursor: "pointer" }}
                    onClick={async (value) => {
                      setLoadingLogs(true);
                      setShowClientLogs(true);
                      setInvoiceLogId(prop?.invoiceId);
                      GetClientLogsAPICall({
                        payload: [{ ClientId: `${prop._id}` }],
                      });
                    }}
                  >
                    {prop?.invoicedItems}
                  </MDTypography>
                </MDBox>
              </>
            ) : (prop?.invoicedItems),
          paymentStatus: prop?.paymentStatus ? prop?.paymentStatus : "-",
          clientsShow: prop?.invoicedItems,
          transactions:(<MDTypography
            variant="body2"
            color="secondary"
            sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
          >
            <Tooltip
              title={'Transaction'}
              placement="top"
              onClick={(value) => {
                setLoadingCircul(true);
                GetTransactionsAPICall({
                  payload: [{ invoiceId: `${prop._id}` }],
                })
              }}
            >
              <Icon sx={darkMode ? { color: "#ffffff" } : {}}>receipt</Icon>
            </Tooltip>
          </MDTypography>)
        };
      });
      setBillingList(data);
      setLoading(false);
      setShowBilling(true);
    }
  }, [Billingresponse]);

  useEffect(async () => {
    ClientUserBillingAPICall({
      payload: [],
    });
    await DetailsUserAPICall({
      payload: [],
    });
    getUserDteailsAPICall({
      payload: [],
    });
  }, []);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setClientSecret(response?.data?.data?.clientSecret);
      setTimeout(() => {
        setOpenForm(true);
        setShowPaymentForm(true);
        setLoadingCircul(false);
      }, 1000);
    }
  }, [response]);

  useEffect(() => {
    openForm === true && response?.data?.isSuccess === true ? setLoading(false) : setOpenForm(false);
  }, [response, openForm, showPaymentForm]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setLastDigit(DetailsResponse?.data?.data?.card?.lastFourDigit);
      setCardBrand(DetailsResponse?.data?.data?.card?.brand);
      setExpyear(DetailsResponse?.data?.data?.card?.exp_year);
      setExpmonth(DetailsResponse?.data?.data?.card?.exp_month);
      setCardHolderName(DetailsResponse?.data?.data?.card?.cardHolderName ? DetailsResponse?.data?.data?.card?.cardHolderName : "---");
    }
  }, [DetailsResponse]);

  const options = {
    // passing the SetupIntent's client secret
    clientSecret: `${clientSecret}`,
    // Fully customizable with appearance API.
    appearance: appearance,
  };

  const handleAddCard = () => {
    setLoadingCircul(true);
    setAPICall({
      payload: [],
    });
  };
  const closeLogs = () => {
    setShowClientLogs(false);
    setClientLogsList([]);
  };

  eventBus.$on("OnSubmitClick", (data) => OnSubmitClick(data));
  function OnSubmitClick(data) {
    setShowPaymentForm(false);
    setOpenForm(false);
    setNewCardText(false);
  }

  eventBus.$on("EditPaymentForm", (data) => EditPaymentForm(data));
  function EditPaymentForm(data) {
    setNewCardText(true);
    setLoadingCircul(true);
    EditCardUserAPICall({
      payload: [],
    });
  }

  const BillingTableData = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Billing Date",
        accessor: "billingdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Restaurant",
        accessor: "restaurant",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Transaction",
        accessor: "transactions",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: billingList,
  };

  const BillingTableData1 = {
    columns: [
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Billing Date",
        accessor: "billingdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Restaurant",
        accessor: "restaurant",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [],
  };

  const dataTableTransactionData = {
    columns: [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Currency",
        accessor: "currency",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Message",
        accessor: "message",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: transactionsList,
  };
  
  const dataTableLogsData = {
    columns: [
      {
        Header: itemName,
        accessor: "itemname",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "End Date",
        accessor: "enddate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Total Days",
        accessor: "totaldays",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: clientLogsList,
  };

  return (
    <>
      {!showBilling ? (
        <MDBox display="flex" justifyContent="center">
          <LoaderProfile />
        </MDBox>
      ) : (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox>
            <MDBox mx={4}>
              <MDBox lineHeight={0}>
                <MDTypography variant="h5"> Billing</MDTypography>
              </MDBox>
              <MDBox my={3}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={3}>
                          <DefaultInfoCard
                            icon="payment"
                            title="Next payment"
                            description={
                              nextBillingDate == null ? `on  -` : `on ${nextBillingDate}`
                            }
                            value={nextBillAmount ? `$${nextBillAmount}` : `$0`}
                          />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                          {userDetailsDataResponse?.data?.data?.user?.card === null ? (
                            <MDBox>
                              <MDBox
                                component="ul"
                                display="flex"
                                flexDirection="column"
                                p={0}
                                m={0}
                              ></MDBox>
                              <Card
                                style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}
                                sx={{
                                  border: 1,
                                  borderColor: "#7239ea",
                                  borderStyle: "dashed",
                                  width: `360px`,
                                  height: `224px`,
                                }}
                              >
                                <MDBox
                                  component="li"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  // bgColor={"#f8f5ff"}
                                  borderRadius="lg"
                                  p={2}
                                  mb={1}
                                  mt={1}
                                >
                                  <MDBox width="100%" display="flex" flexDirection="column">
                                    <MDBox
                                      display="flex"
                                      justifyContent="center"
                                      alignItems={{ xs: "flex-start", sm: "center" }}
                                      flexDirection={{ xs: "column", sm: "row" }}
                                      mt={3}
                                    >
                                      <MDTypography
                                        variant="h6"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                      >
                                        No Card Added Yet!
                                      </MDTypography>
                                    </MDBox>
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      size="small"
                                      sx={{
                                        // '@media (max-width: 1920px)': {
                                        //     mt: '15%', width: '37%', ml: '34%', mb: '12%'
                                        // },
                                        // '@media (max-width: 1440px)': {
                                        //     mt: '13%', width: '35%', ml: '34%', mb: '10%'
                                        // },
                                        mt: "24%",
                                        width: "45%",
                                        ml: "28%",
                                      }}
                                      onClick={handleAddCard}
                                    >
                                      &nbsp;Add New Card
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </MDBox>
                          ) : (
                            <CardImg
                              number={`************${lastDigit}`}
                              holder={cardHolderName}
                              expires={`${expmonth}/${expyear}`}
                              cardBrand={cardBrand}
                              editCard={true}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                  <Card
                    style={{
                      height: 'calc(100vh - 400px)', // Dynamic height based on viewport
                      maxHeight: '600px', // Maximum height
                      overflowY: 'auto',
                      backgroundColor: darkMode ? "#202940" : "#ffffff",
                      margin: '0 auto', // Center the card
                    }}
                    sx={{
                      '@media (min-width: 1440px)': {
                        height: 'calc(100vh - 100px)', // Adjust for larger screens
                      },
                      '@media (max-height: 800px)': {
                        height: 'calc(100vh - 200px)', // Adjust for shorter screens
                      },
                    }}
                  >
                    <MDBox pt={3} px={2}
                      sx={{pr: 3,mb: 2, mt: -1}}
                      component="form"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Billing History
                      </MDTypography>
                      <MDBox px={2} display="flex">
                        <MDBox sx={{ pr: 3, mb: 2 }}>
                          {/* <MDInput type="search" label="Search" variant="standard" /> */}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    {BillingTableData?.rows.length === 0 ? (
                      <MDBox my={2} mt={-0.5}>
                        <DataTable
                          table={BillingTableData1}
                          entriesPerPage={false}
                          pagination={false}
                          showTotalEntries={true}
                        />
                        <MDTypography
                          variant="body2"
                          color="secondary"
                          sx={{
                            color: darkMode ? "#ffffff" : "#000000",
                            mb: 5,
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                            fontSize: "1.20rem",
                          }}
                        >
                          No billing history found!
                        </MDTypography>
                      </MDBox>
                    ) : (
                      <MDBox my={2} mt={-0.5}>
                        <DataTable
                          table={BillingTableData}
                          entriesPerPage={false}
                          pagination={true}
                          showTotalEntries={true}
                          showCheckBoxs={false}
                        />
                      </MDBox>
                    )}
                  </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <Modal
            open={openNoTransaction}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0 }}
                  onClick={() => setOpenNoTransaction(false)}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{color: darkMode ? '#ffffff':'#7b809a', mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                No Transaction History
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{color: darkMode ? '#ffffff':'#7b809a', mt: 4, ml: 1 }}>
              This invoice currently lacks any transaction history. Ensure all payments and adjustments are properly logged. Contact support if needed. Keeping records updated ensures accurate financial tracking.
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
              
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 4 }}>
               
              </MDBox>
              <MDBox display="flex" justifyContent="center">
              </MDBox>
            </Box>
          </Modal>
          <MDBox>
              <Dialog
                open={showTransactionsList}
                onClose={() => setShowTransactionsList(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "auto",
                      maxWidth: "1000px",
                      backgroundColor: darkMode ? "#202940 !important" : '#ffffff',
                    },
                  },
                }}
              >
                <DialogTitle sx={darkMode && {color:'#ffffff'}} >Transactions</DialogTitle>
                <Divider sx={{ mt: -1, mb: -2 }} />
                <DialogContent sx={{ mb: 2 }}>
                  <MDBox my={5} sx={{ mt: 2 }}>
                    <DataTableLog
                      table={dataTableTransactionData}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={false}
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowTransactionsList(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={showPaymentForm}
                onClose={() => setShowPaymentForm(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "auto",
                      maxWidth: "600px",
                      minHeight: "300px",
                      backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                      color: darkMode ? '#ffffff':'#7b809a',
                    },
                  },
                }}
              >
                 <DialogTitle sx={darkMode ? {color:'#ffffff'}:{color:'#344767'}}>
                  {newCardText == true ? "Edit Card Details" : "Add New Card"}
                </DialogTitle>
                <Divider sx={{ mt: -1 }} />
                <DialogContent>
                  <Elements stripe={stripePromise} options={options}>
                    <SetupForm Secret={clientSecret} />
                  </Elements>
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
            <MDBox>
              <Dialog
                open={showClientLogs}
                onClose={closeLogs}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "auto",
                      maxWidth: "1000px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    },
                  },
                }}
              >
                <DialogTitle sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>
                  Invoice #{invoiceLogId}: {itemType}
                </DialogTitle>
                <Divider sx={{ mt: -1, mb: -2 }} />
                <DialogContent sx={{ mb: 2 }}>
                  {loadingLogs && (
                    <MDBox display="flex" justifyContent="center">
                      <CircularProgress
                        size={50}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    </MDBox>
                  )}
                  <MDBox my={5} sx={{ mt: 2 }}>
                    <DataTableLog
                      table={dataTableLogsData}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeLogs}>Close</Button>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed."
                content={errorMessage}
                // autoHideDuration={3000}
                open={showErrorSnackbar}
                close={() => setShowErrorSnackbar(false)}
              />
            </MDBox>
          </MDBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default UserHoc(ClientUserBilling);
