import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Form, Formik } from "formik";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import { AddNewItem, GetCategoryList, GetClassesList, GetCustomerList, GetProductsList } from "services/clientservices";
import { eventBus } from 'utils/eventbus';
import { useAPICall } from "utils/useapicall";

function AddCurrontInvetory({ invoiceId, documentId, onCloseAdd, currencySymbol, businessId, itemList, showClasses }) {
    const [invoiceIds, setInvoiceIds] = useState(invoiceId);
    const [isDisable, setBtnDisable] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [description, setDescription] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [classesList, setClassesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [price, setPrice] = useState(0);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [classIdIds, setClassIdId] = useState('');
    const [customerIds, setCustomerIdId] = useState('');
    const [categoryIds, setCategoryIds] = useState('');
    const [adjustInvoice, setAdjustInvoice] = useState(false);
    const [addinventory, setAddinventory] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
    const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });

    useEffect(() => {
        let prices = (typeof (price) == 'string') ? price.replaceAll(',', '') : price
        let quantitys = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity

        const timeOutId = setTimeout(async () =>
            setTotalPrice(Number(prices) * Number(quantitys)),
            500);
        return () => clearTimeout(timeOutId);
    }, [price, quantity]);

    useEffect(async () => {
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, []);

    const initialValues = {
        product: "",
        checked: "",
        item: description,
        sku: "",
        price: unitPrice,
        total: "",
        qty: "",
        services: "",
        class: "",
        customer: "",
        unit: "",
        category: ""
    };

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("closeAddNewItemPopup", response);
            setBtnDisable(false);
        }
    }, [response]);

    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            const data = CustomerResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    customerId: prop._id
                };
            }
            );
            setCustomerList(data);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (isClassesLoading) {
            return () => null;
        }
        if (isClassesError) {
            return () => null;
        }
        if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
            const data = ClassesResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    classId: prop._id
                };
            }
            );
            setClassesList(data);
        }
    }, [ClassesResponse]);

    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryList(data);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            const data = ProductsResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    productId: prop._id,
                    accountingId: prop.accountingId,
                    description: prop.description,
                    unitPrice: prop.unitPrice,
                };
            }
            );
            setProductsList(data);
        }
    }, [ProductsResponse]);

    const handleSubmit = async (values) => {
        if (categoryIds) {
            setBtnDisable(true);
        }

        const finalprice = (typeof (price) == 'string') ? price.replaceAll(',', '') : price;
        const finalTotalPrice = (typeof (TotalPrice) == 'string') ? TotalPrice.replaceAll(',', '') : TotalPrice;
        const finalquantity = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity;

        await setAPICall({
            payload: [
                {
                    "itemType": 'AccountBasedExpenseLineDetail',
                    "unitOfMeasure": values.unit,
                    "discount": 0,
                    "description": values.item,
                    "documentId": `${invoiceIds}`,
                    "classId": classIdIds,
                    "customerId": customerIds,
                    "categoryId": categoryIds,
                    "adjustDocument": adjustInvoice,
                    "unitPrice": Number(finalprice),
                    "amount": Number(finalTotalPrice),
                    "quantity": Number(finalquantity),
                }
            ]
        })
    };

    return (
        <>
            <MDBox width='auto'>
                <Grid item xs={12} lg={12}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                                <MDBox p={2} pt={2}>
                                    <MDBox >
                                        <Grid container spacing={3} sx={{ mb: 1 }}>
                                            <Grid item xs={12} sm={12}>
                                                <FormField
                                                    type="text"
                                                    label="Quantity"
                                                    name="quantity"
                                                />
                                            </Grid>
                                        </Grid>
                                        <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                                            <MDButton
                                                type="submit"
                                                variant="gradient"
                                                color="light"
                                                onClick={onCloseAdd}
                                            >
                                                Cancel
                                            </MDButton>
                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                <MDButton
                                                    disabled={isDisable}
                                                    variant="gradient"
                                                    color="info"
                                                    onClick={() => setAddinventory(true)}
                                                >
                                                    Submit
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </MDBox>
        </>
    );
}

export default AddCurrontInvetory;