import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import Hoc from "hoc";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import SubscriptionAmount from "./subscriptionAmount";
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    tabs: {
      "& .MuiTab-root": {
        backgroundColor: "#202940 !important",
        color: "#ffffff !important",
        marginLeft: "3px",
        marginRight: "2px",
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#202940 !important",
        backgroundColor: "#ffffff !important",
      },
      "& .css-1t3gkzj-MuiButtonBase-root-MuiTab-root.Mui-disabled": {
        backgroundColor: "transparent !important",
        color: "#7b809a !important",
      },
    }
}));
  

function Settings() {
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const pathnames = window.location.pathname;
    const pathname = pathnames.substr(0, pathnames.lastIndexOf("/"));
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [successMassageText, setSuccessMassageText] = useState("");
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const subNav = ["Billing"];

    const handleChange = async (event, newValue) => {
        setValue(newValue);
      };

    eventBus.$on("editProductResponse", (data) => editProductResponse(data));
    async function editProductResponse(data) {
        setSuccessMassageText("Updated Successfully");
        setShowSuccessSnackbar(true);
        setTimeout(() => {
            setShowSuccessSnackbar(false);
        }, 3000);
    }

    eventBus.$on("isEditProductError", (data) => isEditProductError(data));
    async function isEditProductError(data) {
        setShowErrorSnackbar(true);
        setSuccessMassageText("Something Went Wrong");
        setTimeout(() => {
            setShowErrorSnackbar(false);
        }, 3000);
    }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox my={3}>
            <Card style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }} >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    backgroundColor: darkMode ? "#1a2035 !important" : "inherit",
                    "& .MuiButtonBase-root": {
                      flex: "none"
                    }
                  }}
                  className={darkMode ? classes.tabs : ""}
                >
                  {subNav.map((item, i) => {
                    let uri = i ? pathname + "/" + item.toLowerCase() : pathname + "/settings";
                    return (
                      <Tab
                        key={i}
                        className={darkMode ? classes.tabs : ""}
                        sx={{ paddingLeft: 5, paddingRight: 5 }}
                        label={`${item.replaceAll("-", " ")}`}
                        component={Link}
                        to={uri}
                        {...a11yProps(i)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <SubscriptionAmount />
              </TabPanel>
            </Box>
            </Card>
      </MDBox>
      <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              content={successMassageText}
              open={showSuccessSnackbar}
              close={() => setShowSuccessSnackbar(false)}
            />
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={successMassageText}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Hoc(Settings);