import { styled } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { BASE_URL_API } from "helpers/config";
import { getuserToken } from "helpers/getusertoken";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import { Line } from "rc-progress";
import { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { useMaterialUIController } from "context";

const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        opacity: 1,
        background: "transparent",
        color: "#7b809a",
        borderRadius: "none",
        boxShadow: "none",
        height: "35px",
      },
      textContainer: {
        width: "100%",
      },
      text: {
        fontWeight: 300,
        fontSize: "1rem",
        marginLeft: "120px",
        marginRight: "120px",
      },
      root: {
        minHeight: "120px",
        padding: "0px",
      },
    },
  },
});

const darkModetheme = createMuiTheme({
  palette: {
    type: "dark", // Set theme type to dark
  },
  overrides: {
    MuiDropzoneArea: {
      icon: {
        opacity: 1,
        background: "transparent",
        color: "#ffffff",
        borderRadius: "none",
        boxShadow: "none",
        height: "35px",
      },
      textContainer: {
        width: "100%",
      },
      text: {
        fontWeight: 300,
        fontSize: "1rem",
        marginLeft: "120px",
        marginRight: "120px",
        color: "#ffffff",
      },
      root: {
        border: "2px dotted #ffffff !important", // Change border color
        borderRadius: "8px", // Optional: Add border radius for rounded corners
        minHeight: "120px",
        padding: "0px",
        backgroundColor: "#202940",
      },
      invalid: {
        backgroundImage: "none", // Remove background image for invalid state
        color: "#202940", // Change text color to white for invalid state
        backgroundColor: "#202940", // Change background color to white for invalid state
        border: "solid",
        borderColor: "#ffffff",
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ImportCsvDialog({ onclose, restaurantId, businessId, invoiceId }) {
  const [fileLength, setFileLength] = useState(0);
  const [keys, setKeys] = useState(0);
  const [massage, setMassage] = useState("");
  const [showLoder, setShowLoder] = useState(false);
  const [hideUpload, setHideUpload] = useState(true);
  const [progresBar, setProgresBar] = useState(0);
  const [uploadText, setUploadText] = useState("Please Wait...");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [counter, setCounter] = useState(0);
  const [addNum, setAddNum] = useState(1);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const MAX_SIZE = 10485760;

  useEffect(() => {
    if (progresBar === 33) {
      setCounter(20);
    } else if (progresBar === 66) {
      setCounter(50);
    } else if (progresBar === 99 && fileLength === keys) {
      setAddNum(0);
      setCounter(75);
    }
  }, [progresBar]);

  const handlErrorInvoice = (error) => {
    setMassage(error?.response?.data?.message);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
      onclose();
    }, 3000);
    eventBus.$emit("closeNewInvoicePopup", error);
  };
  
  const handleFile = (file) => {
    setFileLength(file.length - 1);
    file.map((prop, key) => {
      handleUploadChange(prop, key, file);
    });
  };

  function handleUploadChange(prop, key, file) {
    {
      setInterval(() => {
        setCounter((counter) => (counter < 95 ? counter + addNum : counter + 0));
      }, 500);
    }

    // event.preventDefault();
    setShowLoder(true);
    setHideUpload(false);
    setFileLength(file.length - 1);
    setKeys(key);

    const url = BASE_URL_API + `/clientusers/profiles/uploader`;

    const dataRaw = {
      scopeType: "IMPORT",
      scopeId: `${restaurantId ? restaurantId : location.pathname.split("/").slice(-2)[0]}`,
      extension: "csv",
    };
    const config = {
      headers: {
        Authorization: `${getuserToken()}`,
        "content-type": "application/json",
      },
      onUploadProgress: (progressEvent) => {
        setProgresBar(Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / 3));
      },
    };
    axios
      .post(url, dataRaw, config)
      .then((response) => {
        const url2 = `${response.data.data.SignedObject.postData.url}`;

        let createData = {
          uploadFileUrl: `${response.data.data.SignedObject.uploadUrl}`,
          mimetype: `${response.data.data.ContentType}`,
        };

        let formData = new FormData();

        for (const [key, value] of Object.entries(
          response.data.data.SignedObject.postData.fields
        )) {
          formData.append(key, value);
        }
        formData.append("file", prop);

        if (response.data.isSuccess) {
          // Upload the file
          setMassage(response?.data?.data?.message);
          eventBus.$emit("disableclosebutton", true);
          uploadFileOnS3(url2, formData, createData);
        } else {
          setProgresBar(0);
          setShowLoder(false);
          setShowErrorSnackbar(true);
          setTimeout(() => {
            setShowErrorSnackbar(false);
            onclose();
          }, 3000);
        }
      })
      .catch((error) => handlErrorInvoice(error));
    // });
  }
  function uploadFileOnS3(url, formData, createData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) =>
        setProgresBar(33 + Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / 3)),
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.status == 200 || response.status == 204) {
          setMassage(response?.data?.data?.message);
          createInvoice(createData);
        } else {
          setMassage(response?.data?.data?.message);
          setShowErrorSnackbar(true);
          setTimeout(() => {
            setShowErrorSnackbar(false);
            onclose();
          }, 3000);
        }
      })
      .catch((error) => handlErrorInvoice(error));
  }
  function createInvoice(createData) {
    const url =
      BASE_URL_API +
      `/clientusers/restaurants/${restaurantId ? restaurantId : location.pathname.split("/").slice(-2)[0]}/import`;

    const dataRaw = {
      uploadedFileUrl: createData.uploadFileUrl,
      mimetype: createData.mimetype,
    };

    const config = {
      headers: {
        Authorization: `${getuserToken()}`,
        // 'content-type': 'application/json',
      },
      onUploadProgress: (progressEvent) =>
        setProgresBar(66 + Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / 3)),
    };

    axios
      .put(url, dataRaw, config)
      .then((response) => {
        if (response.status == 200 || response.status == 204) {
          setUploadText("Document Uploded");
          setShowLoder(false);
          setHideUpload(true);
          setMassage(response?.data?.data?.message);
          eventBus.$emit("closeImportFilePopup", response?.data?.data?.message);
          onclose();
        } else if (response.isSuccess == false) {
          setMassage(response?.data?.data?.message);
          eventBus.$emit("closeFailImportFilePopup", response?.data?.data?.message);
          onclose();
        }
      })
      .catch((error) => handlErrorInvoice(error));
  }

  const Input = styled("input")({
    display: "none",
  });
  const dropzoneStyle = {
    width: "100%",
    height: "20%",
    border: "1px solid black",
  };
  return (
    <MDBox mb={3}>
      <MDBox my={2} display="flex" justifyContent="center">
        <Box style={{ overflow: "hidden" }}>
          <Grid item xs={12} sm={12} md={12}>
            <MDBox sx={{ fontSize: "2rem" }}>
              {showLoder && (
                <MDBox sx={{ mt: -3 }}>
                  <Line
                    percent={progresBar}
                    strokeWidth={4}
                    strokeColor="#0d87f6"
                    width={175}
                    trailWidth={4}
                  />
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    sx={{ ml: 1 }}
                  >
                    {progresBar === 99 ? `100%` : counter + "%"}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
            {showLoder && (
              <MDBox sx={{ marginLeft: 4 }}>
                <MDTypography variant="h5" fontWeight="regular">
                  {uploadText}
                </MDTypography>
              </MDBox>
            )}
            <MDBox>
              <label style={{ minHeight: "150px" }} htmlFor="icon-button-file">
                {hideUpload && (
                  <>
                    <MuiThemeProvider theme={darkMode ? darkModetheme : theme}>
                      <DropzoneArea
                        filesLimit={100}
                        acceptedFiles={[
                          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                        ]}
                        onDrop={handleFile}
                        maxFileSize={MAX_SIZE}
                      />
                    </MuiThemeProvider>
                  </>
                )}
              </label>
            </MDBox>
          </Grid>
        </Box>
      </MDBox>
      <MDBox>
        <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content={massage}
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          content={massage ? massage : "Failed to Import CSV"}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
      </MDBox>
    </MDBox>
  );
}

export default ImportCsvDialog;
