import RestoreIcon from "@mui/icons-material/Restore";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import UserHoc from "hoc/userHoc";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AccountantClientEdit, GetDeleteuserClientList } from "services/userservices";
import { useAPICall } from "utils/useapicall";
import DashboardLayout from "layouts/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import DataTable from "layouts/components/Tables/DataTable";

function DeleteClients() {
  const action = { route: "", edittooltip: "Restore", deletetooltip: "Delete Profile" };
  const [adminList, setAdminList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [clientId, setclientId] = useState();
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetDeleteuserClientList, { onLoad: false});
  const [{ isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse },UserStatusAPICall] = useAPICall(AccountantClientEdit, { onLoad: false });

  useEffect(() => {
    if (isUserStatusLoading) {
      return () => null;
    }

    if (isUserStatusError) {
      setLoading(false);
      return () => null;
    }

    if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {
      setLoading(true);
      setAPICall({
        payload: [{ isArchived: "1" }],
      });
    }
  }, [UserStatusResponse]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const successDelete = async () => {
    await UserStatusAPICall({
      payload: [
        {
          isArchived: false,
          isBlocked: false,
          bussinessId: clientId,
        },
      ],
    });
    setAlert(false);
  };
  const hideAlert = () => {
    setAlert(false);
  };

  useEffect(async () => {
    setLoading(true);
    await setAPICall({
      payload: [{ isArchived: "1" }],
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      async () =>
        await setAPICall({
          payload: [{ keyword: `${search}`, isArchived: "1" }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          snum: key + 1,
          client: prop.name,
          doctoreview: prop.toReview,
          unmappedvendors: prop.unmappedVendor,
          clientapproval: Createdate.toLocaleDateString(),
          yourapproval: prop.approved,
          pendingapproval: prop.pendingApproval,
          unreconciled: "-",
          switch: prop.isBlocked ? (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="error"
              >
                Inactive
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="success"
              >
                Active
              </MDTypography>
            </MDBox>
          ),
          action: (
            <MDBox display="flex">
              <Link
                to={`/accountants/client/deleted/list`}
                onClick={async () => {
                  setclientId(prop._id);
                  warningWithConfirmMessage();
                }}
              >
                <MDBox display="flex">
                  <MDTypography
                    variant="body2"
                    color="success"
                    sx={{ fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip title={action.edittooltip} placement="top">
                      <Icon>restore</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </Link>
            </MDBox>
          ),
        };
      });
      setAdminList(data);
      setLoading(false);
    }
  }, [response]);

  const dataTableData = {
    columns: [
      {
        Header: "Client",
        accessor: "client",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "To Review",
        accessor: "doctoreview",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unmapped Vendors",
        accessor: "unmappedvendors",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Client Approval",
        accessor: "clientapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Your Approval",
        accessor: "yourapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Pending Approval",
        accessor: "pendingapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unreconciled Statements",
        accessor: "unreconciled",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: adminList,
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox
            pt={3} px={2} sx={{ pr: 3, mb: 2 }}
            component="form" display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Archived Clients
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search Client"
                  variant="standard"
                  onChange={handleSearch}
                  sx={{
                    input: { color: darkMode ? "#ffffff" : "#000000" },
                    "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                  }} 
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>{alert}</MDBox>
          {tableEmpty == "0" ? (
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={true}
              />
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "-2%" }}
              >
                No records found
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={true}
              />
            </MDBox>
          )}
          <MDBox>
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <Dialog
              open={alert}
              onClose={hideAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    height: "50%",
                    maxWidth: "510px",
                  },
                },
              }}
            >
              <DialogContent>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="body2" color="secondary" sx={{ fontSize: 70 }}>
                    <RestoreIcon sx={{ color: green[500], fontSize: 50 }} />
                  </MDTypography>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <h1>Are you sure?</h1>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ fontWeight: 200, fontSize: "1.20rem" }}
                  >
                    Are you sure you want to restore account?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <MDButton variant="gradient" color="success" onClick={successDelete}>
                  Yes, restore it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(DeleteClients);