import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { green, yellow } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import logo from "assets/images/logos/paynthr-logo-colored.png";
import { Button, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { eventBus } from "utils/eventbus";
import { canAccess } from "../../../helpers";
import { GetclientRestaurantList, removeRestaurantAPI } from "../../../services/clientservices";
import { useAPICall } from "../../../utils/useapicall";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import EditRestaurant from "./edit-restaurant";
import AddNewRestaurant from "./new-restaurants";

function Restaurant() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [restaurantList, setRestaurantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [massage, setMassage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [restaurantId, setRestaurantId] = useState();
  const [loaderClass, setLoaderClass] = useState({});
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [openAddNewRestarant, setOpenOpenAddNewRestarant] = useState(false);
  const [openEditRestarant, setOpenEditRestarant] = useState(false);
  const [showAddNewCradModel , setShowAddNewCradModel] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetclientRestaurantList, { onLoad: false });
  const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeRestaurantAPICall] = useAPICall(removeRestaurantAPI, { onLoad: false });
  var resource = "";
  var actions = "";
  const { pathname } = useLocation();

  const Modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  const ModalDrakstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#202940",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  eventBus.$on("FailedToRestaurants", (data) => FailedToRestaurants(data));
  function FailedToRestaurants(data) {
    setMassage("Failed to Add New Restaurant");
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("IsSuccessToRestaurants", (data) => IsSuccessToRestaurants(data));
  function IsSuccessToRestaurants(data) {
    if (data?.data?.data?.restaurant?.status === "Failed") {
      setShowAddNewCradModel(true);
    } else {
    setMassage("New Restaurant Added Successfully");
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  }
  }

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const offset = (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", Number(offset));
    setAPICall({
      payload: [{ keyword: `${search}`, offset: Number(offset), limit: PerPagelimit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: 0, limit: Number(data) }],
    });
  }

  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse ,limit: 10 }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" }) : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    if (isRemoveLoading) {
      return () => null;
    }

    if (isRemoveError) {
      setAlert(false);
      setMassage("Failed To Remove Restaurant");
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setLoadingCircul(true);
      return () => null;
    }

    if (removeResponse && removeResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setMassage("Restaurant Removed Successfully");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 4000);
      setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse , limit: PerPagelimit}],
      });
    }
  }, [removeResponse]);

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
    const timeOutId = setTimeout(
      async () =>
        await setAPICall({
          payload: [{ keyword: `${search}`, offset: ThisOffsetuse , limit: PerPagelimit }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const successDelete = async () => {
    setLoading(true);
    await removeRestaurantAPICall({
      payload: [{ restaurantId: restaurantId }],
    });
    setAlert(false);
  };

  const hideAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (response && response?.data?.isSuccess === true) {
      setTableEmpty(response?.data?.data?.totalRecords);
      const data = response?.data?.data?.records.map((prop, key) => {
        return {
          snum: key + 1,
          restaurantsName: (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="info"
                sx={{ cursor: "pointer" }}
                onClick={async (value) => {
                  navigate(`/client/inventory/${prop?._id}/list`);
                }}
              >
                {prop?.name ? prop?.name : "-"}
              </MDTypography>
            </MDBox>
          ),
          clientName: prop?.business?.name ? prop?.business?.name : "-",
          outOfStock: prop?.totalOutOfStock,
          lowStock: prop?.totalLowStock,
          undarPar: prop?.totalUnderPar,
          par: prop?.totalPar,
          action: (
            <MDBox display="flex" justifyContent="center">
              <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "-4px" }}>
                <Tooltip
                  title={action.edittooltip}
                  sx={{
                    cursor: "pointer",
                  }}
                  placement="top"
                  onClick={(value) => {
                    setOpenEditRestarant(true);
                    setRestaurantId(prop?._id);
                  }}
                >
                  <Icon style={darkMode ? { color: "#ffffff" } : {}}>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ cursor: "pointer", marginLeft: "8px" }}
              >
                <Tooltip
                  title={action.deletetooltip}
                  placement="top"
                  onClick={(value) => {
                    warningWithConfirmMessage();
                    setRestaurantId(prop?._id);
                  }}
                >
                  <Icon style={darkMode ? { color: "#ffffff" } : {}}>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ),
        };
      });
      setRestaurantList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  const restaurantsList = {
    columns: [
      {
        Header: "Restaurant Name",
        accessor: "restaurantsName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Business Name",
        accessor: "clientName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Out of Stock",
        accessor: "outOfStock",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Low Stock",
        accessor: "lowStock",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "under Par",
        accessor: "undarPar",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Par",
        accessor: "par",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: restaurantList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setLoadingCircul(true);
    window.history.pushState("data","title",`${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const RedirectToBillingPage = () => {
    navigate('/client/billing')
  };

  const handleCloseAddRestaurant = async () => {
    setOpenOpenAddNewRestarant(false);
    const ThisOffsetuse = localStorage.getItem("offset");
    await setAPICall({
      payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  };
  const handleCloseEditRestaurant = async () => {
    const ThisOffsetuse = localStorage.getItem("offset");
    setOpenEditRestarant(false);
    await setAPICall({
      payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card sx={{ ...loaderClass, backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
          {!isMobile ? (
            <MDBox
              pt={3}
              px={2}
              sx={{ pr: 3, mb: 2 }}
              component="form"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                Restaurants
              </MDTypography>
              <MDBox px={2} display="flex">
                <MDBox sx={{ pr: 3, mb: 2 }}>
                  <MDInput
                    type="search"
                    label="Search Restaurant"
                    onKeyDown={handleKeyDown}
                    variant="standard"
                    onChange={handleSearch}
                    InputLabelProps={{
                      style: darkMode ? { color: "#ffffff" } : {},
                    }}
                    sx={{
                      "& input": {
                        color: darkMode ? "#ffffff" : "#000000", // Change input text color
                      },
                      "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                    }}
                  />
                </MDBox>
                {canAccess((actions = "create"), (resource = "restaurants")) && (
                  <MDBox>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => setOpenOpenAddNewRestarant(true)}
                      disabled={loading}
                    >
                      {" "}
                      Add New Restaurant
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          ) : (
            <MDBox pt={2} px={2}>
              <MDBox mt={1} display="flex" justifyContent="space-between">
                <MDTypography variant="h5" fontWeight="medium">
                  Restaurants
                </MDTypography>
                <MDBox
                  display="flex"
                  sx={{
                    mb: 1,
                    "@media(max-width: 440px)": {
                      marginLeft: "-20px",
                    },
                  }}
                >
                  {canAccess((actions = "create"), (resource = "restaurants")) && (
                    <MDBox ml={6} mr={1}>
                      <img
                        onClick={() => setOpenOpenAddNewRestarant(true)}
                        width="25"
                        height="25"
                        style={{ mt: 1.3 }}
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAM0lEQVR4nGNgGC4gC4qpBq5C8aiBQz0Ms5A0ouMfUIxLPosuBg7+MMQHRg0chMUXw4ABAOOQLyG/lpv1AAAAAElFTkSuQmCC"
                        title="Add Restaurant"
                      ></img>
                    </MDBox>
                  )}
                  <MDBox display="flex">
                    <MDBox mt={-1.2}>
                      <input
                        variant="standard"
                        type="search"
                        class="search-field"
                        onKeyDown={handleKeyDown}
                        onChange={handleSearch}
                        placeholder="Search"
                        title="Search Restaurant"
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          )}
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="success"
              content={massage ? massage : "Restaurant Added Successfully"}
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={massage ? massage : "Failed to Add New Restaurant"}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
          <MDBox>
            <Dialog
              open={alert}
              onClose={hideAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogContent>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography variant="body2" color="secondary" sx={{ fontSize: 50 }}>
                    <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                  </MDTypography>
                </DialogContentText>
                <DialogContentText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: darkMode ? "#ffffff" : "#000000",
                  }}
                >
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 300,
                      fontSize: "0.90rem",
                      textAlign: "center",
                      color: darkMode ? "#ffffff" : "#000000",
                    }}
                  >
                    If deleted, you will not be able to recover this records.
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <MDButton variant="gradient" color="success" onClick={successDelete}>
                  Yes, delete it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openAddNewRestarant}
              onClose={() => setOpenOpenAddNewRestarant(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    minHeight: "30%",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Add New Restaurant
              </DialogTitle>
              <DialogContent>
                <AddNewRestaurant onClose={handleCloseAddRestaurant} />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
          <Modal
            open={showAddNewCradModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={darkMode ? ModalDrakstyle  : Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0 }}
                  onClick={() => setShowAddNewCradModel(false)}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={darkMode ? { mt: 2 , color: '#ffffff' } : { mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Trial Period Ended: Add Card Details
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={darkMode  ?  {color: '#ffffff', mt: 4, ml: 1 } : { mt: 4, ml: 1 }}>
                  Your trial period has ended. To continue our service without interruption or to add a new restaurant, Please add your card details. Thank you for being a valued user, and we hope to keep serving you.
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
                sx={darkMode ? {color:'#ffffff'} : {}}
              >
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={darkMode  ?  {color: '#ffffff', mt: 4, mb: 2 } : { mt: 4, mb: 2 }}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={RedirectToBillingPage}
              >
                Add Card Details
              </MDButton>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                {/* <MDButton onClick={() => setOnTrialCheck(false)}>Remind me later</MDButton> */}
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
          <MDBox>
            <Dialog
              open={openEditRestarant}
              onClose={() => setOpenEditRestarant(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    minHeight: "30%",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Edit Restaurant
              </DialogTitle>
              <DialogContent>
                <EditRestaurant restaurantId={restaurantId} onClose={handleCloseEditRestaurant} />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox my={5} sx={{ mt: 1 }}>
            <DataTable1
              table={restaurantsList}
              entriesPerPage={false}
              pagination={true}
              showTotalEntries={true}
              response={tableEmpty}
              showCheck={false}
              ClientHeigth={true}
            />
          </MDBox>
        </Card>
        <MDBox display="flex" justifyContent="center">
          {loading && <Loader />}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(Restaurant);