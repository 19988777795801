import { getUserRole } from "helpers/getUserRole";
import { useSelector } from "react-redux";
import configureStore from "store";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function test() {
    const role = useSelector(state => state.role);
    const permission = useSelector(state => state.permission);
}

export function canAccess(action, resource) {
    var userRole = getUserRole();
    var testscope = [
        {
            "category": "inventory",
            "scope": "clients",
            "allScopes": true,
            "scopeIds": [],
            "grants": [
                {
                    "resource": "clients",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2c"
                },
                {
                    "resource": "employees",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2d"
                },
                {
                    "resource": "vendors",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2e"
                },
                {
                    "resource": "locations",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete",
                        "rowsortings"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2f"
                },
                {
                    "resource": "units",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f30"
                },
                {
                    "resource": "restaurants",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete",
                        "columnsortings"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f31"
                },
                {
                    "resource": "inventories",
                    "actions": [
                        "create",
                        "update",
                        "read",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f32"
                },
                {
                    "resource": "profiles",
                    "actions": [
                        "create",
                        "update",
                        "read",
                        "delete",
                        "subscriptions",
                        "payments"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f33"
                }
            ],
            "role": "owner"
        },
        {
            "category": "accountants",
            "scope": "clients",
            "allScopes": true,
            "scopeIds": [],
            "grants": [
                {
                    "resource": "clients",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2c"
                },
                {
                    "resource": "employees",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2d"
                },
                {
                    "resource": "vendors",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2e"
                },
                {
                    "resource": "locations",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete",
                        "rowsortings"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f2f"
                },
                {
                    "resource": "units",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f30"
                },
                {
                    "resource": "restaurants",
                    "actions": [
                        "create",
                        "read",
                        "update",
                        "delete",
                        "columnsortings"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f31"
                },
                {
                    "resource": "inventories",
                    "actions": [
                        "create",
                        "update",
                        "read",
                        "delete"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f32"
                },
                {
                    "resource": "profiles",
                    "actions": [
                        "create",
                        "update",
                        "read",
                        "delete",
                        "subscriptions",
                        "payments"
                    ],
                    "_id": "6525cca3d3f3e85bb6a22f33"
                }
            ],
            "role": "owner"
        }
    ];

    if (action == 'always') {
        return true;
    }
    const myPer = cookies.get('permissions');
    if (myPer) {
        permission = myPer;
    }
    var permission = configureStore.getState().roleReducer.permission;
    if (action && resource) {
        if (userRole == 'admin') {
            return true;
        }

        if (permission) {
            const data = permission.map((prop, key) => {
                return prop?.grants
            });
            const finalArr = data.flat(1);
            if (finalArr) {
                if (action == 'main') {
                    return true;
                }
                if (finalArr) {
                    const finalPermissionclient = finalArr.find(x => x.resource == resource);
                    return finalPermissionclient?.actions.includes(action);
                }
            }
        }
    }
    return false;
}

export function userRole() {
    const role = configureStore.getState().roleReducer.role;
    return role;
}