import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useMaterialUIController } from "context";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const handleValueChange = (name, setFieldValue) => (val) =>
    setFieldValue(name, val.floatValue)

const CurrencyFieldText = ({ currencySymbol, ...props }) => {
    const [displayValue, setDisplayValue] = useState();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (
        <NumberFormat
            customInput={TextField}
            variant="standard"
            isNumericString={true}
            fixedDecimalScale
            thousandSeparator={true}
            value={displayValue}
            decimalScale={2}
            sx={{ color: darkMode ? "#ffffff" : "#000" }}
            InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
            onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
            InputProps={{
                startAdornment: <span>{`$`}</span>,
                style: { borderBottom: darkMode && "2px solid", color: darkMode ? "#ffffff" : "#000" },
                endAdornment: (
                  <Tooltip title={props.ErrorToolTip} placement="top">
                    <InputAdornment position="end">
                      <ErrorOutlineIcon sx={{ fontSize: "1rem !important", mr: "14px", color: "red" }} />
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            {...props}
        />
    );
};


export default CurrencyFieldText;