import * as Yup from "yup";

export const CreatePassSchema = Yup.object().shape({
    password: Yup
        .string()
        .trim()
        .required('New password is required')
        .matches(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{6,}$",
            "Password must contain at least 1 uppercase , 1 lowercase , 1 number, 1 special character and length between 6 to 20"
        ),
    repeatPassword: Yup.string()
        .trim()
        .required('Confirm new password is required.')
        .oneOf([Yup.ref("password"), null], "Your password doesn't match."),
});