import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import NumberFieldText from "layouts/accountants/user/components/NumberField";
import FormField from "layouts/admin/accountants/components/FormField";
import React, { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { AddNewItem, GetClassesList, GetCustomerList, GetProductsList } from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import CurrencyFieldText from "../components/CurrencyField-Text";

function AddItemDialog({ invoiceId, documentId, onClose,currencySymbol, businessId, itemList, showClasses}) {
  const [invoiceIds, setInvoiceIds] = useState(invoiceId);
  const [priceErrorMsg, setPriceErrorMsg] = useState("");
  const [price, setPrice] = useState(0);
  const [openAddOption, setOpenAddOption] = useState(false);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [quantityErrorMsg, setQuantityErrorMsg] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [priceerror, setpriceerror] = useState(false);
  const [productName, SetProductName] = useState("");
  const [customerName, SetCustomerName] = useState("");
  const [className, SetClassName] = useState("");
  const [isDisable, setBtnDisable] = useState(false);
  const [value, setValue] = useState("ItemBasedExpenseLineDetail");
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [classesList, setClassesList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productIds, setProductId] = useState("");
  const [classIdIds, setClassIdId] = useState("");
  const [customerIds, setCustomerIdId] = useState("");
  const [producterrormsg, setProducterrormsg] = useState("");
  const [producterror, setProducterror] = useState(false);
  const [adjustInvoice, setAdjustInvoice] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
  const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
  const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
  const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });

  useEffect(() => {
    let prices = typeof price == "string" ? price.replaceAll(",", "") : price;
    let quantitys = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;

    const timeOutId = setTimeout(
      async () => setTotalPrice(Number(prices) * Number(quantitys)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [price, quantity]);

  useEffect(async () => {
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  const initialValues = {
    product: "",
    checked: "",
    item: description,
    sku: "",
    price: unitPrice,
    total: "",
    qty: "",
    services: "",
    class: "",
    customer: "",
    unit: "",
    category: "",
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeAddNewItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          customerId: prop._id,
        };
      });
      setCustomerList(data);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
        };
      });
      setProductsList(data);
    }
  }, [ProductsResponse]);

  const handleSubmit = async (values) => {
    if (productIds) {
      setProducterrormsg("");
      setProducterror(false);
    } else {
      setProducterrormsg("Product name required");
      setProducterror(true);
      return;
    }
    const finalprice = typeof price == "string" ? price.replaceAll(",", "") : price;
    const finalTotalPrice = typeof TotalPrice == "string" ? TotalPrice.replaceAll(",", "") : TotalPrice;
    const finalquantity = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    if (productIds) {
      setBtnDisable(true);
      await setAPICall({
        payload: [
          {
            documentId: `${invoiceIds}`,
            itemType: "ItemBasedExpenseLineDetail",
            unitPrice: Number(finalprice),
            amount: Number(finalTotalPrice),
            discount: 0,
            sku: values.sku,
            description: values.item,
            unitOfMeasure: values.unit,
            productId: productIds,
            classId: classIdIds,
            customerId: customerIds,
            quantity: Number(finalquantity),
            adjustDocument: adjustInvoice,
          },
        ],
      });
    }
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  const handleChangeProduct = (event, newValue) => {
    if (newValue) {
      setProducterrormsg("");
      setProducterror(false);
    } else {
      setProducterrormsg("Product name required");
      setProducterror(true);
    }
    SetProductName(newValue);
    const found = productsList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      setProductId(found.productId);
      setDescription(found?.description === "---" ? "" : found?.description);
      setUnitPrice(found.unitPrice);
    }
  };

  const handleChangeClass = (event, newValue) => {
    SetClassName(newValue);
    const found1 = classesList.find((obj) => {
      return obj.name === newValue;
    });
    if (found1) {
      setClassIdId(found1.classId);
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    SetCustomerName(newValue);
    const found2 = customerList.find((obj) => {
      return obj.name === newValue;
    });
    if (found2) {
      setCustomerIdId(found2.customerId);
    }
  };

  const handleOpenAddNewProduct = (data) => {
    setOpenAddOption(false);
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox p={2} pt={2}>
                  <MDBox>
                    <MDBox>
                      <Grid container spacing={3} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: darkMode ? "white" : "black"
                              },
                              ...(darkMode && {
                                "& .MuiInput-underline": {
                                  borderBottom: "2px solid #ffffff"
                                },
                                "& .MuiInput-root .MuiInput-input": {
                                  WebkitTextFillColor: "white"
                                },
                                "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                  "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                    color: "white"
                                  }
                                }
                              })
                            }}
                            value={{ name: productName }}
                            options={productsList}
                            getOptionLabel={(option) => option.name}
                            onInputChange={(event, newInputValue) => {
                              handleChangeProduct(event, newInputValue);
                            }}
                            InputLabelProps={{
                              style: { color: darkMode ? "#ffffff" : "#000000" },
                            }}
                            id="controllable-states-demo"
                            disableCloseOnSelect={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // helperText={producterrormsg}
                                // error={producterror}
                                label="Product *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: darkMode ? "#ffffff" : "#000000" },
                                }}
                              />
                            )}
                          />
                          {producterror && (
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {producterrormsg}
                            </MDTypography>
                          </MDBox>
                        )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            sx={
                              darkMode ? {
                                "& .MuiInput-underline": {
                                  borderBottom: "2px solid #ffffff"
                                },
                                "& .MuiInput-root .MuiInput-input": {
                                  WebkitTextFillColor: "white"
                                },
                                "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                  "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                    color: "white !important"
                                  }
                                }
                              } : {
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "black"
                                }
                              }
                            }
                            value={{ name: customerName }}
                            options={customerList}
                            getOptionLabel={(option) => option.name}
                            onInputChange={(event, newInputValue) => {
                              handleChangeCustomer(event, newInputValue);
                            }}
                            InputLabelProps={{
                              style: { color: darkMode ? "#ffffff" : "#000000" },
                            }}
                            id="controllable-states-demo"
                            disableCloseOnSelect={false}
                            renderInput={(params) => (
                              <TextField {...params} label="Customer" variant="standard" InputLabelProps={{
                                style: { color: darkMode ? "#ffffff" : "#000000" },
                              }}/>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {showClasses ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                sx={
                                  darkMode ? {
                                    "& .MuiInput-underline": {
                                      borderBottom: "2px solid #ffffff"
                                    },
                                    "& .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: "white"
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                      "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                        color: "white !important"
                                      }
                                    }
                                  } : {
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black"
                                    }
                                  }
                                }
                                value={{ name: className }}
                                options={classesList}
                                getOptionLabel={(option) => option.name}
                                onInputChange={(event, newInputValue) => {
                                  handleChangeClass(event, newInputValue);
                                }}
                                InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                id="controllable-states-demo"
                                disableCloseOnSelect={false}
                                renderInput={(params) => (
                                  <TextField {...params} label="Class" variant="standard"InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" }} } />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Description" name="item" />
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12} sm={12}>
                            <FormField type="text" label="Description" name="item" />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <FormField type="text" label="Unit" name="unit" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CurrencyFieldText
                            label="Price *"
                            name="price"
                            value={price}
                            error={priceerror}
                            onValueChange={(event) => {
                              setPrice(event.value);
                            }}
                          />
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {priceErrorMsg}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <NumberFieldText
                            label="Quantity *"
                            name="qty"
                            value={quantity}
                            onValueChange={(event) => {
                              setQuantity(event.value);
                            }}
                          />
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {quantityErrorMsg}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CurrencyFieldText
                            label="Total Price"
                            name="TotalPrice"
                            disabled
                            value={TotalPrice}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {/* <MDBox>
                          <Field
                            as={FormControlLabel}
                            type="checkbox"
                            name="checkbox"
                            control={<Checkbox />}
                            label={<spna style={{color: darkMode ? "#ffffff" : "#000"}}>Adjust Total Price</spna>}
                            checked={adjustInvoice}
                            onChange={handleChange}
                            sx={{ mt: 1.5 }}
                          />
                        </MDBox> */}
                      </Grid>
                    </MDBox>
                    <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="light"
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          { isDisable ? "Submitting..." : "Submit"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
        <MDBox>
          <Dialog
            open={openAddOption}
            onClose={() => setOpenAddOption(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#7b809a",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                color: darkMode ? "#ffffff" : "#7b809a",
                justifyContent: "center",
                display: "flex",
              }}
            >
              Product/Service information
            </DialogTitle>
            <DialogContent>
              <List sx={{ width: "100%", bgcolor: darkMode ? 'transparent' : "background.paper" }}>
                <ListItem
                  alignItems="flex-start"
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Service")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Service")}>
                    <ListItemAvatar>
                      <Avatar alt="Service" src="./components/services.png" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Service"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color= {darkMode ? "#ffffff" : "text.primary"}
                          >
                            Services that you provide to customers, for example, landscaping or tax
                            preparation services.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Inventory")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Inventory")}>
                    <ListItemAvatar>
                      <Avatar alt="Inventory" src="/static/images/avatar/2.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Inventory"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color= {darkMode ? "#ffffff" : "text.primary"}
                          >
                            Products you buy and/or sell and that you track quantities of.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => handleOpenAddNewProduct("Non Inventory")}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton onClick={() => handleOpenAddNewProduct("Non Inventory")}>
                    <ListItemAvatar>
                      <Avatar alt="Non Inventory" src="/static/images/avatar/3.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Non Inventory"
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color= {darkMode ? "#ffffff" : "text.primary"}
                          >
                            Products you buy and/or sell but don’t need to (or can’t) track
                            quantities of, for example, nuts and bolts used in an installation.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </DialogContent>
          </Dialog>
        </MDBox>
      </MDBox>
    </>
  );
}

export default AddItemDialog;