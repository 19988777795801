import MDButton from 'components/MDButton';
import React from 'react';

class ConnectToQuickBooksOnlineButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false
        };
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const { accountantDomain, accountantId, accountantReturnUrl, buttonType, loading } = this.props;
        let script1 = document.createElement('script')
        script1.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.1.js"
        script1.type = "text/javascript"
        script1.onload = () => {
            let script2 = document.createElement('script')
            script2.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.1.js"
            script2.type = "text/javascript"
            script2.onload = () => {
                let script3 = document.createElement('script')
                script3.innerHTML = `
        intuit.ipp.anywhere.setup({
            grantUrl: '${process.env.REACT_APP_BASE_URL_API}/externals/qbo/requestToken?u=${accountantId}&d=${accountantDomain}&r=${accountantReturnUrl}',
            datasources: {
                quickbooks : true,
                payments : false
            }
        });
        `
                script3.type = "text/javascript"

                document.getElementsByTagName("head")[0].appendChild(script3)
            }
            document.getElementsByTagName("head")[0].appendChild(script2)
        }
        document.getElementsByTagName("head")[0].appendChild(script1)
    }

    render() {
        return (
            this.props.buttonType === 'custom' ? <MDButton
                disabled={this.props.loading}
                variant="gradient"
                color="info"
                onClick={() => intuit.ipp.anywhere.controller.onConnectToIntuitClicked()}
            >add new client
            </MDButton> :
                <div dangerouslySetInnerHTML={{ __html: "<ipp:connectToIntuit></ipp:connectToIntuit>" }}></div>
        )
    }
}

export default ConnectToQuickBooksOnlineButton;