export const data = [{
    "classifications": [
        {
            "name": "Asset",
            "accountTypes": [
                {
                    "name": "Accounts Receivable",
                    "subTypes": [
                        { "name": "Accounts Receivable" }
                    ]
                },
                {
                    "name": "Bank",
                    "subTypes": [
                        { "name": "Cash On Hand" },
                        { "name": "Checking" },
                        { "name": "Money Market" },
                        { "name": "Rents Held In Trust" },
                        { "name": "Savings" },
                        { "name": "Trust Accounts" }
                    ]                    
                },
                {
                    "name": "Fixed Asset",
                    "subTypes":[
                        { "name": "Accumulated Amortization" },
                        { "name": "Accumulated Depletion" },
                        { "name": "Accumulated Depreciation" },
                        { "name": "Buildings" },
                        { "name": "Depletable Assets" },
                        { "name": "FixedAsset Computers" },
                        { "name": "FixedAsset Copiers" },
                        { "name": "FixedAsset Furniture" },
                        { "name": "FixedAsset Other Tools Equipment" },
                        { "name": "FixedAsset Phone" },
                        { "name": "FixedAsset PhotoVideo" },
                        { "name": "FixedAsset Software" },
                        { "name": "Furniture And Fixtures" },
                        { "name": "Intangible Assets" },
                        { "name": "Land" },
                        { "name": "Leasehold Improvements" },
                        { "name": "Machinery And Equipment" },
                        { "name": "Other Fixed Assets" },
                        { "name": "Vehicles" }
                    ]                    
                },
                {
                    "name": "Other Asset",
                    "subTypes": [
                        { "name": "Accumulated Amortization Of Other Assets" },
                        { "name": "Goodwill" },
                        { "name": "Lease Buyout" },
                        { "name": "Licenses" },
                        { "name": "Organizational Costs" },
                        { "name": "Other Long Term Assets" },
                        { "name": "Security Deposits" }
                    ]                    
                },
                {
                    "name": "Other Current Asset",
                    "subTypes":[
                        { "name": "Allowance For Bad Debts" },
                        { "name": "Development Costs" },
                        { "name": "Employee Cash Advances" },
                        { "name": "Inventory" },
                        { "name": "Investment Mortgage Real Estate Loans" },
                        { "name": "Investment Other" },
                        { "name": "Investment Tax Exempt Securities" },
                        { "name": "Investment US Government Obligations" },
                        { "name": "Loans To Officers" },
                        { "name": "Loans To Others" },
                        { "name": "Loans To Stockholders" },
                        { "name": "Other Current Assets" },
                        { "name": "Prepaid Expenses" },
                        { "name": "Retainage" },
                        { "name": "Undeposited Funds" }
                    ]                    
                },
            ]
        },
        {
            "name": "Equity",
            "accountTypes": [
                {
                    "name": "Equity",
                    "subTypes": [
                        { "name": "Accumulated Adjustment" },
                        { "name": "Common Stock" },
                        { "name": "Estimated Taxes" },
                        { "name": "Healthcare" },
                        { "name": "Opening Balance Equity" },
                        { "name": "Owners Equity" },
                        { "name": "PaidIn Capital Or Surplus" },
                        { "name": "Partner Contributions" },
                        { "name": "Partner Distributions" },
                        { "name": "Partners Equity" },
                        { "name": "Personal Expense" },
                        { "name": "Personal Income" },
                        { "name": "Preferred Stock" },
                        { "name": "Retained Earnings" },
                        { "name": "Treasury Stock" }
                    ]                    
                }
            ]
        },
        {
            "name": "Expense",
            "accountTypes": [
                {
                    "name": "Cost of Goods Sold",
                    "subTypes": [
                        { "name": "Cost Of Labor Cos" },
                        { "name": "Equipment Rental Cos" },
                        { "name": "Equipment Rental Cos" },
                        { "name": "Other Costs Of Service Cos" },
                        { "name": "Shipping Freight Delivery Cos" },
                        { "name": "Supplies Materials Cogs" }
                    ]                    
                },
                {
                    "name": "Expense",
                    "subTypes":[
                        { "name": "Advertising Promotional" },
                        { "name": "Auto" },
                        { "name": "BadDebts" },
                        { "name": "BankCharges" },
                        { "name": "Charitable Contributions" },
                        { "name": "Commissions And Fees" },
                        { "name": "Cost Of Labor" },
                        { "name": "Dues Subscriptions" },
                        { "name": "Entertainment" },
                        { "name": "Entertainment Meals" },
                        { "name": "Equipment Rental" },
                        { "name": "Finance Costs" },
                        { "name": "Global Tax Expense" },
                        { "name": "Insurance" },
                        { "name": "Interest Paid" },
                        { "name": "Legal Professional Fees" },
                        { "name": "Office Expenses" },
                        { "name": "Office General Administrative Expenses" },
                        { "name": "Other Business Expenses" },
                        { "name": "Other Miscellaneous Service Cost" },
                        { "name": "Payroll Expenses" },
                        { "name": "Promotional Meals" },
                        { "name": "Rent Or Lease Of Buildings" },
                        { "name": "Repair Maintenance" },
                        { "name": "Shipping Freight Delivery" },
                        { "name": "Supplies Materials" },
                        { "name": "Taxes Paid" },
                        { "name": "Travel" },
                        { "name": "Travel Meals" },
                        { "name": "Unapplied Cash Bill Payment Expense" },
                        { "name": "Utilities" },
                        { "name": "Utilities" }
                    ]                    
                },
                {
                    "name": "Other Expense",
                    "subTypes": [
                        { "name": "Amortization" },
                        { "name": "Depreciation" },
                        { "name": "Exchange Gain Or Loss" },
                        { "name": "Gas And Fuel" },
                        { "name": "Home Office" },
                        { "name": "Home Owner Rental Insurance" },
                        { "name": "Mortgage Interest" },
                        { "name": "Other Home Office Expenses" },
                        { "name": "Other Miscellaneous Expense" },
                        { "name": "Other Vehicle Expenses" },
                        { "name": "Parking And Tolls" },
                        { "name": "Penalties Settlements" },
                        { "name": "Repairs And Maintenance" },
                        { "name": "Rent And Lease" },
                        { "name": "Vehicle" },
                        { "name": "Vehicle Insurance" },
                        { "name": "Vehicle Lease" },
                        { "name": "Vehicle Loan" },
                        { "name": "Vehicle Loan Interest" },
                        { "name": "Vehicle Registration" },
                        { "name": "Vehicle Repairs" },
                        { "name": "Wash And Road Services" },
                        { "name": "Utilities" }
                    ]                    
                },
            ]
        },
        {
            "name": "Liability",
            "accountTypes": [
                {
                    "name": "Accounts Payable",
                    "subTypes": [
                        { "name": "Accounts Payable" }
                    ]
                },
                {
                    "name": "Credit Card",
                    "subTypes": [
                        { "name": "Credit Card" }
                    ]
                },
                {
                    "name": "Long Term Liability",
                    "subTypes": [
                        { "name": "Notes Payable" },
                        { "name": "Other Long Term Liabilities" },
                        { "name": "Shareholder Notes Payable" }
                    ]
                },
                {
                    "name": "Other Current Liability",
                    "subTypes": [
                        { "name": "Direct Deposit Payable" },
                        { "name": "Federal Income Tax Payable" },
                        { "name": "Global Tax Payable" },
                        { "name": "Global Tax Suspense" },
                        { "name": "Insurance Payable" },
                        { "name": "Line Of Credit" },
                        { "name": "Loan Payable" },
                        { "name": "Payroll Clearing" },
                        { "name": "Payroll Tax Payable" },
                        { "name": "Prepaid Expenses Payable" },
                        { "name": "Rents In TrustLiability" },
                        { "name": "Sales Tax Payable" },
                        { "name": "State Local Income Tax Payable" },
                        { "name": "Trust Accounts Liabilities" },
                        { "name": "Other Current Liabilities" }
                    ]                    
                }
            ]
        },
        {
            "name": "Revenue",
            "accountTypes": [
                {
                    "name": "Income",
                    "subTypes": [
                        { "name": "Discounts Refunds Given" },
                        { "name": "Non Profit Income" },
                        { "name": "Other Primary Income" },
                        { "name": "Sales Of Product Income" },
                        { "name": "Service Fee Income" },
                        { "name": "Unapplied Cash Payment Income" }
                    ]                    
                },
                {
                    "name": "Other Income",
                    "subTypes":[
                        { "name": "Dividend Income" },
                        { "name": "Interest Earned" },
                        { "name": "Other Investment Income" },
                        { "name": "Other Miscellaneous Income" },
                        { "name": "Tax Exempt Interest" }
                    ]                    
                }
            ]
        }
    ]
}]