import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import Users from "layouts/accountants/user";
import DeleteUser from "layouts/accountants/user/delete-user";
import AccountantInvoice from "layouts/accountants/user/invoice/invoice";
import NewUsers from "layouts/accountants/user/new-user";
import Clients from "layouts/accountants/clients";
import Deleteclient from "layouts/accountants/clients/delete-client";
import NewClients from "layouts/accountants/clients/new-clients";

function CheckDarkMode(){
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    
    let routes = [];
    if(!darkMode){
        routes = [
            {
                type: "collapse",
                name: "Users",
                key: "users",
                module: "employees",
                action: "read",
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">groups</Icon>,
                route: "/accountant/users/list",
                noCollapse: true,
                component: <Users />,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">groups</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "add-list",
                        module: "employees",
                        action: "read",
                        route: "/accountant/users/list",
                        component: <Users />,
                    },
                    {
                        name: "Archived",
                        key: "read",
                        module: "employees",
                        action: "update",
                        route: "/accountants/deleted/list",
                        component: <DeleteUser />,
                    },
                    {
                        name: "Add New",
                        key: "add-user",
                        module: "employees",
                        action: "create",
                        route: "/accountants/users/new-user",
                        component: <NewUsers />,
                    },
                ],
            },
            // { type: "divider", key: "divider-1" },
            {
                type: "collapse",
                name: "Clients",
                key: "client",
                module: "clients",
                action: "read",
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">workspaces</Icon>,
                route: "/accountant/client/list",
                component: <Clients />,
                noCollapse: true,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">workspaces</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "clients-list",
                        module: "clients",
                        action: "read",
                        route: "/accountant/client/list",
                        component: <Clients />,
                    },
                    {
                        name: "Archived",
                        key: "archived-list",
                        module: "clients",
                        action: "update",
                        route: "/accountants/client/deleted/list",
                        component: <Deleteclient />,
                    },
                    {
                        name: "Add New",
                        key: "new-clients",
                        module: "clients",
                        action: "create",
                        route: "/accountant/client/new-client",
                        component: <NewClients />,
                    },
                ],
            },
            // { type: "divider", key: "divider-2" },
            {
                type: "collapse",
                name: "Documents",
                key: "document",
                module: "documents",
                action: "read",
                route: "/accountant/document/:id",
                component: <AccountantInvoice />,
                icon: <Icon sx={{ color: '#344767' }} fontSize="medium">receipt</Icon>,
                noCollapse: true,
                collapse: [
                    {
                        name: "list",
                        key: "add-list",
                        module: "documents",
                        action: "read",
                        route: "/accountant/document/:id",
                        component: <AccountantInvoice />,
                    },
                ],
            },
        ];
    }else{
        routes = [
            {
                type: "collapse",
                name: "Users",
                key: "users",
                module: "employees",
                action: "read",
                icon: <Icon fontSize="medium">groups</Icon>,
                route: "/accountant/users/list",
                noCollapse: true,
                component: <Users />,
                icon: <Icon fontSize="medium">groups</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "add-list",
                        module: "employees",
                        action: "read",
                        route: "/accountant/users/list",
                        component: <Users />,
                    },
                    {
                        name: "Archived",
                        key: "read",
                        module: "employees",
                        action: "update",
                        route: "/accountants/deleted/list",
                        component: <DeleteUser />,
                    },
                    {
                        name: "Add New",
                        key: "add-user",
                        module: "employees",
                        action: "create",
                        route: "/accountants/users/new-user",
                        component: <NewUsers />,
                    },
                ],
            },
            // { type: "divider", key: "divider-1" },
            {
                type: "collapse",
                name: "Clients",
                key: "client",
                module: "clients",
                action: "read",
                icon: <Icon fontSize="medium">workspaces</Icon>,
                route: "/accountant/client/list",
                component: <Clients />,
                noCollapse: true,
                icon: <Icon fontSize="medium">workspaces</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "clients-list",
                        module: "clients",
                        action: "read",
                        route: "/accountant/client/list",
                        component: <Clients />,
                    },
                    {
                        name: "Archived",
                        key: "archived-list",
                        module: "clients",
                        action: "update",
                        route: "/accountants/client/deleted/list",
                        component: <Deleteclient />,
                    },
                    {
                        name: "Add New",
                        key: "new-clients",
                        module: "clients",
                        action: "create",
                        route: "/accountant/client/new-client",
                        component: <NewClients />,
                    },
                ],
            },
            // { type: "divider", key: "divider-2" },
            {
                type: "collapse",
                name: "Documents",
                key: "document",
                module: "documents",
                action: "read",
                route: "/accountant/document/:id",
                component: <AccountantInvoice />,
                icon: <Icon fontSize="medium">receipt</Icon>,
                noCollapse: true,
                collapse: [
                    {
                        name: "list",
                        key: "add-list",
                        module: "documents",
                        action: "read",
                        route: "/accountant/document/:id",
                        component: <AccountantInvoice />,
                    },
                ],
            },
        ];
    }
   
    return routes;
}

export default CheckDarkMode;