import { Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import FormField from "layouts/admin/accountants/components/FormField";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { AddVendors, GetVendorDetails, GetVendorList } from "../../../../services/userservices";
import AddNewVendor from "./components/schemas/AddNewVendor";
import AddNewVendorWithoutAddress from "./components/schemas/AddNewVendorWithoutAddress";

function AddVendorDialog({ onClose, businessId, vendorSelect, vendorSelect1 }) {
  const [formState, setFormState] = useState({
    vendorName: "",
    accountNo: "",
    address: "",
    emailId: "",
    phone: "",
    netTerms: "",
    city: "",
    state: "",
    zip: "",
    businessNumber: "",
    billRate: "",
    vendor1099: false,
    trackPayment: false,
    showDetails: false
  });

  const [uiState, setUiState] = useState({
    loading: false,
    btnDisable: false,
    errorMessage: "",
    showErrorSnackbar: false
  });

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorListAPICall] = useAPICall(GetVendorList, { onLoad: false });  
  const [{ response: VendorDetailResponse }, VendorDetailsAPICall] = useAPICall(GetVendorDetails, { onLoad: false }); 
  const [{ isLoading: isAddVendorLoading, isError: isAddVendorError, response: AddVendorResponse }, AddVendorListAPICall] = useAPICall(AddVendors, { onLoad: false });

  useEffect(() => {
    const fetchVendorList = async () => {
      setUiState(prev => ({ ...prev, loading: true }));
      await VendorListAPICall({
        payload: [{ businessId: `${businessId}`, IsSynced: false }],
      });
    };
    fetchVendorList();
  }, []);

  useEffect(() => {
    if (isVendorLoading || isVendorError) return;
    
    if (VendorResponse?.data?.isSuccess) {
      const found = VendorResponse.data.data.records.find(obj => obj.name === vendorSelect);
      const finalVendorName = vendorSelect === vendorSelect1;
      
      if (found) {
        VendorDetailsAPICall({
          payload: [{ VendorId: found._id }],
        });
      } else {
        setFormState(prev => ({
          ...prev,
          vendorName: finalVendorName ? vendorSelect : vendorSelect1,
          showDetails: true
        }));
        setUiState(prev => ({ ...prev, loading: false }));
      }
    }
  }, [VendorResponse]);

  useEffect(() => {
    if (!VendorDetailResponse?.data?.isSuccess) return;

    const vendor = VendorDetailResponse.data.data.vendor;
    const finalVendorName = vendorSelect === vendorSelect1;

    setFormState(prev => ({
      ...prev,
      vendorName: finalVendorName ? vendor.name !== "---" ? vendor.name : "" : vendorSelect1,
      phone: vendor.phone !== "---" ? vendor.phone : "",
      address: vendor.billingAddress?.line1 !== "---" ? vendor.billingAddress.line1 : "",
      city: vendor.billingAddress?.city !== "---" ? vendor.billingAddress.city : "",
      state: vendor.billingAddress?.state !== "---" ? vendor.billingAddress.state : "",
      zip: vendor.billingAddress?.zip !== "---" ? vendor.billingAddress.zip : "",
      accountNo: vendor.accountNo !== "---" ? vendor.accountNo : "",
      emailId: vendor.email !== "---" ? vendor.email : "",
      netTerms: vendor.balance,
      vendor1099: vendor.vendor1099,
      trackPayment: !!vendor.billingAddress?.line1,
      businessNumber: vendor.businessNumber,
      billRate: vendor.billRate,
      showDetails: true
    }));

    setUiState(prev => ({ ...prev, loading: false }));
  }, [VendorDetailResponse]);

  useEffect(() => {
    if (isAddVendorLoading) return;

    if (isAddVendorError) {
      eventBus.$emit("AddnewVendorFailed", AddVendorResponse?.data);
      setUiState(prev => ({
        ...prev,
        loading: false,
        btnDisable: false,
        errorMessage: AddVendorResponse?.data?.message,
        // showErrorSnackbar: true
      }));
      
      setTimeout(() => {
        setUiState(prev => ({ ...prev, showErrorSnackbar: false }));
      }, 3000);
      return;
    }

    if (AddVendorResponse?.data?.isSuccess) {
      setUiState(prev => ({ ...prev, loading: false, btnDisable: false }));
      eventBus.$emit("AddnewVendorComplete", AddVendorResponse.data.data);
      onClose();
    }
  }, [AddVendorResponse]);

  const handleSubmit = async (values) => {
    setUiState(prev => ({ ...prev, btnDisable: true }));

    const payload = {
      name: values.vendor,
      phone: values.phone,
      email: values.email,
      businessNumber: values.businessNumber,
      billRate: Number(values.billRate),
      vendor1099: formState.vendor1099,
      accountNo: values.accountno,
      clientId: `${businessId}`,
      ...(formState.trackPayment && {
        billingAddress: {
          line1: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
        }
      })
    };

    await AddVendorListAPICall({ payload: [payload] });
  };

  const initialValues = {
    vendor: formState.vendorName,
    accountno: formState.accountNo,
    address: formState.address,
    email: formState.emailId,
    phone: formState.phone,
    businessid: "",
    net: formState.netTerms,
    addressfull: formState.trackPayment,
    vendor1099: formState.vendor1099,
    businessNumber: formState.businessNumber,
    billRate: formState.billRate,
    account: "",
    city: formState.city,
    state: formState.state,
    zip: formState.zip,
  };

  return (
    <>
      {formState.showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={formState.trackPayment ? AddNewVendor : AddNewVendorWithoutAddress}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form autoComplete="off">
                      <MDBox p={3}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Vendor *" name="vendor" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Account Number" name="accountno" />
                              </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                  <NumberFormat
                                    customInput={TextField}
                                    variant="standard"
                                    type="tel"
                                    name="phone"
                                    fullWidth
                                    InputLabelProps={{
                                      style: darkMode ? { color: "#ffffff" } : {},
                                    }}
                                    InputProps={{ 
                                      style: darkMode ? { 
                                        color: "#ffffff",
                                        borderBottom: "2px solid" 
                                      } : {} 
                                    }}
                                    value={values?.phone}
                                    label="Phone Number"
                                    format="(###) ###-####"
                                    mask=""
                                    onValueChange={(value) => setFieldValue("phone", value.formattedValue)}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="email" label="Email" name="email" />
                              </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField
                                  type="text"
                                  label="Business ID No./Social Security No."
                                  name="businessNumber"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                  control={<Checkbox 
                                    checked={formState.vendor1099}
                                    onChange={() => setFormState(prev => ({ 
                                      ...prev, 
                                      vendor1099: !prev.vendor1099 
                                    }))}
                                  />}
                                  label={
                                    <Typography style={darkMode ? { color: "#ffffff" , fontSize:'1rem'} : { color: "#7b809a" , fontSize:'1rem'}}>
                                      Track payments for 1099
                                    </Typography>
                                  }
                                  sx={{ mt: 1.5, ml: "-13px" , display:'flex'}}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                  control={<Checkbox 
                                    checked={formState.trackPayment}
                                    onChange={() => setFormState(prev => ({ 
                                      ...prev, 
                                      trackPayment: !prev.trackPayment 
                                    }))}
                                  />}
                                  label={
                                    <span style={darkMode ? { color: "white" } : {}}>
                                      Billing Address
                                    </span>
                                  }
                                  sx={{ mt: 1.5 }}
                                />
                              </Grid>
                            </Grid>

                            {formState.trackPayment && (
                              <>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="Address *" name="address" />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="City *" name="city" />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="State *" name="state" />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="Zip *" name="zip" />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </MDBox>

                          <MDBox mt={4} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={uiState.btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={uiState.btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {uiState.btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>

          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed."
            content={uiState.errorMessage}
            open={uiState.showErrorSnackbar}
            close={() => setUiState(prev => ({ ...prev, showErrorSnackbar: false }))}
          />
        </>
      )}

      {uiState.loading && (
        <CircularProgress
          size={50}
          sx={{
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </>
  );
}

AddVendorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  vendorSelect: PropTypes.string.isRequired,
  vendorSelect1: PropTypes.string.isRequired,
};

export default AddVendorDialog;