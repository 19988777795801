import { Box, MenuItem, Pagination, PaginationItem, Select } from '@mui/material';
import Icon from '@mui/material/Icon';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { eventBus } from 'utils/eventbus';
import DataTableBodyCell from "./Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "./Tables/DataTable/DataTableHeadCell";

function DataTable({entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder, response, selectTab, showCheckBoxs, showCheck, isDailog, footer, ClientHeigth}) {
    const limit = localStorage.getItem("PerPagelimit")
    const defaultValue = limit ? limit : 10;
    const [newpageIndex, setNewpageIndex] = React.useState(0);
    const [selectAllButton, setSelectAllButton] = useState(false);
    const [selectTotalShow, setSelectTotalShow] = useState(defaultValue);
    const [showFooter , setShoweFooter] = useState(true);
    const [hideNodoc ,setHideNodoc ] = useState(true);
    const entries = entriesPerPage.entries ? entriesPerPage.entries.map((el) => el.toString()) : ["10", "20", "50", "100"];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    let pageOptions1 = [];
    let pageOptionsFirst = [];

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    useEffect(() => {
        localStorage.setItem("PerPagelimit",defaultValue);
        const handleResize = () => {
        setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        if(footer == false){
            setShoweFooter(false)
        }
    },[footer])

    useEffect(() => {
        if (response !== null && response !== undefined && response !== '') {
          // If response is not null, undefined, or an empty string, set hideNodoc to true
            setHideNodoc(true);
        } else {
          // If response is null, undefined, empty string or other falsy values, set hideNodoc to false
            setHideNodoc(false);
        }
        }, [response]);
        

    useEffect(() => {
        setSelectTotalShow(10);
        const ThisOffsethandle = localStorage.getItem("offset")
        if (newpageIndex === 0) {
            setNewpageIndex(0)
            localStorage.setItem("offset", 0)
            localStorage.setItem("newpageIndex", 0)
        } else {
            const num = ThisOffsethandle.toString();
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result))
        }
    }, [])

    eventBus.$on('showSelectTabs', (data) => showSelectTabs(data));
    function showSelectTabs(data) {
        setNewpageIndex(0);
    }
    const totalRecords = response
    for (let i = 0; i < totalRecords;) {
        i = i + Number(selectTotalShow)
        pageOptionsFirst.push(i);
    }
    pageOptionsFirst.map((prop, key) => {
        const num = prop.toString();
        if (num >= 100) {
            const str = num.substring(0, num.length - 1);
            pageOptions1.push(str - 1)
        } else {
            const str = num.split('').reverse().join('')
            const result = str.replace(/^0+/, '');
            pageOptions1.push(result - 1)
        }
    })

    eventBus.$on('MoveMenuResponseComplete', (data) => MoveMenuResponseComplete(data));
    function MoveMenuResponseComplete(data) {
        const finaldata = (Number(data) - 10).toString()
        const str = finaldata.split('').reverse().join('')
        const result = str.replace(/^0+/, '');
        setNewpageIndex(Number(result));
    }

    eventBus.$on('showInvoiceStatus', (data) => showInvoiceStatus(data));
    function showInvoiceStatus(data) {
        const found1 = data?.find(obj => {
            return obj.ocrStatus === 'INPROGRESS';
        });
        if (found1) {
            setSelectAllButton(true);
        } else {
            setSelectAllButton(false);
        }
    }

    const tableInstance = useTable({ columns, data, selectTab, initialState: { pageIndex: 0 } },
        useGlobalFilter, useSortBy, usePagination, useRowSelect);

    const {
        getTableProps, getTableBodyProps,
        headerGroups, prepareRow, rows, page, pageOptions,
        canPreviousPage, canNextPage, gotoPage, nextPage,
        previousPage, setPageSize, setGlobalFilter, selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
        onPageChange = (eventBus.$emit('ChangePaginationPage', pageIndex)) } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    useEffect(() => {
        var tempId = [];
        tempId = selectedFlatRows.map(
            d => d.original.id
        )
        eventBus.$emit('invoiceRowSelected', { selectedRowIds: tempId });
    }, [selectedRowIds]);

    const setEntriesPerPage = (value) => setPageSize(value);

    // Search input value state
    const [pages, setPages] = useState(1);
    const ThisOffsetuse = localStorage.getItem("offset");
    const finalPage = ThisOffsetuse == 0 ? 1 : pages;
    const isFirstPage = finalPage === 1;

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }
        return sortedValue;
    };

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions1.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    const handleChange = (e, p) => {
        setPages(p);
        const dataStr = (p - 1).toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        eventBus.$emit('onclickchangePages', Number(p - 1)) || setNewpageIndex(Number(p - 1))
    };

    const changePerPageEntries = (value) => {
        setNewpageIndex(0)
        setEntriesPerPage(parseInt(value, 10));
        setSelectTotalShow(value);
        eventBus.$emit('onclickchangeTotalPerPage', Number(value))
    };

    return (
        <TableContainer sx={{ boxShadow: "none" ,backgroundColor: darkMode ? '#202940' : '#ffffff', height: isDailog ? 'auto' : screenSize < 1440 ? '57vh' : '65vh' }} >
            <Table {...getTableProps()} >
                <MDBox component="thead" >
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}
                        style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: darkMode ? "#202940" : "#ffffff",
                            zIndex: 100,
                        }}
                        >
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? column.width : "auto"}
                                    align={showCheckBoxs === false ? "left" : "center"}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}

                        </TableRow>
                    ))}
                </MDBox>
                <TableBody  {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow  {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <DataTableBodyCell
                                        showCheckBoxs={showCheckBoxs}
                                        ClientHeigth={ClientHeigth}
                                        noBorder={noEndBorder && rows.length - 1 === key}
                                        align={showCheckBoxs === false ? "left" : "center"}
                                        ml={-60}
                                        selectHeight={selectTab}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
                    {(response && showFooter == true) ? 
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                p: !showTotalEntries && pageOptions1.length === 1 ? 0 : 4,
                                right: 25,
                                position: "fixed",
                                bottom: 20,
                                zIndex: 1000,
                                bgcolor: darkMode ? "#202940" : "background.paper",
                                width: "91.2%",
                                borderTop:  darkMode  ? "1px solid #1a2035" : "1px solid #ddd",
                                height: "48px",
                                "@media (max-width: 1440px)": {
                                p: 3,
                                bottom: 0,
                                width: "87.5%",
                                },
                            }}
                        >
                        {response > 1 && (
                        <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={2.2}>
                            <Select
                            value={pageSize.toString()} // Current value
                            onChange={(event) => {
                                changePerPageEntries(event.target.value); // Handle selection change
                            }}
                            size="small"
                            sx={{
                                width: "3.5rem", // Input width
                                height: "2.1rem", // Input height
                                color: darkMode ? '#ffffff' : '#000000', // Set text color to white in dark mode
                                textAlign: 'center', // Center the selected value
                                borderRadius: "8px",
                                '&:hover': {
                                backgroundColor: darkMode ? '#1a73e8' : '#e0f2ff', // Blue hover for dark mode, lighter for light mode
                                },
                                '& .MuiSvgIcon-root': {
                                color: darkMode ? '#ffffff !important' : '#000000 !important', // Custom arrow icon color for dark mode
                                },
                                '& .MuiSelect-select': {
                                color: darkMode ? '#ffffff !important' : '#000000'
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                sx: {
                                    width: "3.5rem", // Match the width of the input
                                    '& .MuiMenuItem-root:hover': {
                                    backgroundColor: '#1a73e8', // Blue hover for menu items in dark mode
                                    },
                                },
                                },
                                anchorOrigin: {
                                vertical: 'top', // Anchor the dropdown at the top of the select
                                horizontal: 'center',
                                },
                                transformOrigin: {
                                vertical: 'bottom', // Transform the dropdown to appear from the bottom
                                horizontal: 'center',
                                },
                            }}
                            disableUnderline
                            >
                            {entries.map((entry) => (
                                <MenuItem 
                                key={entry} 
                                value={entry}  
                                sx={{ 
                                    textAlign: 'center', 
                                    marginLeft:'-8px',
                                    '&:hover': {
                                    backgroundColor: '#1a73e8', // Blue hover color
                                    }
                                }}
                                >
                                {entry}
                                </MenuItem>
                            ))}
                            </Select>
                            <MDTypography variant="caption" color={darkMode ? "#ffffff" : "secondary"}>
                            </MDTypography>
                        </MDBox>
                        )}
                    <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={2.2}  mb={4}>
                        {response >= 1 && (
                            <MDBox sx={{ mr: 1.2, mb: -1.5 }}>
                            <MDTypography
                                variant="button"
                                color={darkMode ? "light" : "secondary"}
                                fontWeight="regular"
                            >
                                Showing results {" "}
                                {newpageIndex == 0 ? "1" : finalPage * Number(selectTotalShow) - Number(selectTotalShow)}{" "} -{" "}
                                {response < finalPage * Number(selectTotalShow) ? response : finalPage * Number(selectTotalShow)}{" "}
                                of {response} 
                            </MDTypography>
                            </MDBox>
                        )}
                        {pageOptions1.length > 1 && (
                            <MDPagination
                            variant={pagination.variant ? pagination.variant : "gradient"}
                            color={pagination.color ? pagination.color : "info"}
                            >
                            {
                            <Pagination
                            page={finalPage}
                            color="primary"
                            sx={{ justifyContent: "center", marginTop: 2, mr: 1.2 }}
                            onChange={handleChange}
                            count={pageOptionsFirst.length}
                            renderItem={(item) => (
                                <>
                                {item.type === 'previous' && isFirstPage ? null : (
                                    <PaginationItem
                                    {...item}
                                    sx={{
                                        color: item.type === 'page' ? darkMode ? '#ffffff' : '#237dea' : darkMode ? '#ffffff' : '#237dea', // White for previous/next in dark mode, blue otherwise
                                        border: item.type === 'page' ? "1px solid #d9d9d9" : 'none', // Add border only to page numbers
                                        margin: "0 5px", // Adds spacing between items
                                        borderRadius: "4px", // Optional for rounded borders
                                        "&.Mui-selected": {
                                        backgroundColor: "#237dea",
                                        color: "white",
                                        },
                                        "&&.Mui-selected:hover": {
                                            backgroundColor: "#237dea", // Keep the background color unchanged for selected pages on hover
                                            color: "#ffffff", // Keep the text color unchanged for selected pages on hover
                                        },
                                        "&.MuiPaginationItem-root:hover": {
                                        backgroundColor: item.type === 'page' ? "#e0f2ff" : 'transparent', // Light blue hover for unselected pages
                                        color: item.type === 'page' ? "#237dea" : darkMode ? "#1a73e8" : '#237dea', // Hover text color blue for dark mode
                                        border: item.type === 'page' ? "1px solid #237dea" : "none", // Optional border color change on hover
                                        }
                                    }}
                                    components={{
                                        next: () => (
                                            <>
                                            {item.type === 'next' && finalPage !== pageOptionsFirst.length && 
                                            <Tooltip title="Next">
                                                <Icon>chevron_right</Icon>
                                            </Tooltip>}
                                            </>
                                        ),
                                        previous: () => 
                                            <>
                                            {item.type === 'previous' && !isFirstPage && 
                                                <Tooltip title="Previous">
                                                <Icon>chevron_left</Icon>
                                                </Tooltip>}
                                            </>,
                                    }}
                                    />
                                )}
                                </>
                            )}
                            />
                            }
                            </MDPagination>
                        )}
                    </MDBox>
                    </Box>:''}
                    </Table>
                    {table?.rows.length == 0  && 
                    <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "15%", marginBottom: "5%" }}
                >
                {hideNodoc ? 'No records found' : ''}
                    </MDTypography>}
        </TableContainer >
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: false,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;