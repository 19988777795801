import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useMaterialUIController } from "context";

function FormField({ label, name, icon, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // Inline CSS styles for dark mode
  const darkModeStyles = {
    color: '#ffffff', // Example text color for dark mode
  };

  const darkTextStyles = {
    color: '#ffffff', // Example text color for dark mode
  };

  return (
    <MDBox mb={1.5}>
      <Field {...rest} name={name} as={MDInput} variant="standard" style={darkMode ? darkModeStyles : {}} label={label} InputLabelProps={{ style: darkMode ? darkModeStyles : {} }} fullWidth autoComplete="off" 
        InputProps={{
          style: { color: darkMode ? '#ffffff' :'' },
          startAdornment: (
            <InputAdornment position="start">
              <MDTypography fontSize='0.8rem' style={darkMode ? darkTextStyles : {}}>
                {icon}
              </MDTypography>
            </InputAdornment>
          ),

        }} />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
