import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import bgImage from "assets/images/backgroundimage.jpg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import BasicLayout from "layouts/auth/components/BasicLayout";
import FormField from "layouts/auth/components/FormField";
import initialValues from "layouts/auth/components/schemas/initialValues";
import { SignUpSchema } from "layouts/auth/components/schemas/validations";
import { Link } from "react-router-dom";

function SignUp() {
  const onSubmit = async (values) => {
    setTimeout(() => {}, 5000);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="success"
          mx={2} mt={-3} p={3} mb={1} sx={{backgroundColor: "#80D6C3"}}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <Formik initialValues={initialValues} validationSchema={SignUpSchema} onSubmit={onSubmit}>
          <Form autoComplete="off">
            <div>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox mb={2}>
                  <MDBox>
                    <FormField type="text" label="Name" name="name" />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormField type="email" label="Email" name="email" />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormField type="password" label="Password" name="password" />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Checkbox />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;I agree the&nbsp;
                    </MDTypography>
                    <MDTypography
                      component="a"
                      href="#"
                      variant="button"
                      fontWeight="bold"
                      color="info"
                      textGradient
                    >
                      Terms and Conditions
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                      sign up
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </div>
          </Form>
        </Formik>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;