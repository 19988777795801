import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "components/MDButton";
import { useMaterialUIController } from 'context';
import PropTypes from 'prop-types';
export default function CustomDialog(props) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { onClose, DialogContents, onSuccess, DialogContentText, open, Title, button, buttonOk, height } = props;
    const handleClose = () => {
        onClose();
    };
    const handleSubmit = () => {
        onSuccess();
    };
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            sx={{
                "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    ...(darkMode && {
                    backgroundColor: "#202940 !important",
                    color: "white !important"
                    })
                }
                }
            }}>
            <DialogTitle sx={{
                justifyContent: "center",
                display: 'flex'
            }}>{Title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {DialogContentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttonOk ? <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit}
                >
                    {buttonOk}
                </MDButton> : ''}
                <Button onClick={handleClose}>{button}</Button>
            </DialogActions>
        </Dialog>
    );
}

CustomDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};