import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController } from "context";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import NotificationItem from "../../Items/NotificationItem";
import { navbar, navbarContainer, navbarMobileMenu, navbarRow } from "../DashboardNavbar/styles";
import { eventBus } from "utils/eventbus";

function DashboardNavbar({ absolute, light, isMini }) {
  const cookies = new Cookies();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [reDate, setReDate] = useState();
  const [profileInfo, setProfileInfo] = useState(cookies.get('user_profile_info'));
  const [showTrial, setShowTrial] = useState(false);
  const [chnageOnTrail , setChangeOnTrail] = useState(false);
  const accountClientAccess = localStorage.getItem('accountClientAccess');
  const [showBillingButton, setShowBillingButton] = useState(false);

  const date_diff_indays = function (date2) {
    const dt1 = new Date();
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
  };

  // eventBus.$on('onclickchangeOnTrial', (data) => onclickchangeOnTrial(data));
  // function onclickchangeOnTrial(data) {
  //   setChangeOnTrail(true);
  // }


  useEffect(() => {
    const onTrialValue = profileInfo?.user?.subscriptions[0].ontrial;
    const onPrimary = profileInfo?.user?.isPrimary;
    setShowBillingButton(onPrimary);
    setShowTrial(onPrimary && onTrialValue);
    setReDate(date_diff_indays(profileInfo?.user?.trialEndDate ? profileInfo?.user?.trialEndDate : profileInfo?.user?.subscriptions[0]?.trialEndDate))
  }, [profileInfo])

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      PaperProps={{
        sx: {
            backgroundColor: darkMode ? '#202940 !important' : '#ffffff', // Change background color
            mt: 2,
        }
    }}
    >
      {accountClientAccess !== 'false' && <Link to="/client/profile-overview">
        <NotificationItem title="Profile" />
      </Link>}
      {(accountClientAccess !== 'false' && showBillingButton) && <Link to="/client/billing">
        <NotificationItem title="Billing" />
      </Link>}
      <Link to="/client/logout">
        <NotificationItem title="Logout" />
      </Link>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      // let colorValue = light || darkMode ? white.main : dark.main;
      let colorValue = darkMode ? white.main : dark.main;
      // if (transparentNavbar && !light) {
      //   colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      // }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
      <MDBox
          sx={{
            '@media (max-width: 1920px)': {
              ml: '86%'
            },
            '@media (max-width: 1440px)': {
              ml: '80%'
            },
            '@media (max-width: 1150px)': {
              ml: '75%'
            },
            ml: '88%'
          }} display="flex" justifyContent="end" >
          {showTrial &&
            <>
              <MDTypography variant="caption" fontWeight="regular" fontSize="1rem" sx={{ color: "#104d9e" }} >
                TRIAL
              </MDTypography>
              <MDTypography variant="caption" fontWeight="regular" fontSize="1rem" color="warning" ml={1} >
                {reDate} days left.
              </MDTypography>
            </>
          }
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {renderMenu()}
              <IconButton
                // sx={navbarIconButton}
                size="medium"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                disableRipple>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;