import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import FormField from "layouts/admin/accountants/components/FormField";
import { EditSubscriptionSchema } from "layouts/components/schemas/editSubscriptionSchema";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { EditProduct } from "services/adminservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import MDSnackbar from "components/MDSnackbar";

function SubscriptionPriceCard({ color, badge, specifications, action, shadow }) {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [editId, setEditId] = useState(null);
  const [editable, SetEditable] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [amount, setAmount] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [{isLoading: isEditProductLoading, isError: isEditProductError, response: editProductResponse },editProductAPICall] = useAPICall(EditProduct, { onLoad: false });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(()=>{
    if(isEditProductLoading){
      return () => null;
    }
    if(isEditProductError){
        eventBus.$emit("isEditProductError", editProductResponse);
        if (editProductResponse?.data?.message) {
          setErrorMessage(editProductResponse?.data?.message);
        }else{
          setErrorMessage("Failed to update subscription");
        } 
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 4000);
        setBtnDisable(false);
        return () => null;
    }
    if(editProductResponse && editProductResponse.data.isSuccess === true){
        eventBus.$emit("editProductResponse", editProductResponse);
        if (editProductResponse?.data?.message && editProductResponse?.data?.message !== "Success") {
          setSuccessMessage(editProductResponse?.data?.message);
        }else{
          setSuccessMessage("Subscription updated successfully");
        }
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 4000);
        setBtnDisable(false);
        setShowEditDialog(false);
    }
  },[editProductResponse])


  const handleEdit = (id,rate) => {
    setTitle(specifications?.subscriptionType !== "clients" ? "Edit Inventory Subscription" : "Edit Accountant Subscription");
    setAmount(rate);
    setEditId(id);
    setShowEditDialog(true);
  }

  const handleClose = () => {
    setEditId(null);
    setShowEditDialog(false);
  }

  const handleCancel = () => {
    setBtnDisable(false);
    SetEditable(false);
  }

  const handleSubmit = (values) => {
    setBtnDisable(true);
    editProductAPICall({ payload: [
        {
            offerId: editId,
            rate: values?.rate,
        }
      ]})
    setTimeout(() => {
        setBtnDisable(false);
    }, 2000);
  }

  const initialValues = {
    rate: amount,
    billingCycle: "",
  }

  const renderSpecifications = specifications?.offers.map(({ label, trialPeriod, billingCycle }) => (
    <MDBox key={label} display="flow" alignItems="center" p={1}>
       <MDTypography
        display="flex"
        justifyContent ="center"
        variant="body1"
        sx={{m:1}}
        color={color === "light" ? "text" : "white"}
        fontWeight="regular"
      >
        Trial Period - {trialPeriod} Mon
      </MDTypography>
      <MDTypography
        display="flex"
        justifyContent ="center"
        variant="body1"
        sx={{m:1}}
        color={color === "light" ? "text" : "white"}
        fontWeight="regular"
      >
        Billing Cycle - {billingCycle}
      </MDTypography>
    </MDBox>
  ));

  const renderPriceSpecifications = specifications?.offers.map(({_id , rate,billingCycle}) => (
    <>
        <MDBox display="flex" justifyContent="center" alignItems="center" width="1.5rem" height="1.5rem" mx="auto" mt={1} mr={1.5} >
        <MDTypography
          variant="body1"
          color={color === "light" ? "text" : "white"}
          sx={{cursor:"pointer", lineHeight: 0 }}
        >
          <Icon onClick={()=>handleEdit(_id,rate)} >{"edit"}</Icon>
        </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={2} textAlign="center">
          <MDBox my={1}>
            <MDTypography variant="h1" color={color === "light" ? "dark" : "white"}>
              ${rate}
            </MDTypography>
            <MDTypography display="inline" component="small" variant="h5" color={color === "light" ? "dark" : "white"}>
                {billingCycle}
              </MDTypography>
          </MDBox>
        </MDBox>
    </>
  ));

  return (
    <>
    <Card sx={{backgroundColor: darkMode ? '#202940 !important' : '#ffffff'}}>
      <MDBox
        bgColor={color === "light" ? "white" : color}
        variant={"gradient"}
        borderRadius="xl"
      >
        <MDBox
          sx={{backgroundColor: darkMode ? '#344767' : '#1a73e8'}} width="max-content"
          px={4} pt={0} pb={0.5} mx="auto" mt={-1.375}
          borderRadius="section" lineHeight={1}
        >
          <MDTypography
            variant="caption"
            textTransform="uppercase"
            fontWeight="medium"
            style={{color: darkMode ? '#ffffff' : '#ffffff'}}
          >
            {specifications?.name}
          </MDTypography>
        </MDBox>
        {renderPriceSpecifications}
        <MDBox pb={3} px={3}>
          {renderSpecifications}
          {action.type === "internal" ? (
            <MDBox mt={3}>
              {/* <MDButton
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MDButton> */}
            </MDBox>
          ) : (
            <MDBox mt={3}>
              {/* <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MDButton> */}
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
      <Dialog
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "450px",
                backgroundColor:darkMode ? '#1a2035 !important':'#ffffff',
                color: darkMode ? '#ffffff':'#000000',
            },
            },
        }}
        onClose={handleClose}
        open={showEditDialog}
        >
      <DialogTitle
        sx={{
          justifyContent: "center",
          display: "flex",
          color: darkMode ? '#ffffff':'#7b809a',
        }}
      >
        {title} 
      </DialogTitle>
      <MDTypography
          variant="body1"
          display="flex"
          justifyContent="end"
          color={color === "light" ? "text" : "white"}
          sx={{mt:-7,mb:7,mr:1.5,cursor:"pointer", lineHeight: 0 }}
        >
          <Icon onClick={handleClose} >{"close"}</Icon>
        </MDTypography>
      <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <Formik
                initialValues={initialValues}
                validationSchema={EditSubscriptionSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, isSubmitting ,handleBlur }) => (
                    <Form autoComplete="off">
                        <MDBox>
                            <MDBox mt={1.625}>
                                <Grid container spacing={3}>
                                <Grid item ml={4} mr={4} xs={12} sm={12}>
                                <FormField
                                    type="text"
                                    label="Subscription Amount *"
                                    name="rate"
                                    variant="standard"
                                    disabled={!editable}
                                />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                    <MDBox
                                      sx={{ display: "flex" }}
                                      mt={{ xs: 2, sm: 3 }}
                                      justifyContent="end"
                                    >
                                      {!editable && (
                                        <MDButton
                                          type="submit"
                                          variant="gradient"
                                          color="info"
                                          onClick={() => SetEditable(true)}
                                        >
                                          Edit
                                        </MDButton>
                                      )}
                                      {editable && (
                                        <MDBox display="flex">
                                          <MDButton
                                            disabled={btnDisable}
                                            variant="gradient"
                                            color="light"
                                            onClick={handleCancel}
                                          >
                                            Cancel
                                          </MDButton>
                                          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                            <MDButton
                                              disabled={btnDisable}
                                              type="submit"
                                              variant="gradient"
                                              color="info"
                                            >
                                              {btnDisable ? "updating..." : "update"}
                                            </MDButton>
                                          </MDBox>
                                        </MDBox>
                                      )}
                                    </MDBox>
                                </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>
                    </Form>
                )}
            </Formik>
        </Grid>
      </Grid>
      </DialogContent>
    </Dialog>
    <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content={successMessage}
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          content={ErrorMessage || "Something went wrong"}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
    </>
  );
}

// Setting default props for the 
SubscriptionPriceCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the 
SubscriptionPriceCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default SubscriptionPriceCard;