import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetuserClientDetails } from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import DashboardLayout from "layouts/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import EditClients from "layouts/accountants/clients/edit-clients";
import Category from "layouts/accountants/clients/setting/category";
import Classes from "layouts/accountants/clients/setting/classes";
import Customer from "layouts/accountants/clients/setting/customer";
import image from "layouts/accountants/clients/setting/output-onlinegiftools.gif";
import Products from "layouts/accountants/clients/setting/products";
import Vendor from "layouts/accountants/clients/setting/vendor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VendorSettings() {
  const [value, setValue] = useState(localStorage.getItem("val"));
  const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [syncPercent, setSyncPercent] = useState(0);
  const [documentSource, setDocumentSource] = useState("");
  const [showSyncing, setShowSyncing] = useState(false);
  const [reloadApi, setReloadApi] = useState(0);
  const [loaderClass, setLoaderClass] = useState({});
  const [showClasses, SetShowClasses] = useState(true);
  const subNav = ["Client Info", "Vendor", "Customer", "Classes", "Category", "Products"];
  const urlParam = window.location.pathname.split("/");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const useStyles = makeStyles((theme) => ({
    tabs: {
      "& .MuiTab-root": {
        backgroundColor: "#202940e3 !important",
        color: "#ffffff !important",
        borderRadius: 4,
        margin: '2px',
        padding:'6px',
        "&:hover": {
          backgroundColor: "#38405585 !important", // Change label color to blue on hover
      },
      },
      "& .MuiTabs-flexContainer": {
        backgroundColor: darkMode ?  "#1a2035 !important" : '#ffffff',
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#ffffff !important",
        backgroundColor: "#006cbe !important" ,
        margin: '2px',
        padding:'6px',
        borderRadius: 4,
        "&:hover": {
          backgroundColor: "#006cbe !important" , // Change label color to blue on hover
      },
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails, { onLoad: false });

  let tabName = urlParam.slice(-1);
  useEffect(async () => {
    handleTab();
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${businessId}` }],
    });
  }, []);

  eventBus.$on("OnSetBlurEdit", (data) =>
    data === true ? setLoaderClass({ opacity: "0.4", borderRadius: "13px" }) : setLoaderClass({})
  );

  useEffect(() => {
    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      SetShowClasses(clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine);
      if (clientDetailsDataResponse?.data?.data?.client?.syncPercent !== 100) {
        setReloadApi(reloadApi + 1);
      }
      eventBus.$emit("clientDetailsDataResponse", clientDetailsDataResponse?.data?.data?.client?.syncPercent);
      setTimeout(() => {
        eventBus.$emit("clientDetailsDataResponse", clientDetailsDataResponse?.data?.data?.client?.syncPercent);
      }, 2000);
      setSyncPercent(clientDetailsDataResponse?.data?.data?.client?.syncPercent);
      setDocumentSource(clientDetailsDataResponse?.data?.data?.client?.documentSource);
      setShowSyncing(true);
    }
  }, [clientDetailsDataResponse]);

  //stop relaod api
  useEffect(() => {
    const fetchData = async () => {
      await getClientDetailsAPICall({
        payload: [{ bussinessId: `${businessId}` }],
      });
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 180000);

    return () => clearTimeout(timer);
  }, [reloadApi]);

  const handleTab = () => {
    if (tabName[0] === "edit-client") {
      setValue(0);
      localStorage.setItem("val", value);
    }
    if (tabName[0] === "vendor") {
      setValue(1);
      localStorage.setItem("val", value);
    }
    if (tabName[0] === "customer") {
      setValue(2);
      localStorage.setItem("val", value);
    }
    if (tabName[0] === "classes") {
      setValue(3);
      localStorage.setItem("val", value);
    }
    if (tabName[0] === "category") {
      setValue(4);
      localStorage.setItem("val", value);
    }
    if (tabName[0] === "products") {
      setValue(5);
      localStorage.setItem("val", value);
    }
  };

  const pathnames = window.location.pathname;
  const pathname = pathnames.substr(0, pathnames.lastIndexOf("/"));
  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={ screenSize < 1440 ? 0 : 2} mb={2}>
          <Card sx={loaderClass} style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  className={darkMode ? classes.tabs : ""}
                  aria-label="basic tabs example"
                  sx={darkMode ? { 
                    padding : '0px !important',
                    backgroundColor: "#1a2035 !important" ,
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'transparent',
                        boxShadow :'none',
                      },
                    '& .Mui-selected': {
                        borderRadius : '0px',
                        backgroundColor: "#006cbe !important" ,
                      },
                      "& .MuiButtonBase-root": {
                        flex: "none",
                      },
                    }:
                  {"& .MuiButtonBase-root": {
                        flex: "none",
                      }
                    }
                  }
                >
                  {subNav.map((item, i) => {
                    let uri = i ? pathname + "/" + item.toLowerCase() : pathname + "/edit-client";
                    return (
                      <Tab
                        className={darkMode ? classes.tabs : ""}
                        key={i}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                        label={`${item.replaceAll("-", " ")}`}
                        component={Link}
                        to={uri}
                        {...a11yProps(i)}
                      />
                    );
                  })}
                  {showSyncing && syncPercent !== 100 && documentSource !== "Paynthr" ? (
                    <MDBox
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div>
                        <img
                          width={25}
                          height={25}
                          style={{ marginBottom: "-6px", marginRight: "8px" }}
                          src={image}
                        />
                      </div>
                      <MDTypography sx={{ mr: 2 }} fontWeight={"bold"} color={"warning"}>
                        Fetching data from Quickbooks({syncPercent}%)
                      </MDTypography>
                    </MDBox>
                  ) : ("")}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <EditClients businessId={businessId} syncPercent={syncPercent} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Vendor
                  businessId={businessId}
                  syncPercent={syncPercent}
                  documentSource={documentSource}
                  IsSynced={true}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Customer
                  businessId={businessId}
                  syncPercent={syncPercent}
                  documentSource={documentSource}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Classes
                  showClasses={showClasses}
                  businessId={businessId}
                  syncPercent={syncPercent}
                  documentSource={documentSource}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Category
                  businessId={businessId}
                  syncPercent={syncPercent}
                  documentSource={documentSource}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Products
                  businessId={businessId}
                  syncPercent={syncPercent}
                  documentSource={documentSource}
                />
              </TabPanel>
            </Box>
          </Card>
          <MDBox></MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default UserHoc(VendorSettings);