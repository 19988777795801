import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddNewLocationApi } from "../../../../services/clientservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";
import { addLocationschema } from "./schemas/addLocationschema";

function AddInventoryLocation({ onClose, invoiceId, restaurantsId }) {
    const [restaurantId, setRestaurantId] = useState(location.pathname.split("/").slice(-2)[0]);
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let initialValues = {
        location: "",
    };

    const [{ isLoading: isAddLocationLoading, isError: isAddLocationError, response: AddLocationResponse }, AddLocationListAPICall] = useAPICall(AddNewLocationApi, { onLoad: false });

    useEffect(() => {
        if (isAddLocationLoading) {
            return () => null;
        }
        if (isAddLocationError) {
            setErrorMessage(AddLocationResponse?.data?.message);
            setLoading(false);
            setBtnDisable(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            return () => null;
        }
        if (AddLocationResponse && AddLocationResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewLocationComplete", AddLocationResponse?.data?.data);
            onClose();
        }
    }, [AddLocationResponse]);

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await AddLocationListAPICall({
            payload: [
                {
                    "restaurantId": restaurantId ? restaurantId : restaurantsId,
                    "name": values?.location
                }]
        })
    };

    return (
        <>{
            <>
                <MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={addLocationschema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={3} pl={'30%'}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={9}>
                                                            <FormField
                                                                type="text"
                                                                label="Location *"
                                                                name="location"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "Submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed."
                        content={errorMessage}
                        open={showErrorSnackbar}
                        close={() => setShowErrorSnackbar(false)}
                    />
                </MDBox>
            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default AddInventoryLocation;