import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import "layouts/accountants/user/invoice/styles.css";
import PropTypes from "prop-types";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EditRapidInvenory, GetInventoryList, UpdateRestaurantOrderList, } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import "./styles.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function RapidInventoryList({ businessId, restaurantId, locationId }) {
  const resId = location.pathname.split("/").slice(-2)[0];
  const gridRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [search, setSearch] = useState("");
  const [rowManage, setRowManage] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [updateinventoryId, setUpdateinventoryId] = useState([]);
  const [moveStartIndex, setMoveStartIndex] = useState();
  const [moveStopIndex, setMoveStopIndex] = useState();
  const [inventoryList, setInventoryList] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInventoryList, { onLoad: false });
  const [ { isLoading: isEditRapidLoading, isError: isEditRapidError, response: EditRapidResponse }, EditRapidListAPICall ] = useAPICall(EditRapidInvenory, { onLoad: false });
  const [ { isLoading: isUpdateListOrderLoading, isError: isUpdateListOrderError, response: UpdateListOrderResponse }, UpdateListOrderListAPICall ] = useAPICall(UpdateRestaurantOrderList, { onLoad: false });
  const [inventoryIDsArray, setInventoryIDsArray] = useState([]);
  const uniqInventoryIds = [...new Set(inventoryIDsArray)];

  useEffect(async () => {
    const reorder = (array, sourceIndex, destinationIndex) => {
      const smallerIndex = Math.min(sourceIndex, destinationIndex);
      const largerIndex = Math.max(sourceIndex, destinationIndex);
      return [
        ...array.slice(0, smallerIndex),
        ...(sourceIndex < destinationIndex ? array.slice(smallerIndex + 1, largerIndex + 1) : []),
        array[sourceIndex],
        ...(sourceIndex > destinationIndex ? array.slice(smallerIndex, largerIndex) : []),
        ...array.slice(largerIndex + 1),
      ];
    };
    var finalArr = reorder(uniqInventoryIds, moveStartIndex, moveStopIndex);
    await UpdateListOrderListAPICall({
      payload: [
        { orderedlist: finalArr, restaurantId:restaurantId ? restaurantId : resId, locationId: locationId },
      ],
    });
  }, [moveStopIndex, moveStartIndex]);

  useEffect(() => {
    eventBus.$emit("updateinventoryId", [...new Set(updateinventoryId)]);
  }, [updateinventoryId]);

  useEffect(() => {
    if (totalRecords > 5) {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
  }, [response, totalRecords]);

  useEffect(() => {
    if (isUpdateListOrderLoading) {
      return () => null;
    }
    if (isUpdateListOrderError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (UpdateListOrderResponse && UpdateListOrderResponse.data.isSuccess === true) {
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [UpdateListOrderResponse]);

  useEffect(async () => {
    setLoadingCircul(true);
    await setAPICall({
      payload: [
        {
          keyword: `${search}`,
          restaurantId: restaurantId ? restaurantId :resId ,
          locationId: locationId,
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (isEditRapidLoading) {
      return () => null;
    }
    if (isEditRapidError) {
      setLoadingCircul(false);
      setLoading(false);
      eventBus.$emit("startUpdateLoading", false);
      return () => null;
    }
    if (EditRapidResponse && EditRapidResponse.data.isSuccess === true) {
      setUpdateinventoryId([
        ...updateinventoryId,
        `${EditRapidResponse?.data?.data?.inventory?._id}`,
      ]);
      eventBus.$emit("startUpdateLoading", false);
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [EditRapidResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      const data = response?.data?.data?.records.map((prop, key) => {
        setInventoryIDsArray((current) => [...current, prop?._id]);
        setTotalRecords(response?.data?.data?.totalRecords);
        return {
          id: prop?._id,
          productId: prop?.productId,
          inventoryUnitIds: prop?.unit?._id,
          locationId: prop?.location?._id,
          vendorId: prop?.vendor?._id,
          restaurantId: prop?.restaurant?._id,
          itemName: prop?.name ? prop?.name : "-",
          currentInventory: prop?.currentInventory ? prop?.currentInventory : "-",
          updateInventory: prop?.currentInventory ? prop?.currentInventory : "-",
          inventoryUnits: prop?.unit?.name ? prop?.unit?.name : "-",
          lowStockFlag: prop?.lowStockFlag ? prop?.lowStockFlag : "-",
          parFlag: prop?.parFlag ? prop?.parFlag : "-",
          location: prop?.location?.name ? prop?.location?.name : "-",
          estimateddaysofSupply: prop?.estimatedSupplyDate ? prop?.estimatedSupplyDate : "-",
          lastPurchaseDate: prop?.lastPurchaseDate ? prop?.lastPurchaseDate : "-",
        };
      });
      setRowData(data);
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [response]);

  let timeoutFilter = null;
  var nietos = [];
  var obj = {};

  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join("&");
  }

  const SliderFloatingFilter = (props, ref) => {
    const [currentValue, setCurrentValue] = useState("");

    const valueChanged = (event) => {
      clearTimeout(timeoutFilter);
      setCurrentValue(event.target.value);
      if (props?.filterName == "keyword") {
        obj["keyword"] = event.target.value.trim();
      } else if (props?.filterName == "currentInventory") {
        setLoadingCircul(true);
        obj["currentInventory"] = event.target.value.trim();
      }

      if (props?.filterName == "keyword" || props?.filterName == "currentInventory") {
        nietos.push(obj);
        let finalQuery = objectToQueryString(...new Set(nietos));
        setLoadingCircul(true);
        timeoutFilter = setTimeout(function () {
          setAPICall({
            payload: [
              {
                restaurantId: restaurantId ? restaurantId : resId ,
                locationId: locationId,
                finalQuery: finalQuery,
              },
            ],
          });
        }, 1000);
      }
    };
    return (
      <input
        type="text"
        value={currentValue}
        onChange={() => valueChanged(event)}
        style={{
          width: "100%",
          padding: "6px",
          minHeight: "28px",
          fontSize: "inherit",
          lineHeight: "inherit",
          borderRadius: "3px",
          color: "inherit",
          border: `0.5px solid #babfc7`,
        }}
      />
    );
  };

  const setNewCurrentInventory = useCallback((value, changeValue) => {
    // const rowNode = gridRef.current.api.getRowNode(`${value}`);
    // rowNode.setDataValue("currentInventory", `${changeValue}`);
  }, []);

  eventBus.$on("saveAndClose", (data) => saveAndClose(data));
  function saveAndClose(data) {
    let uniqueObjArray = [
      ...new Map(inventoryList.map((item) => [item["productId"], item])).values(),
    ];

    EditRapidListAPICall({
      payload: [{ updatables: uniqueObjArray, restaurantId: restaurantId ? restaurantId : resId}],
    });
  }

  var gridOptions = {
    onCellKeyDown: (params) => {
      setNewCurrentInventory(
        params?.data?.id,
        params?.event?.target?.value !== undefined
          ? params?.event?.target?.value
          : params.data.updateInventory
      );
      setInventoryList((current) => [
        ...current,
        {
          productId: params?.data?.productId,
          locationName: params?.data?.location,
          currentInventory:
            params?.event?.target?.value !== undefined
              ? Number(params?.event?.target?.value)
              : Number(params.data.updateInventory),
        },
      ]);
    },
  };

  let timeout = null;

  const TextFieldEditor = (props) => {
    const [value, setValue] = useState(props.value);

    const handleValueChange = (event) => {
      setValue(event.target.value);
    };

    const handleBlur = () => {
      // Save the changes when the TextField loses focus
      // props.api.stopEditing();
      // props.api.setValue(value);
    };

    return (
      <TextField
        value={value}
        onChange={handleValueChange}
        onBlur={handleBlur}
        fullWidth
        inputProps={{
          inputProps: { tabIndex: -1 },
          style: {
            textAlign: "center",
            color: darkMode ? "#ffffff" : "#000000", // White text in dark mode
          },
        }}
        style={{
          width: 120,
          backgroundColor: darkMode ? "#1a2035" : "#ffffff", // Dark mode background color
          border: `1px solid ${darkMode ? "#344767" : "#cccccc"}`, // Border color based on mode
          borderRadius: 4, // Optional rounded corners
        }}
        variant="standard"
      />
    );
  };

  function suppressNavigation(params) {
    var KEY_TAB = "Tab";

    var event = params.event;
    var key = event.key;

    var keysToSuppress = [KEY_TAB];

    var suppress = keysToSuppress.some(function (suppressedKey) {
      return suppressedKey === key || key.toUpperCase() === suppressedKey;
    });

    return suppress;
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "itemName",
      rowDrag: true,
      headerName: "ITEM NAME",
      suppressMovable: true,
      sortable: false,
      editable: false,
      suppressKeyboardEvent: suppressNavigation,
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "keyword",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "currentInventory",
      headerName: "CURRENT INVENTORY",
      suppressMovable: true,
      width: 170,
      sortable: false,
      editable: false,
      suppressKeyboardEvent: suppressNavigation,
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "currentInventory",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "updateInventory",
      headerName: "UPDATE INVENTORY",
      filter: false,
      editable: true,
      suppressMovable: true,
      cellRenderer: TextFieldEditor,
      width: 170,
      sortable: false,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: "#7b809a",
      }),
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      suppressCellFlash: true,
      flex: 1,
      minWidth: 100,
      resizable: true,
      width: 200,
      sortable: true, // Enable sorting by default
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    };
  }, []);

  const rowStyle = { background: "transparent" };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "transparent" };
    }
  };

  const onRowDragEnter = useCallback((e) => {
    setRowManage(true);
    setMoveStartIndex(e?.overIndex);
  }, []);

  const onRowDragEnd = useCallback((e) => {
    setRowManage(false);
    setMoveStopIndex(e?.overIndex);
  }, []);

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  return (
    <>
      <MDBox my={2} mx={2}>
        <MDBox sx={{ mt: 1, mb: -1 }}>
          <div
            className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
            style={{ height: 400, width: "auto" }} >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowDragManaged={rowManage}
              rowStyle={rowStyle}
              rowHeight={55}
              suppressMoveWhenRowDragging={true}
              getRowStyle={getRowStyle}
              animateRows={true}
              pagination={showPagination}
              paginationPageSize={10}
              rowDragMultiRow={true}
              onRowDragEnter={onRowDragEnter}
              onRowDragEnd={onRowDragEnd}
              enableCellChangeFlash={true}
              singleClickEdit={true}
            />
          </div>
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          {loading && <Loader />}
        </MDBox>
      </MDBox>
    </>
  );
}

export default UserHoc(RapidInventoryList);