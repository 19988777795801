import { TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { Field, Form, Formik } from "formik";
import AddNewVendor from "layouts/accountants/user/invoice/components/schemas/AddNewVendor";
import AddNewVendorWithoutAddress from "layouts/accountants/user/invoice/components/schemas/AddNewVendorWithoutAddress";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { EditVendors, GetVendorDetails } from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";

function EditVendor({ onClose, vendorId, businessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailId, setEmailId] = useState("");
  const [netTerms, setNetTerms] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [showselectedrowsData, setShowSelectedrowsData] = useState("");
  const [billRate, setBillRate] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [vendor1099, setVendor1099] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [trackPayment, setTrackPayment] = useState(false);
  const [city, setCity] = useState("");
  const [state, setstate] = useState("");
  const [zip, setzip] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{isLoading: isEditVendorLoading, isError: isEditVendorError, response: EditVendorResponse },EditVendorListAPICall] = useAPICall(EditVendors, { onLoad: false });
  const [{isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse },VendorDetailsAPICall] = useAPICall(GetVendorDetails, { onLoad: false });

  useEffect(async () => {
    await VendorDetailsAPICall({
      payload: [{ VendorId: `${vendorId}` }],
    });
    setLoading(true);
  }, []);

  useEffect(() => {
    if (isVendorLoading) {
      return () => null;
    }
    if (isVendorError) {
      setLoading(false);
      return () => null;
    }
    if (VendorResponse && VendorResponse.data.isSuccess === true) {
      const VendorDataResponse = VendorResponse?.data?.data?.vendor;
      setShowSelectedrowsData(VendorDataResponse?.name ? VendorDataResponse?.name : VendorDataResponse?.legalName);
      setVendorName(VendorDataResponse?.name !== "---" ? VendorDataResponse?.name : "");
      setPhone(VendorDataResponse?.phone !== "---" ? VendorDataResponse?.phone : "");
      setAddress(VendorDataResponse?.billingAddress?.line1 !== "---" ? VendorDataResponse?.billingAddress?.line1 : "");
      setCity(VendorDataResponse?.billingAddress?.city !== "---" ? VendorDataResponse?.billingAddress?.city : "");
      setstate(VendorDataResponse?.billingAddress?.state !== "---" ? VendorDataResponse?.billingAddress?.state : "");
      setzip(VendorDataResponse?.billingAddress?.zip !== "---" ? VendorDataResponse?.billingAddress?.zip : "");
      setAccountNo(VendorDataResponse?.accountNo !== "---" ? VendorDataResponse?.accountNo : "");
      setEmailId(VendorDataResponse?.email !== "---" ? VendorDataResponse?.email : "");
      setPhone(VendorDataResponse?.phone !== "---" ? VendorDataResponse?.phone : "");
      setNetTerms(VendorDataResponse?.balance);
      setVendor1099(VendorDataResponse?.vendor1099);
      setBusinessNumber(VendorDataResponse?.businessNumber);
      setBillRate(VendorDataResponse?.billRate);
      setTrackPayment(VendorDataResponse?.vendor1099);
      setShowDetails(true);
    }
  }, [VendorResponse]);

  useEffect(() => {
    if (isEditVendorLoading) {
      return () => null;
    }
    if (isEditVendorError) {
      eventBus.$emit("editVendorFailed", EditVendorResponse?.data);
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditVendorResponse && EditVendorResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      onClose();
      eventBus.$emit("reloadDetailsList", EditVendorResponse);
    }
  }, [EditVendorResponse]);

  let initialValues = {
    vendor: vendorName,
    accountno: accountNo,
    address: address,
    email: emailId,
    phone: phone,
    businessid: "",
    net: netTerms,
    checkbox: trackPayment,
    vendor1099: vendor1099,
    businessNumber: businessNumber,
    billRate: billRate,
    account: "",
    city: city,
    state: state,
    zip: zip,
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [showselectedrowsData, address]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditVendorListAPICall({
      payload: [
        {
          name: values.vendor,
          email: values.email,
          phone: phone,
          businessNumber: values?.businessNumber,
          billRate: Number(values?.billRate),
          vendor1099: vendor1099,
          accountNo: values.accountno,
          vendor1099: trackPayment,
          billingAddress: {
            line1: values?.address,
            city: values?.city,
            state: values?.state,
            zip: values?.zip,
          },
          VendorId: `${vendorId}`,
        },
      ],
    });
  };
  
  const handleChange = () => {
    setTrackPayment(!trackPayment);
  };

  const handleChangeVendor = () => {
    setVendor1099(!vendor1099);
  };

  useEffect(() => {
    if (address) {
      setTrackPayment(true);
    }
  }, [address]);
  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={trackPayment ? AddNewVendor : AddNewVendorWithoutAddress}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form autoComplete="off">
                      <MDBox p={1}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Vendor *" name="vendor" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Account Number" name="accountno" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <NumberFormat
                                  customInput={TextField}
                                  variant="standard"
                                  type="tel"
                                  fullWidth
                                  value={phone}
                                  InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                                  InputProps={{ style: darkMode ? { color: "#ffffff",borderBottom:"2px solid" } : {} }}
                                  label="Phone Number"
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    mb: 1,
                                    fontSize: `0.823rem !important`,
                                  }}
                                  format="(###) ###-####"
                                  mask=""
                                  onValueChange={(value) => setPhone(value.formattedValue)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} mt={-0.5}>
                                <FormField type="email" label="Email" name="email" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField
                                  type="text"
                                  label="Business ID No./Social Security No."
                                  name="businessNumber"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDBox>
                                  <Field
                                    as={FormControlLabel}
                                    type="checkbox"
                                    name="vendor1099"
                                    control={<Checkbox />}
                                    label={
                                      <Typography
                                        variant="secendory"
                                        color={darkMode ? "#ffffff" : "#7b809a"}
                                      >
                                        Track payments for 1099
                                      </Typography>
                                    }
                                    checked={vendor1099}
                                    onChange={handleChangeVendor}
                                    sx={{ mt: 1.5, ml: "-13px" }}
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <MDBox>
                                  <Field
                                    as={FormControlLabel}
                                    type="checkbox"
                                    name="checkbox"
                                    control={<Checkbox />}
                                    label="Billing Address"
                                    checked={trackPayment}
                                    onChange={handleChange}
                                    sx={{
                                      mt: 1.5,
                                      "& .MuiTypography-root": {
                                        color: darkMode ? "#ffffff" : "#333333", // Label color
                                      },
                                    }}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} sm={6}></Grid>
                            </Grid>
                            {trackPayment && (
                              <>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="Address *" name="address" />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="City *" name="city" />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="State *" name="state" />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="Zip *" name="zip" />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </MDBox>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default EditVendor;