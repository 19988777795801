import { Autocomplete, FormControl, Icon, Paper, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { CheckProductExist, GetProductList ,GetItemCategoryList } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import * as Yup from "yup";
import AddInventoryDialog from "./AddInventoryDialog";
import EditInventoryDialog from "./editInventory";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";

function CheckItemExistDialog({ clientId, restaurantsId, onClose, editInventory }) {
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(CheckProductExist, {onLoad: false});
  const [{isLoading: isProductListLoading, isError: isProductListError, response: ProductListResponse },ProductListListAPICall] = useAPICall(GetProductList, { onLoad: false });
  const [{isLoading: isCategoryListLoading, isError: isCategoryListError, response: CategoryListResponse },CategoryListListAPICall] = useAPICall(GetItemCategoryList, { onLoad: false });
  const [productId, setProductId] = useState("");
  const [openEditInventory, setOpenEditInventory] = useState(false);
  const [openAddInventory, setOpenAddInventory] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [productName, setProductName] = useState("");
  const [newProductName, setNewProductName] = useState("");
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectProductName, setSelectProductName] = useState("");
  const [selectCategoryName, setSelectCategoryName] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const [disableSelect, setDisableSelect] = useState(true);
  const [categoryerrormsg , setCategoryErrorMessage] = useState("");
  const [categoryerror ,setCategoryError] = useState(false);
  const [itemerrormsg ,setItemErrorMessage] = useState("");
  const [itemerror ,setItemError] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const initialValues = {
    itemName: "",
    categoryName : "",
  };

  useEffect(async () => {
    
    if(clientId){
      setDisableSelect(true);
      setLoadingCircul(true);
      await ProductListListAPICall({
        payload: [{ clientId: clientId, keyword: search }],
      });
      await CategoryListListAPICall({
        payload: [],
      });
    }
  }, []);

  useEffect(() => {
    if (isProductListLoading) {
      return () => null;
    }
    if (isProductListError) {
      setDisableSelect(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (ProductListResponse && ProductListResponse.data.isSuccess === true) {
      const data = ProductListResponse?.data?.data.map((prop, key) => {
        return {
          name: prop?.name ? prop?.name : "-",
          id: prop?._id ? prop?._id : "-",
        };
      });
      setProductList(data);
      setLoadingCircul(false);
      setDisableSelect(false);
    }
  }, [ProductListResponse]);

  useEffect(() => {
    if (isCategoryListLoading) {
      return () => null;
    }
    if (isCategoryListError) {
      setDisableSelect(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (CategoryListResponse && CategoryListResponse.data.isSuccess === true) {
      const data = CategoryListResponse?.data?.data?.records.map((prop, key) => {
        return {
          name: prop?.name ? prop?.name : "-",
          id: prop?._id ? prop?._id : "-",
        };
      });
      setCategoryList(data);
      setLoadingCircul(false);
      setDisableSelect(false);
    }
  }, [CategoryListResponse]);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setShowSubmit(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSubmit(false);
      setProductId(response?.data?.data?.product?._id);
      setBusinessId(response?.data?.data?.product?.business?._id);
      setLoadingCircul(false);
      if (response?.data?.data?.product == null) {
        setOpenAddInventory(true);
      } else {
        eventBus.$emit("editInventoryFound", response);
      }
    }
  }, [response]);

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the first render
      isInitialRender.current = false;
      return;
    }
  
    // Logic for changes in selectCategoryName or selectProductName
    if (selectCategoryName) {
      setCategoryErrorMessage("");
      setCategoryError(false);
    } else {
      setCategoryErrorMessage("Income Account name required");
      setCategoryError(true);
    }
  
    if (selectProductName) {
      setItemErrorMessage("");
      setItemError(false);
    } else {
      setItemErrorMessage("Income Account name required");
      setItemError(true);
    }
  }, [selectCategoryName, selectProductName]);

  const handleSubmit = async (values) => {
    if (selectCategoryName) {
      setCategoryErrorMessage("");
      setCategoryError(false);
    } else {
      setCategoryErrorMessage("Income Account name required");
      setCategoryError(true);
    }

    if (selectProductName) {
      setItemErrorMessage("");
      setItemError(false);
    } else {
      setItemErrorMessage("Income Account name required");
      setItemError(true);
    }
    if (selectProductName && selectCategoryName) {
      setShowSubmit(true);
      setNewProductName(selectProductName);
      setProductName(selectProductName);
      await setAPICall({
        payload: [
          {
            restaurantId:  restaurantsId ?  restaurantsId : location.pathname.split("/").slice(-2)[0],
            productName: selectProductName,
            categoryName: selectCategoryName
          },
        ],
      });
    }
  };

  const handleAddProduct = (newProduct) => {
    if (newProduct) {
      setProductList((prev) => [...prev, { name: newProduct }]); // Add the new unit to the list
    }
  };

  const handleAddCategory = (newCategory) => {
    if (newCategory) {
      setCategoryList((prev) => [...prev, { name: newCategory }]); // Add the new unit to the list
    }
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={11}  >
                      <FormControl variant="standard" fullWidth sx={{ m: 2 }}>
                        <Autocomplete
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "black",
                            },
                            ...(darkMode && {
                              "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root": {
                                color: "#FFFFFF",
                              },
                              "& .MuiInput-root .MuiInput-input": {
                                WebkitTextFillColor: "white", 
                              },
                              "& .MuiFormLabel-root-MuiInputLabel-root": {
                                color: "white",
                              },
                              "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                  color: "white !important",
                                }
                              }
                            })
                          }}
                          disabled={disableSelect}
                          id="combo-box-demo"
                          noOptionsText={
                            <MDTypography
                              sx={{ fontSize: "0.775rem", cursor: "pointer", color: "#1a2035" }}
                              onClick={() => handleAddProduct(selectProductName)}
                            >
                              {selectProductName} (New Item)
                            </MDTypography>
                          }
                          ListboxProps={{ style: { maxHeight: "6.5rem" }, position: "bottom-start" }}
                          value={{ name: selectProductName }}
                          options={productList}
                          getOptionLabel={(option) => option.name}
                          fullWidth
                          onInputChange={(event, newValue) => {
                            setSelectProductName(newValue);
                          }}
                          // onChange={(event, newValue) => {
                          //   setProductName(newValue);
                          //   handleSubmit(newValue);
                          // }}
                          PaperComponent={(props) => (
                            <Paper
                              {...props}
                              sx={{ backgroundColor: darkMode ? "#1a2035" : "#ffffff" }}
                            />
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Name *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? "#ffffff" : "#000000" },
                              }}
                              helperText={itemerrormsg}
                              FormHelperTextProps={{
                                sx: { color: "red" }, // Set helper text color to red
                              }}
                              sx={{
                                "& .MuiFormLabel-root": {
                                  marginTop: "5px",
                                },
                              }}
                            />
                          )}
                        />
                    </FormControl>
                  </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={11}>
                      <FormControl variant="standard" fullWidth sx={{ m: 2, mb: -2 }}>
                        <Autocomplete
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "black",
                            },
                            ...(darkMode && {
                              "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root": {
                                color: "#FFFFFF",
                              },
                              "& .MuiInput-root .MuiInput-input": {
                                WebkitTextFillColor: "white", 
                              },
                              "& .MuiFormLabel-root-MuiInputLabel-root": {
                                color: "white",
                              },
                              "& .MuiIconButton-root.MuiIconButton-sizeMedium": {
                                "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator": {
                                  color: "white !important",
                                }
                              }
                            })
                          }}
                          disabled={disableSelect}
                          id="combo-box-demo"
                          noOptionsText={
                            <MDTypography
                              sx={{ fontSize: "0.775rem", cursor: "pointer", color: "#1a2035" }}
                              onClick={() => handleAddCategory(selectCategoryName)}
                            >
                              {selectCategoryName} (New Item)
                            </MDTypography>
                          }
                          ListboxProps={{ style: { maxHeight: "6.5rem" }, position: "bottom-start" }}
                          value={{ name: selectCategoryName }}
                          options={categoryList}
                          getOptionLabel={(option) => option.name}
                          fullWidth
                          onInputChange={(event, newValue) => {
                            setSelectCategoryName(newValue);
                          }}
                          // onChange={(event, newValue) => {
                          //   setProductName(newValue);
                          //   handleSubmit(newValue);
                          // }}
                          PaperComponent={(props) => (
                            <Paper
                              {...props}
                              sx={{ backgroundColor: darkMode ? "#1a2035" : "#ffffff" }}
                            />
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? "#ffffff" : "#000000" },
                              }}
                              FormHelperTextProps={{
                                sx: { color: "red" }, // Set helper text color to red
                              }}
                              helperText={categoryerrormsg}
                              // error={categoryerror}
                              sx={{
                                "& .MuiFormLabel-root": {
                                  marginTop: "5px",
                                },
                              }}
                            />
                          )}
                        />
                    </FormControl>  
                    </Grid>
                  </Grid>
                  <MDBox display="flex" justifyContent="end" mt={8}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      >
                      {showSubmit === true ? "Submitting..." : "Submit"}
                    </MDButton>
                  </MDBox>
                  </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
      <MDBox>
        <Dialog
          open={openAddInventory}
          onClose={() => setOpenAddInventory(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "570px",
                minHeight: "40%",
                backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                color: darkMode ? "#ffffff" : "#000000",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
              color: darkMode ? "#ffffff" : "#000000",
            }}
          >
            Add Item
          </DialogTitle>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "end",
              display: "flex",
              mt: -7.5,
            }}
          >
            <Icon
              sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#000000" }}
              onClick={onClose}
            >
              close
            </Icon>
          </DialogTitle>
          <DialogContent>
            <AddInventoryDialog
              newProductName={newProductName}
              newCategoryName={selectCategoryName}
              onClose={onClose}
              businessId={businessId}
              restaurantsId={restaurantsId ? restaurantsId : location.pathname.split("/").slice(-2)[0]}
            />
          </DialogContent>
        </Dialog>
      </MDBox>
      <MDBox>
        <Dialog
          open={openEditInventory}
          onClose={() => setOpenEditInventory(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                // height: "50%",
                maxWidth: "570px",
                minHeight: "40%",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            Edit Item
          </DialogTitle>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "end",
              display: "flex",
              mt: -7.5,
            }}
          >
            <Icon sx={{ cursor: "pointer" }} onClick={onClose}>
              close
            </Icon>
          </DialogTitle>
          <DialogContent>
            <EditInventoryDialog
              productName={productName}
              businessId={businessId}
              productId={productId}
              restaurantsId={restaurantsId}
              onClose={onClose}
            />
          </DialogContent>
        </Dialog>
      </MDBox>
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default CheckItemExistDialog;
