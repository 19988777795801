import CircleIcon from "@mui/icons-material/Circle";
import { FormControl, Icon } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik ,Field } from "formik";
import CurrencyFieldText from "layouts/accountants/user/components/CurrencyField-Text";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CheckProductExist, DeleteInventory, EditInvenory,GetItemCategoryList , GetinventoryHistory, GetInventoryUnitList, GetRestaurantLocationList} from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import Tooltip from "@mui/material/Tooltip"
import * as Yup from "yup";
import { default as AddCurrontInvetory, default as AddNewInventoryUnitDialog } from "./component/addInventoryUnit";
import AddInventoryLocation from "./component/addLocation";
import AddVendorDialog from "./component/AddVendorDialog";
import "./styles.css";

function EditInventoryDialog({ inventoryId, productName, businessId, productId, restaurantsId, onClose, locationListA}) {
  const restaurantsNewId = localStorage.getItem("restaurantsNewId");
  const gridRef = useRef(null);
  let inventoriesArray = [];
  const [restaurantId, setRestaurantId] = useState(restaurantsId !== 'all' ? restaurantsId : restaurantsNewId);
  const [editTableHeight, setEditTableHeight] = useState(90);
  const [product_id, setProduct_id] = useState("");
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [inventoryUnitList, setInventoryUnitList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [inventoryHistoryList, setinventoryHistoryList] = useState([]);
  const [openAddNewinventory, setOpenAddNewinventory] = useState(false);
  const [openAddNewLocation, setOpenAddNewLocation] = useState(false);
  const [editable, seteditable] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [addinventory, setAddinventory] = useState(false);
  const [locationNewValue, setLocationNewValue] = useState("");
  const [VendorsNewValue, setVendorsNewValue] = useState("");
  const [inventoryUnitError, setInventoryUnitError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [unitPriceError, setUnitPriceError] = useState(false);
  const [unitNewValue, setunitNewValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [currentInventory, setCurrentInventory] = useState("");
  const [totalInventoryCost, setTotalInventoryCost] = useState(0);
  const [lowStockFlag, setLowStockFlag] = useState("");
  const [parflag, setParflag] = useState("");
  const [itemName, setItemName] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [rowData1, setRowData1] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [deleteInventoryDialog, setDeleteInventoryDialog] = useState(0);
  const [price, setPrice] = useState(0);
  const [removeFromArray, setRemoveFromArray] = useState([]);
  const [totalInventory, setTotalInventory] = useState("");
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const rowStyle = { background: "transparent" };
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditInvenory, {onLoad: false});
  const [{isLoading: isInventoryUnitLoading,isError: isInventoryUnitError,response: InventoryUnitResponse}, InventoryUnitListAPICall] = useAPICall(GetInventoryUnitList, { onLoad: false });
  const [{isLoading: isLocationLoading, isError: isLocationError, response: LocationResponse },LocationListAPICall] = useAPICall(GetRestaurantLocationList, { onLoad: false });
  const [{isLoading: isgetInventoryLoading,isError: isgetInventoryError,response: getInventoryResponse},getInventoryDetailAPICall] = useAPICall(CheckProductExist, { onLoad: false });
  const [{isLoading: isGetinventoryHistoryLoading,isError: isGetinventoryHistoryError,response: GetinventoryHistoryResponse},GetinventoryHistoryListAPICall] = useAPICall(GetinventoryHistory, { onLoad: false });
  const [{isLoading: isdeleteInventoryLoading,isError: isdeleteInventoryError,response: deleteInventoryResponse},deleteInventoryAPICall] = useAPICall(DeleteInventory, { onLoad: false });
  const [{isLoading: isCategoryLoading,isError: isCategoryError,response: CategoryResponse},CategoryListAPICall] = useAPICall(GetItemCategoryList, { onLoad: false });

  useEffect(() => {
    if (isdeleteInventoryLoading) {
      return () => null;
    }

    if (isdeleteInventoryError) {
      setDeleteInventoryDialog(false);
      eventBus.$emit("deleteItemFailed", isdeleteInventoryError);

      setLoadingCircul(false);
      return () => null;
    }

    if (deleteInventoryResponse && deleteInventoryResponse.data.isSuccess === true) {
      setDeleteInventoryDialog(false);
      eventBus.$emit("deleteItem", deleteInventoryResponse);
      setLoadingCircul(false);
    }
  }, [deleteInventoryResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          CategoryId: prop?._id,
          clientDomainId: prop?.clientDomainId,
        };
      });
      setCategoryList(data);
      setLoadingCircul(false);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isGetinventoryHistoryLoading) {
      return () => null;
    }

    if (isGetinventoryHistoryError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (GetinventoryHistoryResponse && GetinventoryHistoryResponse.data.isSuccess === true) {
      const data = GetinventoryHistoryResponse?.data?.data?.records.map((prop, key) => {
        return {
          message: prop?.message,
        };
      });
      setinventoryHistoryList(data);
    }
  }, [GetinventoryHistoryResponse]);

  useEffect(()=>{
    setTotalInventoryCost(`$${totalInventory * price}`);
  },[totalInventory , price])

  useEffect(() => {
    localStorage.setItem("RowData", JSON.stringify(rowData));
  }, [rowData]);

  const setNewLocation = useCallback((value, changeValue) => {
    const valuess = value - 1;
    const rowNode = gridRef.current.api.getRowNode(`${valuess}`);
    rowNode?.setDataValue("location", `${changeValue}`);
    let Arr = JSON.parse(localStorage.getItem("RowData"));
    const index = Arr.findIndex((obj) => obj.id === value);
    if (index !== -1) {
      let newArray = [...Arr];
      newArray[index] = rowNode?.data;
      localStorage.setItem("RowData", JSON.stringify(newArray));
    }
  }, []);

  function actionCellRendererEdit(params) {
    const counts = params.api.getDisplayedRowCount();
  
    // Calculate row height based on count, defaulting to 60 for counts greater than 3
    const rowHeight = counts <= 3 ? 48 - counts * 2 : 60;
  
    // Set the table height, ensuring it doesn't exceed 210
    setEditTableHeight(Math.min((counts + 1) * rowHeight, 210));
    return (
      <MDBox display="flex" sx={{ color: darkMode ? "#ffffff" : "#344767", mt: "4px !important" }}>
        {params?.data?.location}
      </MDBox>
    );
  }

  function actionCurrentInventoryCellRendererEdit(params) {
    return (
      <MDBox display="flex" sx={{ color: darkMode ? "#ffffff" : "#344767", mt: "4px !important" }}>
        {params?.data?.currentInventory}
      </MDBox>
    );
  }

  function actionAddDeleteCellRendererEdit(params) {
    var count = params.api.getDisplayedRowCount();
    return (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ paddingTop: "0.6rem" }}
      ></MDBox>
    );
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  function actionCellRenderer(params) {
    const [value, setValue] = useState(params?.value);
    let counts = params.api.getDisplayedRowCount();
    const rowHeight = counts <= 3 ? 48 - counts * 2 : 60;
    setEditTableHeight(Math.min((counts + 1) * rowHeight, 210));
    const handleValueChange = (event, newValue) => {
      setValue(newValue);
      setNewLocation(params?.data?.id, newValue);
    };

    return (
      <FormControl variant="standard" fullWidth>
        <Autocomplete
          forcePopupIcon={false}
          sx={{
            width: 150,
            mb: -3.5,
            ml: 2,
            ...(darkMode && {
              "& .MuiInput-underline": {
                "&.Mui-disabled:before": {
                  borderBottom: "1px solid #949494"
                },
                borderBottom: "2px solid #ffffff",
                "&:before": {
                  borderBottomColor: "white"
                }
              },
              "& .MuiInputBase-input.Mui-disabled, & .MuiInput-root .MuiInput-input": {
                WebkitTextFillColor: "white"
              },
              "& .MuiFormLabel-root": {
                color: "white !important" 
              },
              "& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator": {
                color: "white !important"
              }
            })
          }}
          ListboxProps={{ style: { maxHeight: "6.5rem" } }}
          disableClearable
          noOptionsText={
            <MDTypography
              sx={{
                fontSize: "0.775rem",
                cursor: "pointer",
                ...(darkMode && { color: "#344767" })
              }}
            >
              {value} (New Location)
            </MDTypography>
          }
          value={{ name: value }}
          options={locationListA ? locationListA : locationList}
          getOptionLabel={(option) => option.name}
          onKeyDown={handleKeyDown}
          onInputChange={(event, newInputValue) => {
            handleValueChange(event, newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem !important",
                  fontWeight: 400,
                  color: "#344767 !important",
                  textAlign: "center",
                },
              }}
              variant="standard"
            />
          )}
        />
      </FormControl>
    );
  }

  useEffect(() => {
    if (removeFromArray) {
      const ids = removeFromArray?.id;
      const newArr = rowData.filter((object) => {
        return object.id !== ids;
      });
      setRowData(newArr);
    }
  }, [removeFromArray]);

  const setDeleteCurrentInventory = useCallback((params) => {
    setRemoveFromArray(params.node.data);
    params.api.applyTransaction({
      remove: [params.node.data],
    });
  }, []);

  function actionAddDeleteCellRenderer(params) {
    var count = params.api.getDisplayedRowCount();
    return (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ paddingTop: "0.6rem" }}
      >
        {count !== 1 && params.node.rowIndex + 1 == count ? (
          <MDBox sx={{ ml: 1.9 }}>
            <Icon
              disabled={!editable}
              sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#344767" }}
              onClick={() => setDeleteCurrentInventory(params)}
            >
              delete
            </Icon>
            <Icon
              disabled={!editable}
              sx={{ mt: 2, cursor: "pointer", color: darkMode ? "#ffffff" : "#344767" }}
              onClick={() => addItems(count)}
            >
              add
            </Icon>
          </MDBox>
        ) : (
          <MDBox>
            {count !== 1 && (
              <Icon
                disabled={!editable}
                sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#344767" }}
                onClick={() => setDeleteCurrentInventory(params)}
              >
                delete
              </Icon>
            )}
            {params.node.rowIndex + 1 == count && (
              <Icon
                disabled={!editable}
                sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#344767" }}
                onClick={() => addItems(count)}
              >
                add
              </Icon>
            )}
          </MDBox>
        )}
      </MDBox>
    );
  }

  const setNewCurrentInventory = useCallback((value, changeValue) => {
    const valuess = value - 1;
    const rowNode = gridRef.current.api.getRowNode(`${valuess}`);
    rowNode?.setDataValue("currentInventory", `${changeValue}`);
    let Arr = JSON.parse(localStorage.getItem("RowData"));
    const index = Arr.findIndex((obj) => obj.id === value);
    if (index !== -1) {
      let newArray = [...Arr];
      newArray[index] = rowNode?.data;
      localStorage.setItem("RowData", JSON.stringify(newArray));
    }
  }, []);

  function actionCurrentInventoryCellRenderer(params) {
    return (
      <MDBox display="flex">
        <TextField
          margin="dense"
          id="name"
          onKeyDown={handleKeyDown}
          value={params?.data?.currentInventory}
          error={params?.data?.currentInventory == 0 ? true : false}
          // defaultValue={params?.data?.currentInventory}
          onChange={async (value) => {
            setNewCurrentInventory(params?.data?.id, value?.target?.value);
          }}
          label=""
          type="text"
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
              fontWeight: 400,
              color: darkMode ? "#ffffff" : "#344767",
              textAlign: "center",
            },
            "& .MuiInputLabel-root": {
              color: darkMode ? "#ffffff" : "#344767",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: darkMode ? "#ffffff" : "#344767",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: darkMode ? "#ffffff" : "#344767",
            },
          }}
          inputProps={{
            autoComplete: "off",
            fontSize: "0.875rem !important",
            fontWeight: 400,
            inputProps: { tabIndex: -1 },
            min: 0,
            style: { textAlign: "center" },
            // type: 'number'
          }}
          style={{ width: 120 }}
          variant="standard"
        />
      </MDBox>
    );
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: 220,
      lockPosition: "left",
      cellRenderer: actionCellRenderer,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "currentInventory",
      headerName: "CURRENT INVENTORY",
      lockPosition: "center",
      cellRenderer: actionCurrentInventoryCellRenderer,
      minWidth: 170,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      headerName: "orderNo",
      field: "orderNo",
      hide: true,
    },
    {
      headerName: "",
      lockPosition: "right",
      field: "action",
      cellRenderer: actionAddDeleteCellRenderer,
      minWidth: 20,
    },
  ]);

  const [columnDefs1, setColumnDefs1] = useState([
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: 220,
      lockPosition: "left",
      cellRenderer: actionCellRendererEdit,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "currentInventory",
      headerName: "CURRENT INVENTORY",
      lockPosition: "center",
      cellRenderer: actionCurrentInventoryCellRendererEdit,
      minWidth: 170,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      headerName: "orderNo",
      field: "orderNo",
      hide: true,
    },
    {
      headerName: "",
      lockPosition: "right",
      field: "action",
      cellRenderer: actionAddDeleteCellRendererEdit,
      minWidth: 20,
    },
  ]);

  useEffect(() => {
    if (isgetInventoryLoading) {
      return () => null;
    }
    if (isgetInventoryError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (getInventoryResponse && getInventoryResponse.data.isSuccess === true) {
      const InventoryDetail = getInventoryResponse?.data?.data?.product;
      setProduct_id(InventoryDetail?._id);
      setTotalInventory(InventoryDetail?.totalInventories);
      setItemName(InventoryDetail?.name);
      setPrice(InventoryDetail?.unitPrice);
      setCategoryValue(InventoryDetail?.category ? InventoryDetail?.category : "");
      setParflag(InventoryDetail?.parFlag);
      setunitNewValue(InventoryDetail?.unit);
      setLowStockFlag(InventoryDetail?.lowStockFlag);
      const data = InventoryDetail?.inventories.map((prop, key) => {
        return {
          id: key + 1,
          location: prop?.location ? prop?.location : "-",
          currentInventory: `${prop?.currentInventory}`,
          orderNo: prop?.orderNo ? prop?.orderNo : "-",
        };
      });
      setRowData(data);
      setRowData1(data);
      setVendorsNewValue(InventoryDetail?.vendor?.name ? InventoryDetail?.vendor?.name : "");
      setLocationNewValue(getInventoryResponse?.data?.data?.inventory?.location?.name);
      setLoadingCircul(false);
      setShowEditForm(true);
    }
  }, [getInventoryResponse]);

  const initialValues = {
    itemName: itemName,
    inventoryUnit: unitNewValue,
    currentInventory: currentInventory,
    totalInventoryCost: totalInventoryCost,
    lowStockFlag: lowStockFlag,
    parflag: parflag,
    unitPrice: price,
    location: locationNewValue,
    vendors: VendorsNewValue,
    totalInventory: totalInventory,
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "transparent" };
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      minWidth: 200,
      resizable: true,
      sortable: false,
      filter: false,
      floatingFilter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: false },
    };
  }, []);

  eventBus.$on("AddnewInventoryUnitSchemaComplete", (data) => AddnewInventoryUnitSchemaComplete(data));
  function AddnewInventoryUnitSchemaComplete(data) {
    setunitNewValue(data?.unit?.name);
    setLoadingCircul(false);
  }

  eventBus.$on("AddnewInventoryVendorComplete", (data) => AddnewInventoryVendorComplete(data));
  function AddnewInventoryVendorComplete(data) {
    setVendorsNewValue(data?.vendor?.name);
    setLoadingCircul(false);
  }

  eventBus.$on("AddnewLocationComplete", (data) => AddnewLocationComplete(data));
  function AddnewLocationComplete(data) {
    setLocationNewValue(data?.location?.name);
    setLoadingCircul(false);
  }

  useEffect(async () => {
    localStorage.setItem("RowData", JSON.stringify(inventoriesArray));
    await getInventoryDetailAPICall({
      payload: [
        {
          restaurantId:restaurantId ? restaurantId : location.pathname.split("/").slice(-2)[0],
          productName: productName,
        },
      ],
    });
    await GetinventoryHistoryListAPICall({
      payload: [
        {
          restaurantId:restaurantId ? restaurantId : location.pathname.split("/").slice(-2)[0],
          productId: productId,
        },
      ],
    });
    await CategoryListAPICall({
      payload: [],
    });
    await InventoryUnitListAPICall({
      payload: [],
    });
    await LocationListAPICall({
      payload: [{ businessId: `${restaurantId ? restaurantId : restaurantsId}` }],
    });
    setLoadingCircul(true);
  }, []);

  useEffect(async () => {
    if (!productId) {
      await GetinventoryHistoryListAPICall({
        payload: [
          {
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            productId: product_id,
          },
        ],
      });
      setLoadingCircul(true);
    }
  }, [productId, product_id]);

  const handleClose = () => {
    localStorage.setItem("RowData", JSON.stringify(rowData1));
    setRowData(rowData1);
    seteditable(false);
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setShowSubmit(false);
      eventBus.$emit("editItemSucess", false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSubmit(false);
      setShowSnackbar(true);
      eventBus.$emit("editItemSucess", true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      onClose();
    }
  }, [response]);

  useEffect(() => {
    if (isInventoryUnitLoading) {
      return () => null;
    }
    if (isInventoryUnitError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (InventoryUnitResponse && InventoryUnitResponse.data.isSuccess === true) {
      const data = InventoryUnitResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          InventoryUnitId: prop?._id,
          clientOwnerId: prop?.clientOwnerId,
        };
      });
      setInventoryUnitList(data);
      setLoadingCircul(false);
    }
  }, [InventoryUnitResponse]);

  useEffect(() => {
    if (isLocationLoading) {
      return () => null;
    }
    if (isLocationError) {
      return () => null;
    }
    if (LocationResponse && LocationResponse.data.isSuccess === true) {
      const data = LocationResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          locationId: prop?._id,
        };
      });
      setLocationList(data);
      // setShowEditForm(true);
    }
  }, [LocationResponse]);

  const handleSubmit = async (values) => {
    const FinalArr = JSON.parse(localStorage.getItem("RowData"));
    const arr = FinalArr ? FinalArr : rowData;
    const filteredArray = arr.filter((obj) => obj.location !== "" && obj.currentInventory !== "");

    const errors = {
      inventoryUnit: unitNewValue.length === 0,
      category: categoryValue.length === 0,
      unitPrice: price === 0,
    };
    
    setInventoryUnitError(errors.inventoryUnit);
    setCategoryError(errors.category);
    setUnitPriceError(errors.unitPrice);

    const hasError = Object.values(errors).some(error => error);

    // if (unitNewValue.length == 0) {
    //   setInventoryUnitError(true);
    // }else if (categoryValue.length == 0) {
    //   setCategoryError(true);
    // } else if(price == 0){
    //   setUnitPriceError(true);
    // }else{
    if(!hasError){
      setInventoryUnitError(false);
      setCategoryError(false);
      setUnitPriceError(false);
      setShowSubmit(true);
      await setAPICall({
        payload: [
          {
            name: values?.itemName,
            unit: unitNewValue,
            category: categoryValue,
            unitPrice: Number(price),
            vendor: values?.vendors,
            lowStockFlag: Number(values?.lowStockFlag),
            parFlag: Number(values?.parflag),
            inventories: filteredArray,
            productId: productId,
            restaurantId: restaurantId ? restaurantId :  location.pathname.split("/").slice(-2)[0],
          },
        ],
      });
    }
  };

  const handleInventoryValueChange = (event, newInputValue) => {
    setunitNewValue(newInputValue);
  };

  const successDelete = async () => {
    setLoadingCircul(true);
    await deleteInventoryAPICall({
      payload: [
        {
          inventoryId: inventoryId,
          restaurantId: restaurantId ? restaurantId : location.pathname.split("/").slice(-2)[0],
        },
      ],
    });
  };

  const hideAlert = () => {
    setDeleteInventoryDialog(false);
  };

  useEffect(() => {
    if (unitNewValue?.length == 0) {
      setInventoryUnitError(true);
    } else {
      setInventoryUnitError(false);
    }
  }, [unitNewValue, locationNewValue]);
  
  let newCount = 1;

  const printResult = (res) => {
    if (res.add) {
      res.add.forEach(function (rowNode) {
        setRowData((result) => [...result, rowNode?.data]);
      });
    }
    if (res.remove) {
      res.remove.forEach(function (rowNode) {
        console.log("Removed Row Node", rowNode);
      });
    }
    if (res.update) {
      res.update.forEach(function (rowNode) {
        console.log("Updated Row Node", rowNode);
      });
    }
  };

  const createNewRowData = (index) => {
    const newData = {
      id: index,
      location: "",
      currentInventory: "",
      orderNo: 2,
    };
    newCount++;
    return newData;
  };

  const addItems = useCallback((addIndex) => {
    const newItems = [createNewRowData(addIndex + 1)];
    const res = gridRef.current.api.applyTransaction({
      add: newItems,
      addIndex: addIndex,
    });
    printResult(res);
  }, []);

  const handleCategoryValueChange = (event, newInputValue) => {
    if(!newInputValue) {
      setCategoryError(true);
    }else{
      setCategoryError(false);
    }
    setCategoryValue(newInputValue);
  };

  const handleAddUnit = (newUnit) => {
    if (newUnit) {
      setInventoryUnitList((prev) => [...prev, { name: newUnit }]); // Add the new unit to the list
    }
  };

  const handleAddCategory = (newUnit) => {
    if (newUnit) {
      setCategoryList((prev) => [...prev, { name: newUnit }]); // Add the new unit to the list
    }
  };

  return (
    <>
      {showEditForm ? (
        <>
          <MDBox width="auto">
            <Grid item xs={12} lg={12}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  itemName: Yup.string()
                    .trim()
                    .required("Item  name is required.")
                    .min(3, "Item name should be more than 3 characters."),
                  inventoryUnit: Yup.string().nullable().required("Select Inventory Unit"),
                  // category: Yup.string().nullable().required("Select Category"),
                  lowStockFlag: Yup.string("Low Stock Flag must be a number type")
                    .matches(
                      /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                      "Low Stock Flag must be a number type"
                    )
                    .nullable()
                    .required("Low Stock Flag is required"),
                  parflag: Yup.string("Par Flag be a number type")
                    .matches(
                      /^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                      "Par Flag must be a number type"
                    )
                    .nullable()
                    .required("Par Flag is required"),
                })}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form autoComplete="off">
                    <MDBox
                      sx={{
                        "@media (max-width: 1440px)": {
                          padding: "0px 16px 14px 16px",
                        },
                        padding: "16px 16px 20px 16px",
                      }}
                    >
                      <MDBox>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} mb={2}>
                          <Field
                            as={TextField}
                            label="Item Name *"
                            name="itemName"
                            onChange={(e)=>{
                              setItemName(e.target.value);
                            }}
                            variant="standard"
                            fullWidth
                            disabled={!editable}
                            helperText={touched.itemName && errors.itemName}
                            InputProps={{
                              style: {
                                color: darkMode
                                  ? "white" // White text in dark mode
                                  : !editable
                                  ? "#9e9e9e" // Gray text when not editable
                                  : "#7a7a7a", // Default text color
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                  color: darkMode
                                    ? "#ffffff !important" // White input text in dark mode
                                    : !editable
                                    ? "#9e9e9e" // Gray input text when not editable
                                    : "#000000", // Black text when editable
                                },
                              "& .MuiInput-underline:before": {
                                borderBottom: darkMode
                                  ? "1px solid #ffffff" // White border in dark mode
                                  : !editable
                                  ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                  : "1px solid #000", // Black border when editable
                              },
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor : darkMode
                                  ? "#ffffff !important" // Override disabled text color in dark mode
                                  : "#9e9e9e !important", // Override disabled text color in light mode
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                color: "#ff0000", // Change helper text color here (e.g., red)
                              },
                            }}
                          />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} sx={{ mt: 0.5}} >
                            <CurrencyFieldText
                              label="Unit Price *"
                              name="unitPrice"
                              value={price}
                              fullWidth
                              disabled={!editable}
                              onValueChange={(event) => {
                                if(!event.value) {
                                  setUnitPriceError(true);
                                }else{
                                  setUnitPriceError(false);
                                }
                                setPrice(event.value);
                              }}
                            />
                            {unitPriceError && (
                                <MDBox mt={0.75}>
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                  >
                                    Unit price is required
                                  </MDTypography>
                                </MDBox>
                              )}
                          </Grid>
                          <Grid item xs={12} sm={6}
                            sx={{
                              "@media (max-width: 1920px)": {
                                mt: 1,
                              },
                              "@media (max-width: 1440px)": {
                                mt: 1,
                              },
                            }}
                          >
                            <FormControl variant="standard" fullWidth sx={{ mt: 0.5 }}>
                              <Autocomplete
                                sx={
                                  darkMode ? {
                                    "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root": {
                                      color: "#FFFFFF"
                                    },
                                    "& .MuiInput-underline": {
                                      "&.Mui-disabled:before": {
                                        borderBottom: "1px solid #949494"
                                      },
                                      borderBottom: "2px solid #ffffff",
                                      "&:before": {
                                        borderBottomColor: "white"
                                      }
                                    },
                                    "& .MuiInputBase-input.Mui-disabled, & .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: "white"
                                    },
                                    "& .MuiFormLabel-root": {
                                      color: "white !important"
                                    },
                                    "& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator": {
                                      color: "white !important"
                                    }
                                  } : {}
                                }
                                name="inventoryUnit"
                                noOptionsText={ 
                                  <MDTypography onClick={() => handleAddUnit(unitNewValue)} sx={{ fontSize: "0.775rem" }}>
                                    {unitNewValue} (New Unit)
                                  </MDTypography>
                                }
                                disablePortal
                                id="combo-box-demo"
                                ListboxProps={{
                                  style: { maxHeight: "150px" },
                                  position: "bottom-start",
                                }}
                                value={{ name: unitNewValue }}
                                options={inventoryUnitList}
                                getOptionLabel={(option) => option.name}
                                disabled={!editable}
                                onInputChange={(event, newInputValue) => {
                                  handleInventoryValueChange(event, newInputValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Inventory Unit *"
                                    name="inventoryUnit"
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: editable ? (
                                        <>{params.InputProps.endAdornment}</>
                                      ) : (
                                        // If not editable, remove the dropdown icon
                                        <></>
                                      ),
                                    }}
                                    sx={{
                                      borderBottom: darkMode
                                      ? "1px solid #ffffff" // White border in dark mode
                                      : !editable
                                      ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                      : "1px solid #000",
                                      color: "#7b809a !important",
                                      "@media (max-width: 440px)": {
                                        marginTop: "-27px",
                                        marginBottom: "13px",
                                        color: "#7b809a !important",
                                      },
                                    }}
                                  />
                                )}
                              />
                              {inventoryUnitError && (
                                <MDBox mt={0.75}>
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                  >
                                    Inventory Unit is required
                                  </MDTypography>
                                </MDBox>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} sx={{ marginTop: "13px" }} >
                          <Field
                            as={TextField}
                            label="Vendor"
                            name="vendors"
                            variant="standard"
                            onChange={(e)=>{
                              setVendorsNewValue(e.target.value);
                            }}
                            fullWidth
                            disabled={!editable}
                            helperText={touched.vendors && errors.vendors} // Display error for vendors
                            InputProps={{
                              style: {
                                color:darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a", // Text color based on editable state
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color:darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a", // Label color based on editable state
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor : darkMode
                                  ? "#ffffff !important" // Override disabled text color in dark mode
                                  : "#9e9e9e !important", // Override disabled text color in light mode
                              },
                              "& .MuiInput-underline:before": {
                                borderBottom: darkMode
                                  ? "1px dotted #ffffff" // White border in dark mode
                                  : !editable
                                  ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                  : "1px solid #000", // Black border when editable
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                color: "#ff0000", // Error text color (red)
                              },
                            }}
                          />
                          </Grid>
                          <Grid item xs={12} sm={6} sx={{ marginTop: "16px" }} >
                            <FormControl variant="standard" fullWidth sx={{ mt: 0.5 }}>
                              <Autocomplete
                                sx={
                                  darkMode ? {
                                    "& .MuiAutocomplete-clearIndicator, & .MuiSvgIcon-root": {
                                      color: "#FFFFFF"
                                    },
                                    "& .MuiInput-underline": {
                                      "&.Mui-disabled:before": {
                                        borderBottom: "1px solid #949494"
                                      },
                                      borderBottom: "2px solid #ffffff",
                                      "&:before": {
                                        borderBottomColor: "white"
                                      }
                                    },
                                    "& .MuiInputBase-input.Mui-disabled, & .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: "white"
                                    },
                                    "& .MuiFormLabel-root": {
                                      color: "white !important"
                                    },
                                    "& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator": {
                                      color: "white !important"
                                    }
                                  } : {}
                                }
                                name="category"
                                noOptionsText={ 
                                  <MDTypography onClick={() => handleAddCategory(categoryValue)} sx={{ fontSize: "0.775rem" }}>
                                    {categoryValue} (New Category)
                                  </MDTypography>
                                }
                                disablePortal
                                id="combo-box-demo"
                                ListboxProps={{
                                  style: { maxHeight: "150px" },
                                  position: "bottom-start",
                                }}
                                value={{ name: categoryValue }}
                                options={categoryList}
                                getOptionLabel={(option) => option.name}
                                disabled={!editable}
                                onInputChange={(event, newInputValue) => {
                                  handleCategoryValueChange(event, newInputValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Category *"
                                    name="category"
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: editable ? (
                                        <>{params.InputProps.endAdornment}</>
                                      ) : (
                                        // If not editable, remove the dropdown icon
                                        <></>
                                      ),
                                    }}
                                    sx={{
                                      borderBottom: darkMode
                                      ? "1px solid #ffffff" // White border in dark mode
                                      : !editable
                                      ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                      : "1px solid #000",
                                      color: "#7b809a !important",
                                      "@media (max-width: 440px)": {
                                        marginTop: "-27px",
                                        marginBottom: "13px",
                                        color: "#7b809a !important",
                                      },
                                    }}
                                  />
                                )}
                              />
                              {categoryError && (
                                <MDBox mt={0.75}>
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                  >
                                    Category is required
                                  </MDTypography>
                                </MDBox>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} sx={{ marginTop : '13px'}} >
                              <Tooltip
                                title="This is not editable. This is the Total Inventory * the Unit Price."
                                placement="top"
                              >
                              <Field
                                as={TextField}
                                label="Total Inventory Cost"
                                name="totalInventoryCost"
                                variant="standard"
                                fullWidth
                                disabled={true} // Set to true since it's disabled
                                helperText={touched.totalInventory && errors.totalInventory} // Display error for totalInventory
                                InputProps={{
                                  style: {
                                    color: "#9e9e9e", // Text color is gray when disabled
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color:darkMode ? 'white' : "#9e9e9e", // Label color is gray when disabled
                                  },
                                }}
                                sx={{
                                  "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor : darkMode
                                    ? "#ffffff !important" // Override disabled text color in dark mode
                                    : "#9e9e9e !important", // Override disabled text color in light mode
                                },
                                  "& .MuiInput-underline:before": {
                                    borderBottom: darkMode
                                      ? "1px solid #ffffff" // White border in dark mode
                                      : !editable
                                      ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                      : "1px solid #000", // Black border when editable
                                  },
                                }}
                                FormHelperTextProps={{
                                  style: {
                                    color: "#ff0000", // Error text color (red)
                                  },
                                }}
                              />
                              </Tooltip>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ marginTop : '13px'}} >
                            <Field
                              as={TextField}
                              label="Total Inventory"
                              name="totalInventory"
                              variant="standard"
                              fullWidth
                              disabled={true} // Set to true since it's disabled
                              helperText={touched.totalInventory && errors.totalInventory} // Display error for totalInventory
                              InputProps={{
                                style: {
                                  color: "#9e9e9e", // Text color is gray when disabled
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color:darkMode ? 'white' : "#9e9e9e", // Label color is gray when disabled
                                },
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor : darkMode
                                  ? "#ffffff !important" // Override disabled text color in dark mode
                                  : "#9e9e9e !important", // Override disabled text color in light mode
                              },
                                "& .MuiInput-underline:before": {
                                  borderBottom: darkMode
                                    ? "1px solid #ffffff" // White border in dark mode
                                    : !editable
                                    ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                    : "1px solid #000", // Black border when editable
                                },
                              }}
                              FormHelperTextProps={{
                                style: {
                                  color: "#ff0000", // Error text color (red)
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}
                            sx={{
                              "@media (max-width: 440px)": {
                                marginTop: "-24px",
                              },
                              "@media (max-width: 1920px)": {
                                marginTop: "5px",
                              },
                              "@media (max-width: 1440px)": {
                                marginTop: "5px",
                              },
                            }}
                          >
                            <Field
                              as={TextField}
                              label="Low Stock Flag"
                              name="lowStockFlag"
                              variant="standard"
                              fullWidth
                              onChange={(e)=>{
                                setLowStockFlag(e.target.value);
                              }}
                              disabled={!editable} // Disabled when editable is false
                              helperText={touched.lowStockFlag && errors.lowStockFlag} // Display error for lowStockFlag
                              InputProps={{
                                style: {
                                  color:darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color:darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a",
                                },
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor : darkMode
                                  ? "#ffffff !important" // Override disabled text color in dark mode
                                  : "#9e9e9e !important", // Override disabled text color in light mode
                              },
                                "& .MuiInput-underline:before": {
                                  borderBottom: darkMode
                                    ? "1px solid #ffffff" // White border in dark mode
                                    : !editable
                                    ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                    : "1px solid #000", // Black border when editable
                                },
                              }}
                              FormHelperTextProps={{
                                style: {
                                  color: "#ff0000", // Error text color (red)
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}
                            sx={{
                              "@media (max-width: 440px)": {
                                marginTop: "-24px",
                              },
                              "@media (max-width: 1920px)": {
                                marginTop: "5px",
                              },
                              "@media (max-width: 1440px)": {
                                marginTop: "5px",
                              },
                            }}
                          >
                            <Field
                              as={TextField}
                              label="Par Flag"
                              name="parflag"
                              variant="standard"
                              onChange={(e)=>{
                                setParflag(e.target.value);
                              }}
                              fullWidth
                              disabled={!editable} // Disabled when editable is false
                              helperText={touched.parflag && errors.parflag} // Display error for parflag
                              InputProps={{
                                style: {
                                  color:darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a", // Text color changes based on the editable state
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color:darkMode ? "#ffffff" :  !editable ? "#9e9e9e" : "#7a7a7a", // Label color changes based on the editable state
                                },
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor : darkMode
                                  ? "#ffffff !important" // Override disabled text color in dark mode
                                  : "#9e9e9e !important", // Override disabled text color in light mode
                              },
                                "& .MuiInput-underline:before": {
                                  borderBottom: darkMode
                                    ? "1px solid #ffffff" // White border in dark mode
                                    : !editable
                                    ? "1px dotted #9e9e9e" // Gray dotted border when not editable
                                    : "1px solid #000", // Black border when editable
                                },
                              }}
                              FormHelperTextProps={{
                                style: {
                                  color: "#ff0000", // Error text color (red)
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            {values?.parflag &&
                            Number(values?.lowStockFlag) > Number(values?.parflag) ? (
                              <MDBox sx={{ display: "flex", justifyContent: "end" }}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  Par Flag must be Greater than Low Stock
                                </MDTypography>
                              </MDBox>
                            ) : (
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                ></MDTypography>
                              </MDBox>
                            )}
                          </Grid>
                        </Grid>
                        <MDBox>
                          <div style={containerStyle}>
                            <div className={darkMode ? "ag-theme-alpine-add-edit" : "ag-theme-alpine"} style={{ height: editTableHeight, width: "100%" }} >
                              <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={editable ? columnDefs : columnDefs1}
                                headerHeight={40}
                                defaultColDef={defaultColDef}
                                rowDragManaged={false}
                                rowHeight={50}
                                rowStyle={rowStyle}
                                getRowStyle={getRowStyle}
                                animateRows={false}
                                pagination={false}
                              />
                            </div>
                          </div>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        mb={-2}
                        sx={{
                          "@media (max-width: 1440px)": {
                            marginTop: "8px",
                          },
                          marginTop: "25px",
                        }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        {editable === false ? (
                          <MDButton
                            disabled={loadingCircul}
                            variant="gradient"
                            color="info"
                            onClick={() => setDeleteInventoryDialog(true)}
                          >
                            Delete
                          </MDButton>
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            disabled
                            sx={{ background: "transparent !important" }}
                          ></MDButton>
                        )}
                        <MDBox display="flex">
                          {editable === false && (
                            <MDButton
                              disabled={loadingCircul}
                              variant="gradient"
                              color="info"
                              onClick={() => seteditable(true)}
                            >
                              Edit
                            </MDButton>
                          )}
                          {editable === true && (
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton variant="gradient" color="light" onClick={handleClose}>
                                Cancel
                              </MDButton>
                            </MDBox>
                          )}
                          {editable === true && (
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                sx={{ marginLeft: "4px!important" }}
                                type="submit"
                                variant="gradient"
                                color="info"
                                // onClick={() => seteditable(false)}
                              >
                                {showSubmit === true ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </Grid>
          </MDBox>
          {inventoryHistoryList.length !== 0 && (
            <>
              <MDBox
                mt={2}
                mb={3}
                display="flex"
                justifyContent="center"
                sx={darkMode ? { color: "#ffffff" } : {}}
              >
                History
              </MDBox>
              <MDBox sx={{ maxHeight: "150px", overflowY: "auto" }}>
                <List>
                  {inventoryHistoryList.map((props) => (
                    <ListItem disableGutters>
                      <CircleIcon
                        sx={{
                          mb: "-6px",
                          ml: 1.5,
                          mr: 1.5,
                          fontSize: "1rem",
                          ...(darkMode && { color: "#ffffff" })
                        }}
                      />
                      <ListItemText
                        primaryTypographyProps={
                          {
                            fontSize: "0.875rem", 
                            fontWeight: 400,
                            mt: 1,
                            ...(darkMode && { color: "#ffffff" })
                          }
                        }
                        primary={props?.message}
                      />
                    </ListItem>
                  ))}
                </List>
              </MDBox>
            </>
          )}
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="success"
              content={"Edit Inventory Successfully"}
              // autoHideDuration={3000}
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={"Failed to Edit Inventory"}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
          <MDBox>
            <Dialog
              // fullScreen
              open={openAddNewLocation}
              onClose={() => setOpenAddNewLocation(false)}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Location
              </DialogTitle>
              <DialogContent>
                <AddInventoryLocation
                  restaurantsId={restaurantId ? restaurantId : restaurantsId}
                  onClose={() => setOpenAddNewLocation(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              // fullScreen
              open={addinventory}
              onClose={() => setAddinventory(false)}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              <DialogContent>
                <AddCurrontInvetory onCloseAdd={() => setAddinventory(false)} />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openEditVendor}
              onClose={() => setOpenEditVendor(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    minHeight: "30%",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Vendor
              </DialogTitle>
              <DialogContent>
                <AddVendorDialog
                  businessId={businessId}
                  restaurantsId={restaurantId ? restaurantId : restaurantsId}
                  onClose={() => setOpenEditVendor(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <Dialog
            open={deleteInventoryDialog}
            onClose={() => setDeleteInventoryDialog(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#000000",
                },
              },
            }}
          >
            <DialogContent sx={{ overflowY: "hidden" }}>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              <DialogContentText
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                <h3>Are you sure?</h3>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{
                    fontWeight: 300,
                    fontSize: "0.90rem",
                    textAlign: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#000000",
                  }}
                >
                  Once you delete this Item there is no way of getting any of the data back.
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
              <MDButton variant="gradient" color="success" onClick={successDelete}>
                Yes, delete it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={hideAlert}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
          <MDBox>
            <Dialog
              open={openAddNewinventory}
              onClose={() => setOpenAddNewinventory(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Inventory Unit
              </DialogTitle>
              <DialogContent>
                <AddNewInventoryUnitDialog
                  restaurantsId={restaurantId ? restaurantId : restaurantsId}
                  onClose={() => setOpenAddNewinventory(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
        </>
      ) : (
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
      )}
    </>
  );
}

export default EditInventoryDialog;
