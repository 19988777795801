import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import Hoc from "hoc";
import SubscriptionPriceCard from "layouts/components/SubscriptionPriceCard";
import { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { GetProducts } from "../../../../services/adminservices";
import LoaderProfile from "layouts/accountants/user/components/imgLoader/LoaderProfile";

function SubscriptionAmount() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [subscriptionName ,setSubscriptionName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaderClass, setLoaderClass] = useState({});
  const [showCards ,setShowCards] = useState(false);
  const [{isLoading: isProductLoading, isError: isProductError, response: productsResponse },getProductsAPICall] = useAPICall(GetProducts, { onLoad: false });

  useEffect(() => {
      if (isProductLoading) {
        return () => null;
      }
  
      if (isProductError) {
        setLoading(false);
        setShowCards(false);
        return () => null;
      }
  
      if (productsResponse && productsResponse?.data?.isSuccess === true) {
          setSubscriptionName(productsResponse?.data?.data?.records?.records);
          setShowCards(false);
          setLoading(false);
      } 
    }, [productsResponse]);

    useEffect(() => {
      eventBus.$emit("OnSetBlur", loading);
      loading === true
        ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
        : setLoaderClass({});
    }, [loading]);

    useEffect(async () => {
      setLoading(true);
      setShowCards(true);
      await getProductsAPICall({ payload: [] });
    }, []);

    eventBus.$on("editProductResponse", (data) => editProductResponse(data));
    async function editProductResponse(data) {
      await getProductsAPICall({ payload: [] });
    }

  return (
    <>
    {showCards &&
    <MDBox display="flex" justifyContent="center">
                              <LoaderProfile />
                            </MDBox>}
    <MDBox sx={loaderClass} display="flex" justifyContent="start" alignItems="center" flexWrap="wrap" gap="3rem" mt={10} mb={15} >    
    {subscriptionName && subscriptionName.map((offers ,name, index) => (
      <Grid item xs={12} lg={4} key={index}>
        <SubscriptionPriceCard
          color={darkMode ? "dark" : "light"}
          badge={{
            color: darkMode ? "dark" : "light",
            label: name,
          }}
          specifications={offers}
          action={{
            type: "internal",
            route: "/",
            color: darkMode ? "dark" : "light",
            label: name,
          }}
          shadow={darkMode}
        />
      </Grid>
    ))}
    </MDBox>
    </>
    );
}

export default Hoc(SubscriptionAmount);