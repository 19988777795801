import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { EditVendorSchema } from "layouts/accountants/user/components/schemas/editvendorvalidation";
import FormField from "layouts/admin/accountants/components/FormField";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import { EditVendorDetails, GetInvoiceDetails } from "../../../../services/clientservices";

function EditVendorDialog({ onClose, invoiceId, businessId }) {
  const [invoice_id, setInvoiceId] = useState(invoiceId);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [phone, setPhone] = useState("");
  const [vendorId, setvendorId] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [trackPayment, setTrackPayment] = useState(false);
  const [{ isLoading: isEditVendorLoading, isError: isEditVendorError, response: EditVendorResponse }, EditVendorListAPICall ] = useAPICall(EditVendorDetails, { onLoad: false });
  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });

  useEffect(() => {
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    setLoading(true);
  }, []);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      setLoading(false);
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setVendorName(DetailsResponse.data.data.invoice.vendor.name);
      setPhone(DetailsResponse.data.data.invoice.vendor.phone);
      setvendorId(DetailsResponse.data.data.invoice.vendorId);
      setAddress(DetailsResponse.data.data.invoice.vendor.address);
      setShowDetails(true);
      setLoading(false);
    }
  }, [DetailsResponse]);

  useEffect(() => {
    if (isEditVendorLoading) {
      return () => null;
    }
    if (isEditVendorError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditVendorResponse && EditVendorResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      onClose();
    }
  }, [EditVendorResponse]);

  const initialValues = {
    vendor: vendorName,
    accountno: "",
    address: address,
    email: "",
    phone: phone,
    businessid: "",
    net: "",
    account: "",
  };
  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditVendorListAPICall({
      payload: [
        {
          name: values.vendor,
          phone: values.phone,
          address: values.address,
          VendorId: `${vendorId}`,
        },
      ],
    });
  };

  const handleChange = () => {
    setTrackPayment(!trackPayment);
  };

  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={EditVendorSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form autoComplete="off">
                      <MDBox p={3}>
                        <MDBox>
                          <MDBox lineHeight={0}>
                            <MDTypography variant="h5">Edit Vendor</MDTypography>
                          </MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Vendor" name="vendor" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Account Number" name="accountno" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Address" name="address" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="email" label="Email" name="email" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField
                                  type="text"
                                  label="Phone Number"
                                  name="phone"
                                  icon="+1"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField
                                  type="text"
                                  label="Business ID Number/Social Security Number"
                                  name="businessid"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Net Terms" name="net" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDBox>
                                  <Field
                                    as={FormControlLabel}
                                    type="checkbox"
                                    name="checkbox"
                                    control={<Checkbox />}
                                    label="Track payments for 1099"
                                    checked={trackPayment}
                                    onChange={handleChange}
                                    sx={{ mt: 1.5 }}
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <MDBox lineHeight={0}>
                              <MDTypography variant="h5">Account Mapping</MDTypography>
                            </MDBox>
                            <MDBox>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                    <RadioGroup>
                                      <FormControlLabel
                                        label="Map to a Single Account"
                                        defaultValue="map"
                                        control={<Radio />}
                                      />
                                      <Field
                                        as="select"
                                        name="account"
                                        variant="standard"
                                        style={{
                                          marginTop: "18px",
                                          borderTop: "0px",
                                          borderLeft: "0px",
                                          borderRight: "0px",
                                          color: "slategray",
                                        }}
                                      >
                                        <option value="1">Chart Account</option>
                                        <option value="2">Food</option>
                                        <option value="2">Bar</option>
                                      </Field>

                                      <MDBox mt={0.75}>
                                        <MDTypography
                                          component="div"
                                          variant="caption"
                                          color="error"
                                          fontWeight="regular"
                                        >
                                          <ErrorMessage name="account" />
                                        </MDTypography>
                                      </MDBox>
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControlLabel
                                    value="classes"
                                    control={<Radio />}
                                    label="Classes "
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <FormControlLabel
                                    value="split"
                                    control={<Radio />}
                                    label="Split between multiple accounts"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControlLabel
                                    value="products"
                                    control={<Radio />}
                                    label="Products/Services"
                                  />
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
              <MDBox>
                {loading && (
                  <CircularProgress
                    size={50}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </MDBox>
            </Grid>
          </MDBox>
        </>
      )}
    </>
  );
}

export default EditVendorDialog;