import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { parseError, parseResponse } from '../parseResponse';

const fetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                response: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
                response: action.payload
            };
        case 'RESET':
            return {
                isLoading: false,
                isError: false,
                response: null,
            }
        default:
            throw new Error();
    }
}

/**
 * Executes an API call and returns the result. Tracks isLoading and
 * isError flags.
 * 
 * Can either be passed a funcition that returns a promise or a config 
 * object.  If a function is passed, that will be used to execute the API 
 * Call.  If no function is passed the config object should be the first 
 * agrument and will be used to execute a call with axios.
 * 
 * @param {() => Promise || Object} fn Function to call or config object
 * @param {Object} config Config used when a function is passed
 */
export const useAPICall = (fn, config = {}) => {
    const navigate = useNavigate();

    // Allow function to be passed as first argument or only a config
    // object to be passed.
    if (typeof fn !== 'function') {
        config = fn;
        fn = null;
    }

    const { url: defaultURL, payload: defaultPayload, method = 'get', onLoad = true } = config;
    const [{ url, payload, ready }, setConfig] = useState({ url: defaultURL, payload: defaultPayload, method, ready: onLoad });
    const [state, dispatch] = useReducer(fetchReducer, {
        isLoading: false,
        isError: false,
        response: null
    })

    useEffect(() => {
        if (state?.response?.code === "DATA401") {
            navigate("/user/logout");
        }
    }, [state]);

    const setAPICall = (newState) => {
        setConfig((state) => ({ ...state, ...newState, ready: true }))
    }

    const resetAPICall = () => {
        setConfig({ url: defaultURL, payload: defaultPayload, method, ready: onLoad });
        dispatch({ type: 'RESET' });
    }

    useEffect(() => {
        const fetchData = () => {

            dispatch({ type: 'FETCH_INIT' });
            const Func = typeof fn === 'function'
                ? () => {
                    return Array.isArray(payload)
                        ? fn(...payload)
                        : fn(payload)
                }
                : () => axios({ method, url, data: payload })

            Func()
                .then((result) => {
                    const response = parseResponse(result);  
                    if (response?.data?.message ===  "OK") {
                        response.data.message = "Success";
                    }                 
                    if (response && response.status >= 400) {
                        if (response?.data?.message === "undefined") {
                            response.data.message = "We have encountered a problem, please try again after sometime";
                        }

                        throw response;
                    }
                    dispatch({ type: 'FETCH_SUCCESS', payload: response })
                })
                .catch((error) => {
                    const response = parseError(error);
                     // Check if the message is "undefined" and replace it
                    if (response?.data?.message === "undefined") {
                        response.data.message = "We have encountered a problem, please try again after sometime";
                    }
                    dispatch({ type: 'FETCH_FAILURE', payload: response })
                })
        }

        if (ready) {
            fetchData();
        }

    }, [ready, url, payload])

    return [state, setAPICall, resetAPICall]

}